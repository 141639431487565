import * as Sentry from '@sentry/react';
import { rootReducer } from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { rootSplitApi } from './root-api-slice';
import { rtkQueryErrorHandler } from 'store/middleware/global-error-handler';

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(rootSplitApi.middleware, rtkQueryErrorHandler),
    enhancers: (getDefaultEnhancers) => 
        getDefaultEnhancers().concat(Sentry.createReduxEnhancer({})),
    
})
export type RootState = ReturnType<typeof rootReducer>;
