import { ItemType } from 'types/item';

export const getItemType = (item: ItemType) => item.item_type ?? item.type;

export const isShopableItem = (item: ItemType) => {
    const itemType = getItemType(item);

    return itemType === 'shop' || itemType === 'catalog';
};

export const isExternalItem = (item: ItemType) =>
    // Strict equality check to protect ourselves from inconsistencies in api data
    item.is_shoppable === false && (isShopableItem(item) || item.available_for_checkout === false);
