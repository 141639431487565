import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import { deactivate } from '../../../redux/reducers/users/actions';
import DeactivateAccount from './DeactivateAccount';

const mapStateToProps = ({ users: { error } }) => ({ error });

const mapDispatchToProps = (dispatch) => ({
    deactivate: () => dispatch(deactivate()),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateAccount);
