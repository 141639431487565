import './style.scss';

import React from 'react';

import campaigns from 'campaigns.json';
import { Campaigns, Page } from 'components';
interface ICampaign {
    campaign: keyof typeof campaigns;
}
export const Campaign: React.FC<ICampaign> = ({ campaign, ...rest }) => {
    const campaignData = campaigns[campaign];
    const CampaignComponent = Campaigns.get(campaign);
    return (
        <Page footer={(campaignData as any).footer ? (campaignData as any).footer.show : false}>
            {React.createElement(CampaignComponent as any, { ...rest })}
        </Page>
    );
};
