import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client, Stylist } from 'types/user';

import { isMatchedStylist, MP_EVENTS, MP_PROPS, trackEvent } from '../../services/mixpanel';
import { sentryException } from '../../services/SentryLogging';
import { useCommonStore } from '../../store/common/reducer';
import { useSelector } from 'react-redux';
import { selectApiToken } from 'store/auth-service/auth-slice';

interface IQuiz {
    user: Client;
    stylist: Stylist;
    price: number;
}

const Quiz: React.FC<IQuiz> = ({ user, stylist, price }) => {
    const navigate = useNavigate();
    const initialized = useRef(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [quizComplete, setQuizComplete] = useState(false);
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const token = useSelector(selectApiToken);

    const trackQuizComplete = useCallback(() => {
        if (user && stylist) {
            const properties = {
                [MP_PROPS.USER_UUID]: user.user_uuid ?? '',
                [MP_PROPS.USER_EMAIL]: user.email ?? '',
                [MP_PROPS.STYLIST_NAME]: stylist?.name ?? '',
                [MP_PROPS.STYLIST_UUID]: stylist?.uuid ?? '',
                [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, stylist?.uuid ?? ''),
                [MP_PROPS.SESSION_STATUS]: 'Ongoing',
                [MP_PROPS.SESSION_COUNT]: user.bookings_count,
                [MP_PROPS.PAYMENT_AMOUNT]: price,
                [MP_PROPS.SESSION_AMOUNT]: price
            };

            trackEvent({
                name: MP_EVENTS.COMPLETED_A_QUIZ,
                properties
            });
        }
    }, [user, stylist, matches, price]);

    const goToSession = useCallback(() => {
        navigate(stylist && user ? `/chat/${stylist.uuid}/${user.user_uuid}` : '/inbox');
    }, [stylist, user, navigate]);

    useEffect(() => {
        if (user && containerRef.current) {
            const { user_uuid, first_name, last_name, email } = user;
            const { chat } = window.Wishi.modules;

            if (!initialized.current && user_uuid && email) {
                localStorage.removeItem('[wishi-sdk-token]');

                chat.mount({
                    rootElement: containerRef.current,
                    partnerUserId: user_uuid,
                    partnerToken: token,
                    clientInfo: {
                        firstName: first_name,
                        lastName: last_name,
                        email: email
                    }
                });

                chat.on('quiz-complete', () => {
                    setQuizComplete(true);
                });

                initialized.current = true;
            }
        }
    }, [user, setQuizComplete]);

    useEffect(() => {
        if (quizComplete) {
            trackQuizComplete();
            goToSession();
        }
    }, [quizComplete, trackQuizComplete, goToSession]);

    useEffect(() => {
        let error = '';

        if (!user) {
            error = 'Quiz started without user';
        } else if (!(user.user_uuid && user.first_name && user.email)) {
            error = 'Quiz started with invalid user';
        }

        if (!token) {
            error = 'Quiz started without partnerToken';
        }

        if (error) {
            sentryException(new Error('Quiz page error'), error);
            navigate('/');
        }
    }, [navigate, user]);

    return <div className="quiz" ref={containerRef}></div>;
};

export default Quiz;
