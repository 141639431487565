import './style.scss';

import React from 'react';
import { Col, Container, Form } from 'react-bootstrap';

import * as Types from './types';

const INPUT_TYPES = {
    social: Types.Social,
    phone: Types.Phone,
    text: Types.Text,
    textarea: Types.Textarea,
    password: Types.Password,
    email: Types.Email,
    date: Types.DateInput,
    checkbox: Types.Checkbox,
    dropdown: Types.Dropdown,
    colors: Types.Colors
};

const Input = (data) => {
    const type = data.props && data.props.type ? data.props.type : data.type;
    const InputComponent = INPUT_TYPES[type];

    return (
        <Container className={`input ${type}-input-field ${data.className || ''}`}>
            <Form.Row>
                <Col id="input">
                    <InputComponent {...data} />
                    {data.props && data.props.text && <Form.Text>{data.props.text}</Form.Text>}
                </Col>
            </Form.Row>
        </Container>
    );
};

export default Input;
