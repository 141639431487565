import './style.scss';

import { useIsMobile } from 'hooks';
import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import content from 'content';
import { Formatter } from 'services';
import { MP_EVENTS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { isExternalItem } from 'services/utils/item-utils';
import { itemToProps } from 'services/utils/mixpanel-utils';

const { shopping } = content;

export default ({ loading, item, removeFromCart, addToCloset }) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const priceFormatter = (price) => Formatter.price(price);

    const goToItem = () => {
        trackEvent({
            name: MP_EVENTS.ITEM_CLICKS,
            properties: itemToProps(
                item.item_uuid,
                MP_VALUES.CART,
                item.brand_name,
                item.source_look_uuid
            )
        });

        if (isExternalItem(item)) {
            window.open(item.buy_url, '_blank');
        }
        navigate(`/shopping-list/item/${item.originalItemUnique}`);
    };

    return (
        <Container className="cart-item">
            <Row>
                <Col xs={6} md={2} className="image-col">
                    <Image src={item.picture} onClick={goToItem} />
                </Col>
                <Col xs={6}>
                    <p className="brand" onClick={() => (isMobile ? goToItem() : null)}>
                        {item.brand_name}
                    </p>
                    {item.name && (
                        <p
                            className="name d-none d-sm-block"
                            onClick={() => (isMobile ? goToItem() : null)}>
                            {item.name}
                        </p>
                    )}
                    <div className="prices" onClick={goToItem}>
                        {item.retail_price && (
                            <p
                                className={`price${
                                    parseInt(item.sale_price) < parseInt(item.retail_price)
                                        ? ' line-through'
                                        : ''
                                }`}>
                                {priceFormatter(item.retail_price)}
                            </p>
                        )}
                        {item.sale_price &&
                            parseInt(item.sale_price) < parseInt(item.retail_price) && (
                                <>
                                    <p className="price">{priceFormatter(item.sale_price)}</p>
                                    <p className="price discount">
                                        {shopping.discount.replace(
                                            '%price%',
                                            priceFormatter(
                                                parseInt(item.retail_price) -
                                                    parseInt(item.sale_price)
                                            )
                                        )}
                                    </p>
                                </>
                            )}
                    </div>
                    <Button className="d-block d-md-none" variant="dark" onClick={goToItem}>
                        {shopping.shop}
                    </Button>
                    <p
                        className={`link bold ${item.is_in_closet ? 'in-closet' : ''}`}
                        onClick={() => addToCloset(item)}>
                        {item.is_in_closet ? shopping.inCloset : shopping.addCloset}
                    </p>
                </Col>
                <Col className="buttons d-none d-md-flex">
                    <Button variant="dark" onClick={goToItem}>
                        {shopping.shop}
                    </Button>
                </Col>
                <div className="close" onClick={() => !loading && removeFromCart(item)} />
            </Row>
        </Container>
    );
};
