import './style.scss';

import React from 'react';
import { Modal } from 'react-bootstrap';
import { ReviewBody } from 'components/Review';

interface IReviewData {
    clientName: string;
    stylistName: string;
    stylistUuid: string;
    rate: string;
    description: string;
    date: string;
}

const ReadReview: React.FC<{ data?: IReviewData }> = ({ data }) => {
    return (
        <span className="reviewReader-modal">
            <Modal.Header closeButton />
            <Modal.Body>{data && <ReviewBody review={data} type={'reviewModal'} />}</Modal.Body>
        </span>
    );
};
export default ReadReview;
