import './GiftCards.scss';

import { Page } from 'components';
import { FC, useEffect } from 'react';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';

import { Benefits, Experience, Hero, PricingTable, Slideshow } from './components';

export const GiftCards: FC = () => {
    useEffect(() => {
        klaviyoTrack(KlaviyoEvent.GIFTCARDS_PAGE_VIEW);
    }, []);

    return (
        <Page className="gift-cards-page">
            <Hero />
            <PricingTable />
            <Slideshow />
            <Benefits />
            <Experience />
        </Page>
    );
};
