import './style.scss';

import React, { createElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { Page } from 'components';
import Header from './Header';
import Sections from './Sections';
import { useIsMobile, useWithDispatch } from 'hooks';
import { setGridSize } from 'store/profile/actions';
import { AvailableSections, baseURL } from './consts';
import { useSelector } from 'react-redux';
import { selectApiToken } from 'store/auth-service/auth-slice';

export const Profile = () => {
    const navigate = useNavigate();
    const token = useSelector(selectApiToken);
    const { tab = AvailableSections.Closet } = useParams<{ tab: AvailableSections }>();
    const [section, setSection] = useState<any>();
    const isMobile = useIsMobile();
    const setGridSizeAction = useWithDispatch(setGridSize);

    useEffect(() => {
        if (!token) navigate('/');

        if (isMobile) setGridSizeAction('small');
    }, [token, navigate, isMobile, setGridSizeAction]);

    useEffect(() => {
        if (Sections[tab]) {
            setSection(Sections[tab]);
        } else {
            navigate(`${baseURL}/${AvailableSections.Closet}`);
        }
    }, [tab, navigate, setSection]);

    const onMenuSelect = (selection: string) => navigate(`${baseURL}/${selection}`);

    return (
        <Page footer={false}>
            <Container className="profile" fluid>
                <Header onMenuSelect={onMenuSelect} active={tab} />

                <Row className="content">
                    <Col> {section ? createElement(section) : ''}</Col>
                </Row>
            </Container>
        </Page>
    );
};
