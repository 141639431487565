import axios from 'axios';
import Config from './Config';
import { getUserId, getUserToken } from 'services/utils/user-utils';

const Stylist = {
    all: (params: Record<string, any>) =>
        axios.get(`${Config.get('apiroot2')}stylist/readAll`, {
            params,
            headers: { token: getUserToken() }
        }),
    get: (stylistId: string) =>
        axios.get(
            `${Config.get('apiroot2')}stylist/${stylistId}/read`,
            { 
                params: { user_uuid: getUserId() }, 
                headers: { token: getUserToken() } 
            }
        ),
    reviews: (stylist_id: string, from: number, count: number) =>
        axios.get(`${Config.get('apiroot2')}stylist/${stylist_id}/reviews`, {
            params: { from, count }
        }),
    getLatestReviews: (limit: number) =>
        axios.get(`${process.env.REACT_APP_API_GW_PATH}review/latest`, {
            params: { limit }
        }),
    review: (stylistId: string, review: string) =>
        axios.post(`${Config.get('apiroot2')}stylist/${stylistId}/review`, review, {
            headers: { token: getUserToken() }
        }),
    looks: (stylist_id: string, params: Record<string, any>) =>
        axios.get(`${Config.get('apiroot2')}stylist/${stylist_id}/looks`, {
            params,
            headers: { token: getUserToken() }
        }),
    createSession: (stylistId: string) =>
        axios.post(`${Config.get('apiroot2')}twilio/channel`, 
            {
                client_uuid: getUserId(),
                stylist_uuid: stylistId
            },
            {
                headers: { token: getUserToken() }
            }
        ),
    joinWaitList: ({ stylistId, userId }: { stylistId: string; userId: string }) =>
        axios.post(
            `${Config.get('apiroot2')}stylist/${stylistId}/waitingList`,
            { user_uuid: userId },
            {
                headers: { token: getUserToken() }
            }
        )
};

export default Stylist;
