import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import Slider from 'react-slick/lib';

const Brands = ({ data }) => (
    <Row className="brands">
        <Container>
            <Row>
                <Col>
                    <p className="section-title">{data.title}</p>
                </Col>
            </Row>
            <Slider
                className="brands-carousel"
                dots={true}
                infinite={true}
                centerMode={false}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay={false}
                arrows={false}
            >
                {data.looks.map((look, index) => (
                    <BrandItem key={index} look={look} />
                ))}
            </Slider>
        </Container>
    </Row>
);

const BrandItem = ({ look, key }) => (
    <Container className="brand-item" key={key}>
        <Row>
            <Col sm={6} className="d-none d-sm-flex">
                <ImgWithFallback
                    src={look.main.src}
                    fallbackSrc={look.main.fallback}
                    className="main-image img-fluid"
                />
                <div className="name" dangerouslySetInnerHTML={{ __html: look.name }} />
            </Col>
            <Col xs={12} sm={6}>
                <Container fluid>
                    <Row>
                        {look.items.map((item) => (
                            <Col xs={6} key={item.image.src}>
                                <Card key={item.image.src}>
                                    <Card.Body className="item">
                                        <ImgWithFallback
                                            src={item.image.src}
                                            fallbackSrc={item.image.fallback}
                                            className="img-fluid"
                                        />
                                        <div>
                                            <Image src={item.brand} className="logo" fluid />
                                            <p>{item.price}</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>
);

export default Brands;
