import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { useCommonStore } from 'store/common/reducer';

import campaigns from 'campaigns';
import { Page, RadioButton } from 'components';
import content from 'content.json';
import { GoalsHeader } from './';

const { goals } = content;

const GoalsSelect = ({
    stylist,
    user = {},
    campaign,
    selectGoal,
    clearGoal,
    clearResult,
    mixpanelStore
}) => {
    const navigate = useNavigate();
    const [content, setContent] = useState();
    const [checked, setChecked] = useState(null);
    const bookingSource = useCommonStore((store) => store.bookingSource);

    useEffect(() => {
        clearResult();
        if (stylist && user)
            trackEvent({
                name: MP_EVENTS.STYLING_GOALS_VIEWS,
                properties: {
                    ...mixpanelStore.onboarding
                }
            });
    }, [stylist, user]);

    useEffect(() => {
        setContent(campaign ? campaigns[campaign].goals : goals);
    }, [campaign]);

    const onSelect = (goal, idx) => {
        trackEvent({
            name: MP_EVENTS.STYLING_GOAL_SELECTION,
            properties: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.SELECTED_GOAL]: idx,
                ...bookingSourceToProps(bookingSource)
            }
        });
        selectGoal(goal);
        setTimeout(() => navigate('/plans'), 500);
    };

    const onBack = () => {
        if (!stylist) {
            navigate('');
            return;
        }
        clearGoal();
        const options = campaign ? {state : { campaign }} : {};
        navigate(`/stylist/${stylist.uuid}/profile`, options);
    };

    return (
        <Page footer={false} header={false} fixedHeader={false}>
            {content && (
                <Container className="goals-select">
                    <GoalsHeader onBack={onBack} />
                    {user && (
                        <>
                            <div className="d-none d-sm-block">
                                <Row className="chat-row">
                                    <Col>{content.question.replace('%user%', user.first_name)}</Col>
                                </Row>
                            </div>
                            <div className="d-block d-sm-none">
                                <Row className="chat-row">
                                    <Col>
                                        {content.question
                                            .split('\n')[0]
                                            .replace('%user%', user.first_name)}
                                    </Col>
                                </Row>
                                <Row className="chat-row">
                                    <Col>{content.question.split('\n')[1]}</Col>
                                </Row>
                            </div>
                        </>
                    )}
                    <Row className="answers">
                        {content.answers.map((answer, idx) => {
                            return (
                                <RadioButton
                                    type="dark"
                                    key={answer.value}
                                    id={`${answer.value}Goal`}
                                    value={answer.value}
                                    isChecked={checked === answer.value}
                                    onClick={() => {
                                        setChecked(answer.value);
                                        onSelect(answer, idx);
                                    }}
                                    label={answer.text}
                                    fill={false}
                                />
                            );
                        })}
                    </Row>
                </Container>
            )}
        </Page>
    );
};

export default GoalsSelect;
