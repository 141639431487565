import '../style.scss';

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { Validator } from '../../../services';

export default ({ onChange, user }) => {
    const [error, setError] = useState();
    useEffect(() => {
        if (user && onChange) onChange({ text: user.email });
    }, [user]);

    const onTextChange = (value) => {
        const valid = Validator.email(value);
        if (valid) {
            setError(valid.email);
            onChange({ text: '' });
        } else {
            setError('');
            onChange({ text: value });
        }
    };

    return (
        <Form.Group>
            <Form.Control
                as="input"
                type="email"
                defaultValue={user ? user.email : ''}
                onChange={(e) => onTextChange(e.target.value)}
            />
            {error && <Form.Row className="error">{error}</Form.Row>}
        </Form.Group>
    );
};
