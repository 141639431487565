import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import Content from '../../../content';

const Work = ({ stylist: { looks = {} } }) => (
    <>
        <div className="title">{Content.stylist.work}</div>
        <Row className="work">
            <Col>
                <Container className="looks no-padding" fluid>
                    <Row>
                        {looks.data &&
                            looks.data.map((look) => (
                                <Col key={look.uuid} className="look">
                                    <div className="look-name">{look.style_tags.join(' ')}</div>
                                    <Image className="look-image" src={look.picture} />
                                </Col>
                            ))}
                    </Row>
                </Container>
            </Col>
        </Row>
    </>
);

export default Work;
