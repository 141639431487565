import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import { removeLook } from '../../../redux/reducers/users/actions';
import RemoveLook from './RemoveLook';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    removeLook: (look_uuid, params) => dispatch(removeLook(look_uuid, params)),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveLook);
