import './style.scss';

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { StripeForm } from '../../../components';
import content from '../../../content.json';
import { Tracking } from '../../../services';
import Summary from './Summary';

const { giftcards } = content;

const CheckoutForm = ({ giftcard, getUserCards, payGiftCard }) => {
    const [first_name, last_name] = giftcard.senderName.split(/\s+/);

    useEffect(() => {
        Tracking.tag({
            event: 'eec.checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 1 },
                    products: [
                        {
                            name: 'Gift Card',
                            category: '/category/gift-card/',
                            variant: `Gift Card - ${giftcard.amount + giftcard.additionalAmount}`,
                            quantity: 1,
                            dimension3: 'Ecommerce'
                        }
                    ]
                }
            }
        });
    }, [giftcard]);

    useEffect(() => {
        getUserCards();
    }, [getUserCards]);

    return (
        <Container className="checkout-form" fluid>
            <Row>
                <Col>
                    <p className="title">{giftcards.checkout.details}</p>
                </Col>
            </Row>
            <Row className="d-flex d-sm-none">
                <Col>
                    <Summary giftcard={giftcard} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <StripeForm
                        cardholder={{ first_name, last_name }}
                        onSubmit={(token, captchaToken) =>
                            payGiftCard({ recaptcha_token: captchaToken })
                        }
                        bottomText=""
                        disclaimer={true}
                        disclaimerText={giftcards.checkout.disclaimer}
                        buttonText={giftcards.checkout.button}
                        stripeButton={false}
                        recaptcha={true}
                        checkCoupon={false}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default CheckoutForm;
