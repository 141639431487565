import './style.scss';

import { FC, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { useWithDispatch } from '../../hooks';
import { hideToast } from '../../store/ui/actions';
import { useUIStore } from '../../store/ui/reducer';

export const Toast: FC = connect()(() => {
    const {
        toastData: { isVisible, content, onUndo }
    } = useUIStore((store) => store);
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const destroyModal = useWithDispatch(hideToast);

    const onAnimationEnd = () => {
        !isVisible && destroyModal();
    };

    const pause = useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
    }, []);
    const unpause = useCallback(() => {
        timeout.current = setTimeout(destroyModal, 3500);
    }, [destroyModal]);

    useEffect(() => {
        if (isVisible) {
            unpause();

            return pause;
        }
    }, [isVisible, content, pause, unpause]);

    return content ? (
        <div
            className={`not-bootstrap-toast ${isVisible ? 'visible' : ''}`}
            onMouseEnter={pause}
            onMouseLeave={unpause}
            onAnimationEnd={onAnimationEnd}>
            <div className="content">{content}</div>
            {onUndo && (
                <div className="undo" onClick={onUndo}>
                    Undo
                </div>
            )}
        </div>
    ) : (
        <></>
    );
});
