import { connect } from 'react-redux';

import {
    paymentButtonDisabled,
    submitCoupon,
    toggleMobileCollapse,
    updatePaymentToken,
    updateUserCards
} from '../../../redux/reducers/payments/actions';
import { updateBillingDetails } from '../../../redux/reducers/users/actions';
import Billing from './Billing';
import Coupon from './Coupon';
import CreditCard from './CreditCard';

const mapStateToProps = ({
    user: { user },
    booking: { plan },
    payments: { error, coupon, model, buttonDisabled, loading }
}) => ({
    user,
    error,
    coupon,
    model,
    loading,
    buttonDisabled,
    plan
});

const mapDispatchToProps = (dispatch) => ({
    toggle: () => dispatch(toggleMobileCollapse()),
    submitCoupon: (code) => dispatch(submitCoupon(code)),
    updateUserCards: (data, token) => dispatch(updateUserCards(data, token)),
    updatePaymentToken: (token) => dispatch(updatePaymentToken(token)),
    paymentButtonDisabled: (disabled) => dispatch(paymentButtonDisabled(disabled)),
    updateBillingDetails: (billingDetails) => dispatch(updateBillingDetails(billingDetails))
});

const components = {
    coupon: connect(mapStateToProps, mapDispatchToProps)(Coupon),
    card: connect(mapStateToProps, mapDispatchToProps)(CreditCard),
    billing: connect(mapStateToProps, mapDispatchToProps)(Billing)
};

export default components;
