import './style.scss';

import React from 'react';
import { Container, Row } from 'react-bootstrap';

import { Loader, Orders } from '../../../components';
import Empty from './Empty';

export default ({ loading, orders, ordersTotal }) => {
    return (
        <Container className="profile-orders" fluid>
            {loading && <Loader />}
            <Row>
                {orders && !ordersTotal && <Empty section="orders" />}
                <Orders /> :
            </Row>
        </Container>
    );
};
