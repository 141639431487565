import './style.scss';

import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

const ToggleButton = ({ toggles = [], onChange, className = '', selected = null }) => {
    const [_selected, setSelected] = useState(selected || toggles[0].value);

    const onSelect = (selection) => {
        setSelected(selection);
        onChange(selection);
    };

    return (
        <ButtonGroup className={`toggle-button ${className}`}>
            {toggles.map(({ value, text }) => (
                <Button
                    key={value}
                    variant="light"
                    className={_selected === value ? 'selected' : ''}
                    onClick={() => onSelect(value)}
                >
                    {text}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default ToggleButton;
