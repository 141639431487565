import './Gender.scss';

import content from 'content.json';
import React, { FC, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useWithDispatch } from '../../../hooks';
import { MP_EVENTS, registerProperties, trackEvent } from '../../../services/mixpanel';
import { updateUser } from '../../../store/user/actions';
import { useUserStore } from '../../../store/user/reducer';
import { TGender, TStepComponentProps } from '../types';

const {
    onboarding: {
        gender: { question, answers }
    }
} = content;

export const Gender: FC<TStepComponentProps> = ({ onboardingState: { gender }, proceed }) => {
    const isInitialized = useRef(false);
    const { user } = useUserStore((store) => store);
    const updateUserAction = useWithDispatch(updateUser);
    const onProceedClick = (gender: TGender) => {
        registerProperties({ Gender: gender });
        trackEvent({ name: MP_EVENTS.ONBOARDING_GENDER_SELECTION });

        user && updateUserAction({ gender });
        proceed({ gender });
    };

    if (!isInitialized.current) {
        trackEvent({ name: MP_EVENTS.ONBOARDING_GENDER_VIEWS });

        isInitialized.current = true;
    }

    return (
        <div className="gender">
            <Col className="content">
                <p className="question">{question}</p>

                <Container>
                    <Row>
                        {answers.map(({ value, label, imageUrl }) => (
                            <div
                                key={value}
                                className={`gender-button ${gender === value ? 'selected' : ''}`}
                                onClick={() => onProceedClick(value as TGender)}>
                                <div className="label">{label}</div>
                                <img src={imageUrl} alt={label} />
                            </div>
                        ))}
                    </Row>
                </Container>
            </Col>
        </div>
    );
};
