import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchBox } from 'components';
import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUserStore } from 'store/user/reducer';
import { Option } from 'types/utils';

import content from '../../../content.json';

const { match } = content;

interface IMatchHeader {
    setFilter?: ({ key, value }: { key: string; value: any }) => void;
    stylists: Option[];
    selectedFilters: Record<string, any>;
    matchesLength?: number;
    favoriteStylistsLength?: number;
    favoriteLooksLength?: number;
    onSelect: (stylist: any) => void;
}
const MatchHeader: React.FC<IMatchHeader> = ({
    setFilter,
    stylists,
    selectedFilters,
    matchesLength = 0,
    favoriteStylistsLength = 0,
    favoriteLooksLength = 0,
    onSelect
}) => {
    const user = useUserStore((store) => store.user);
    return (
        <>
            <Row className="header">
                <Col xs={{ span: 6, order: 1 }} md={{ span: 4, order: 1 }}>
                    {(favoriteLooksLength > 0 || favoriteStylistsLength > 0) && (
                        <Link to="/favorites">
                            <FontAwesomeIcon icon={faHeart} />
                        </Link>
                    )}
                </Col>
                <Col
                    xs={{ span: 12, order: 3 }}
                    md={{ span: 4, order: 2 }}
                    className={`title ${matchesLength > 3 ? 'results' : ''}`}>
                    {Object.keys(selectedFilters).length
                        ? match.resultsTitle.replace('%length%', matchesLength as any)
                        : match.title}
                    {!Object.keys(selectedFilters).length && user && (
                        <div className="meet d-block">
                            {user?.first_name &&
                                match.meet
                                    .replace(
                                        '%user%',
                                        `${user.first_name
                                            .charAt(0)
                                            .toUpperCase()}${user?.first_name.slice(1)}`
                                    )
                                    .replace('%numberOfMatches%', `${matchesLength}`)}
                        </div>
                    )}
                </Col>
                <Col
                    xs={{ span: 6, order: 2 }}
                    md={{ span: 4, order: 3 }}
                    className="stylists-search-box">
                    <SearchBox
                        options={stylists}
                        placeholder={match.search}
                        onChange={onSelect}
                        onSubmit={() => {}}
                    />
                </Col>
            </Row>

            <Row className="filter-badges">
                <Col>
                    {Object.keys(selectedFilters).map((key) =>
                        selectedFilters[key].map((filter: any) => (
                            <Badge variant="light" key={filter.key}>
                                <div
                                    className="close"
                                    // onClick={() => setFilter({ key, value: filter })}
                                    onClick={() => console.log('onFilter- please fix')}
                                />
                                {filter.text}
                            </Badge>
                        ))
                    )}
                </Col>
            </Row>
        </>
    );
};

export default MatchHeader;
