import '../style.scss';

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';

import content from '../../../content.json';
import Config from '../../../services/Config';

const { payment } = content;

const Coupon = ({ toggle, error, coupon, submitCoupon, loading }) => {
    const [code, setCode] = useState('');
    const [couponError, setCouponError] = useState(null);
    const [safariKeyboard, setSafariKeyboard] = useState(false);
    let couponInput = React.createRef();

    useEffect(() => {
        couponInput.current.focus();
    }, []);

    useEffect(() => {
        if (error && error.coupon) {
            setCouponError(payment.summary.errors.invalidCode);
        }
        if (coupon.valid) {
            toggle();
        }
    }, [error, coupon]);

    const onSubmit = () => {
        if (!code.length) {
            setCouponError(payment.summary.errors.emptyCode);
        } else {
            setCouponError(null);
            submitCoupon(code);
        }
    };

    return (
        <Container className="coupon">
            <Row>
                <Col>
                    <div className="back-btn no-text" onClick={toggle} />
                    <div className="title">{payment.mobile.coupon}</div>
                </Col>
            </Row>
            <Row className="promotion-code">
                <Col className="d-flex flex-column">
                    <Form.Control
                        type="text"
                        value={code}
                        onFocus={() => setSafariKeyboard(Config.getDevice() === 'iphone')}
                        onBlur={() => setSafariKeyboard(false)}
                        onChange={(e) => setCode(e.target.value)}
                        ref={couponInput}
                    />

                    <div className="button-container">
                        <Button
                            className="submit-button"
                            disabled={loading}
                            onClick={onSubmit}
                            variant="dark">
                            {payment.mobile.confirm}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="code-error">{couponError}</Col>
            </Row>
        </Container>
    );
};

export default Coupon;
