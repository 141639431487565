// import 'react-intl-tel-input/dist/main.css';
import 'react-international-phone/style.css';
import '../style.scss';

import React, { useEffect, useState } from 'react';
import { registerProperties } from 'services/mixpanel';
import { PhoneInput } from 'react-international-phone';

const phoneRegex = /^[+][0-9]*$/;

interface PhoneProps {
    onChange: (text: string) => void;
    number?: string;
    country?: string;
}

export default ({ onChange, number = '', country = 'us' }: PhoneProps) => {
    const [phone, setPhone] = useState('');

    useEffect(() => {
        setPhone(number);
    }, [number]);
    
    const isValid = (value: string) => value === '' || phoneRegex.test(value)

    return (
        <PhoneInput
            defaultCountry={country}
            value={phone}
            onChange={(phone, meta) => {
                if (isValid(phone)) {
                    setPhone(phone);
                    onChange(phone);
                    registerProperties({ Country: meta.country.iso2 });
                }
            }}
        />
    );
};
