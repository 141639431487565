import { ResponseReviewItem } from '../../../types/utils';

export const reviewFormatter = (reviews: ResponseReviewItem[]) =>
    reviews.map((r) => convertReview(r));

const convertReview = (review: ResponseReviewItem) => {
    return {
        ...review,
        date: new Date(review.created).toLocaleString('default', {
            month: 'short',
            year: 'numeric'
        }),
        clientName: `${review.clientFirstName} ${review.clientLastName}`,
        stylistName: `${review.stylistFirstName} ${review.stylistLastName}`,
        stylistUuid: `${review.stylistUuid}`
    };
};
