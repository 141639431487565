import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TestimonialData } from 'types/utils';
import { TestimonialsCarousel } from '../../../components';

interface ITestimonials {
    data: TestimonialData;
}

const Testimonials: React.FC<ITestimonials> = ({ data }) => {
    return (
        <Row className="testimonials">
            <Container>
                <Row className="section-title flex-column">
                    <Col>{data.title}</Col>
                </Row>
                <Row className="testimonials-container d-none d-md-flex">
                    {data.content.map((testimonial) => (
                        <Col xs={12} md={4} key={testimonial.name} className="testimonial">
                            <Container>
                                <Row>
                                    <Col className="image">
                                        <div className="watermark" />
                                        <ImgWithFallback
                                            src={testimonial.img.src}
                                            fallbackSrc={testimonial.img.fallback}
                                            className="img-fluid"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="desc">
                                        <p className="text">{testimonial.text}</p>
                                        <p className="name">{testimonial.name}</p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    ))}
                </Row>
            </Container>

            <Col className="testimonials-container no-padding d-block d-md-none">
                <TestimonialsCarousel items={data.content} />
            </Col>
        </Row>
    );
};

export default Testimonials;
