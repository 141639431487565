import './style.scss';

import React from 'react';
import { Col } from 'react-bootstrap';

const FilterCheckBox = ({ item = {}, isSelected, filterKey, singleSelect, onChange }) => (
    <Col className={`filter-item ${isSelected ? 'selected' : ''}`}>
        <input
            className="styled-checkbox"
            type="checkbox"
            id={item.key}
            onChange={() => onChange({ key: filterKey, value: item })}
            checked={isSelected}
        />
        <label className={`filter-label ${singleSelect ? 'single' : ''}`} htmlFor={item.key}>
            {item.picture && <img src={item.picture} />}
            {item.text}
        </label>
    </Col>
);

export default FilterCheckBox;
