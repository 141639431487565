import '../style.scss';

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

export default ({ onChange, props = {}, type = 'text' }) => {
    const [value, setValue] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [returnType, setReturnType] = useState('string');
    const [className, setClassName] = useState('');
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (props.value) setValue(props.value);
        if (props.placeholder) setPlaceholder(props.placeholder);
        if (props.returnType) setReturnType(props.returnType);
        if (props.className) setClassName(props.className || '');
        if (props.label) setLabel(props.label);
    }, [props]);

    const onUpdate = (e) => {
        const str =
            returnType === 'array'
                ? e.target.value.length
                    ? e.target.value.split(',')
                    : []
                : e.target.value || e.key;
        setValue(str);
        onChange({ text: str });
    };

    return (
        <Form.Group className="customFormGroup">
            <Form.Label className="customFormLabel">{label}</Form.Label>
            <Form.Control
                as="input"
                type={type}
                className={className}
                onChange={onUpdate}
                placeholder={placeholder || ''}
                value={value}
            />
        </Form.Group>
    );
};
