import './style.scss';

import { Loader, MatchItem, Page } from 'components';
import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadFavoriteLooks } from 'redux/reducers/users/actions';
import { Stylist, Tracking } from 'services';
import { isMatchedStylist, MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { setCampaignBooking } from 'store/booking/actions';
import { useBookingStore } from 'store/booking/reducer';
import {
    loadStylistsNames,
    loadUserMatches,
    setBookingSource,
    setMixpanelStore
} from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';
import { updateFavoriteStylist } from 'store/user/actions';
import { loadFavoriteStylists } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';
import { Stylist as StylistType } from 'types/user';
import { Client } from 'types/user';

import content from '../../content.json';
import Banner from './components/Banner';
import MatchHeader from './components/Header';

const texts = content.match;

export const StylistMatch: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showBanner, setShowBanner] = useState(true);
    const [stylistMatches, setStylistMatches] = useState<any[]>([]);
    const [showMore, setShowMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const stylists = useCommonStore((store) => store.stylists);
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const favoriteStylists = useUserStore((store) => store.favoriteStylists);
    const campaign = useBookingStore((store) => store.campaign);
    const user = useUserStore((store) => store.user);
    const loadStylistsNamesAction = useWithDispatch(loadStylistsNames);
    const loadUserMatchesAction = useWithDispatch(loadUserMatches);
    const loadFavoriteStylistsAction = useWithDispatch(loadFavoriteStylists);
    const setCampaignBookingAction = useWithDispatch(setCampaignBooking);
    const updateFavoriteStylistAction = useWithDispatch(updateFavoriteStylist);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);
    const setBookingSourceAction = useWithDispatch(setBookingSource);

    useEffect(() => {
        dispatch(loadFavoriteLooks());
        loadFavoriteStylistsAction();
        setCampaignBookingAction();
    }, []);

    useEffect(() => {
        if (!matches.length) {
            loadUserMatchesAction(user as Client);
            loadStylistsNamesAction();
        }
    }, [user]);

    useEffect(() => {
        // @ts-ignore
        setCampaignBookingAction(location.state ? location.state.campaign : null);
    }, [location]);

    useEffect(() => {
        if (matches?.length) {
            Tracking.tag({
                event: 'eec.impressionView',
                ecommerce: {
                    impressions: matches.map((match, index) => ({
                        id: match.uuid,
                        name: match.name,
                        category: '/category/stylist/',
                        list: 'Stylist Recommendations',
                        position: index
                    }))
                }
            });
            setStylistMatches(matches);
            trackEvent({
                name: MP_EVENTS.STYLIST_MATCH_VIEWS,
                properties: {
                    [MP_PROPS.PREFERRED_STYLES]: mixpanelStore?.onboarding?.length
                        ? mixpanelStore.onboarding[MP_PROPS.PREFERRED_STYLES]
                        : '',
                    'Stylist match names': matches.reduce((acc, curr) => {
                        if (curr?.name) {
                            acc.push(curr.name);
                        }
                        return acc;
                    }, [] as string[]),
                    'Stylist match UUIDs': matches.reduce((acc, curr) => {
                        if (curr?.uuid) acc.push(curr.uuid);
                        return acc;
                    }, [] as string[])
                }
            });
        }
    }, [matches]);

    const selectStylist = (stylist: StylistType) => {
        const uuid = stylist?.uuid || stylist?.value;
        setBookingSourceAction({ source: MP_VALUES.STYLISTS });
        if (uuid) {
            const stylistName = stylist.name ? stylist.name : stylist.label;
            Tracking.tag({
                event: 'eec.impressionClick',
                ecommerce: {
                    click: {
                        actionField: { list: 'Stylist Recommendations' },
                        products: [
                            {
                                id: uuid,
                                name: stylistName,
                                category: '/category/stylist/'
                            }
                        ]
                    }
                }
            });
            trackEvent({
                name: MP_EVENTS.MEET_STYLIST,
                properties: {
                    ...mixpanelStore.onboarding,
                    [MP_PROPS.STYLIST_NAME]: stylistName,
                    [MP_PROPS.STYLIST_UUID]: uuid,
                    [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, uuid),
                    [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLISTS
                }
            });
            setMixpanelStoreAction({
                onboarding: {
                    ...mixpanelStore.onboarding,
                    [MP_PROPS.STYLIST_NAME]: stylistName ?? '',
                    [MP_PROPS.STYLIST_UUID]: uuid,
                    [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, uuid),
                    [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLISTS
                }
            });

            const props = campaign ? { state: { campaign } } : {};
            navigate(`/stylist/${uuid}/profile`, props);
        }
    };

    const loadMore = async () => {
        try {
            setLoading(true);

            const { data } = await Stylist.all({ count: 1000, user_gender: user?.gender });
            const stylistItemsArray = Array.isArray(data.items)
                ? data.items
                : Object.values(data.items);
            const matches = [
                ...stylistMatches,
                ...stylistItemsArray.filter(
                    (obj: StylistType) =>
                        stylistMatches.findIndex((match) => match.uuid === obj.uuid) < 0 &&
                        !obj.picture
                )
            ];
            setShowMore(false);
            setStylistMatches(matches);
            setLoading(false);
        } catch (e) {
            sentryException(e as Error, 'Cannot load more stylists');
            setLoading(false);
        }
    };
    return (
        <Page footer={false} className="no-padding">
            <Container className="match" fluid>
                {loading && <Loader />}
                {showBanner && <Banner onClose={() => setShowBanner(false)} />}

                <Row>
                    <Col className="results">
                        <Container>
                            <MatchHeader
                                selectedFilters={[]}
                                matchesLength={stylistMatches.length}
                                onSelect={selectStylist}
                                stylists={stylists}
                            />
                            <Row>
                                {stylistMatches.map((match) => (
                                    <Col
                                        xs={12}
                                        md={4}
                                        key={match.uuid}
                                        className="match-item-container">
                                        <MatchItem
                                            match={match}
                                            onClick={selectStylist}
                                            isFavorite={
                                                favoriteStylists?.findIndex(
                                                    (favorite) => favorite.uuid === match.uuid
                                                ) > -1
                                            }
                                            onFavorite={updateFavoriteStylistAction}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            {showMore && (
                                <Row>
                                    <Col>
                                        <div
                                            className="more-btn"
                                            onClick={loadMore}
                                            data-test-id="load-more">
                                            {texts.more}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
