import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ActionTypes, ProfileState } from './types';
import { Actions } from '../actions';

const initialState: ProfileState = {
    gridSize: 'large'
};

export const profileReducer = (state = initialState, action: Actions): ProfileState => {
    switch (action.type) {
        case ActionTypes.SET_GRID_SIZE: {
            return {
                ...state,
                gridSize: action.payload
            };
        }
        default:
            return state;
    }
};
export const name = 'profile';
export const useProfileStore: TypedUseSelectorHook<ProfileState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
