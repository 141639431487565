import '../style.scss';

import React from 'react';

export default ({ onChange, props = {}, value = '' }) => (
    <textarea
        onChange={(e) => onChange({ text: e.target.value })}
        defaultValue={value}
        {...props}
    />
);
