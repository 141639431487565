import '../style.scss';

import React from 'react';

import content from '../../../content.json';
import { StripeForm } from '../..';

const { payment } = content;

const Details = ({ model }) => (
    <div>
        {model === 'monthly' && <div className="trial-text">{payment.details.trial}</div>}
        <StripeForm />
    </div>
);

export default Details;
