import { Dispatch } from 'redux';
import { Config, Tracking } from 'services';

import { store } from 'store';
import { Booking } from 'types/booking';
import { Stylist as StylistType } from 'types/user';
import { ActionTypes, Plan, StatusOption } from './types';

export const bookStylist = (dispatch: Dispatch, stylist: StylistType) =>
    dispatch({
        type: ActionTypes.BOOK_STYLIST,
        payload: stylist
    });

export const selectGoal = (dispatch: Dispatch, goal: string) =>
    dispatch({
        type: ActionTypes.SELECT_GOAL,
        payload: goal
    });

export const setPlan = (dispatch: Dispatch, plan: Plan) =>
    dispatch({
        type: ActionTypes.SELECT_PLAN,
        payload: plan
    });

export const setCampaignBooking = (dispatch: Dispatch, campaign = null) =>
    dispatch({
        type: ActionTypes.CAMPAIGN_BOOKING,
        payload: campaign
    });
export const setBookings = (dispatch: Dispatch, payload: Booking[]) =>
    dispatch({
        type: ActionTypes.SET_BOOKINGS,
        payload
    });

export const selectPlan = (dispatch: Dispatch, plan: Plan) => {
    if (!plan) return;
    const { stylist } = store.getState().booking;

    Tracking.google({
        type: 'event',
        event: 'Funnel',
        data: { event_category: 'initiateCheckout' }
    });
    Tracking.facebook('track', 'InitiateCheckout', {
        device: Config.isMobile() ? 'mobile' : 'desktop',
        content_type: 'product',
        content_category: plan.value,
        content_ids: [stylist?.user_uuid ? stylist.user_uuid : stylist?.uuid],
        currency: 'USD',
        num_items: 1,
        value: plan.price
    });
    setPlan(dispatch, plan);
};
