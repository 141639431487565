import './style.scss';

import React, { createElement, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { Page } from 'components';
import Sections from './sections';

export const Settings = (props) => {
    const { tab = 'main' } = useParams();
    const navigate = useNavigate();
    const [section, setSection] = useState();

    useEffect(() => {
        if (tab.length) Sections[tab] ? setSection(Sections[tab]) : navigate('/settings');
    }, [tab]);

    return (
        <Page footer={false}>
            <div className="settings">
                <Container>{section ? createElement(section, props) : ''}</Container>
            </div>
        </Page>
    );
};
