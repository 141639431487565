import './style.scss';

import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Cart, Loader, Page } from 'components';
import content from 'content.json';
import { ShoppingFilter } from './';

const { shopping } = content;

const ShoppingList = ({ cartLoading, cart = [] }) => {
    return (
        <Page className="shopping-list" footer={false}>
            {cartLoading && <Loader />}
            <Container fluid>
                <Row>
                    <Col>
                        <p className="title">{shopping.title}</p>
                    </Col>
                </Row>

                {!cart.length && !cartLoading ? (
                    <Row>
                        <Col className="empty">
                            <Image src={shopping.icons.emptyCart} />
                            <p className="nothing">{shopping.nothing}</p>
                            <p>{shopping.let}</p>
                            <Link to="/stylistSearch">
                                <Button variant="dark">{shopping.browse}</Button>
                            </Link>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <ShoppingFilter />
                    </Row>
                )}

                <Cart />
            </Container>
        </Page>
    );
};

export default ShoppingList;
