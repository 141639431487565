import axios from 'axios';
import { getUserId, getUserToken } from 'services/utils/user-utils';

import Config from './Config';

const proxyRoot = Config.get('apiroot2');
const gwRoot = Config.get('api-gw');

const Payments = {
    getCoupon: (couponCode) =>
        axios.get(`${proxyRoot}coupon/${couponCode}`, { headers: { token: getUserToken() } }),

    getInvoice: (plan, couponCode) =>
        axios.get(`${proxyRoot}checkout/${plan}`, {
            params: { coupon_code: couponCode },
            headers: { token: getUserToken() }
        }),

    payInvoice: (data, model) =>
        axios.post(`${proxyRoot}${model === 'onetime' ? 'charge' : 'subscription'}`, data, {
            headers: { token: getUserToken() }
        }),

    payGiftCard: (data) => axios.post(`${proxyRoot}invoice/payGiftCard`, data),

    giftCardInvoice: (data) =>
        axios.post(
            `${proxyRoot}invoice/payGiftCard`,
            { data },
            { headers: { token: getUserToken() } }
        ),

    getCards: () =>
        axios.get(`${gwRoot}user/${getUserId()}/cards`, { headers: { token: getUserToken() } }),

    updatePaymentMethod: ({ data }) =>
        axios.post(`${gwRoot}user/${getUserId()}/cards`, data, {
            headers: { token: getUserToken() }
        }),

    getStripeKey: () => process.env.REACT_APP_STRIPE,

    payTip: (data) =>
        axios.post(`${proxyRoot}invoice/payTip`, data, { headers: { token: getUserToken() } }),

    checkoutList: (data) =>
        axios.post(`${proxyRoot}checkoutList`, data, { headers: { token: getUserToken() } }),

    checkoutListPay: (data) =>
        axios.post(`${proxyRoot}checkoutList/pay`, data, { headers: { token: getUserToken() } }),

    getCountries: () => axios.get(`${proxyRoot}country`, { headers: { token: getUserToken() } }),

    getStates: (country) =>
        axios.get(`${proxyRoot}state`, {
            params: { country },
            headers: { token: getUserToken() }
        }),
    sessions: async (userId) => {
        const resp = await axios.get(`${proxyRoot}user/${userId}/sessionsPayments`, {
            headers: { token: getUserToken() }
        });
        // hack to overcome backend bug that is returning 200 with error message in body if list is empty
        return Array.isArray(resp.data) ? resp.data : [];
    },
    paymentCardType: (payment = {}, cards = []) =>
        payment.cards[cards[0].brand.toLowerCase().replace(/\s/g, '')]
};

export default Payments;
