import './style.scss';

import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { Page } from 'components';
import content from 'content.json';

const { giftcards } = content;

const Confirmation = ({ giftcard, clearGiftCard }) => {
    const navigate = useNavigate();
    const [image, setImage] = useState('');
    const [amount, setAmount] = useState(0);
    const [cardDesign, setDesignCard] = useState('');

    useEffect(() => {
        if (giftcard.design) {
            setImage(
                _.find(giftcards.designs.cards, (card) => card.value === giftcard.design).image
            );
            setAmount(giftcard.amount + giftcard.additionalAmount);
            setDesignCard(giftcard.design);
            clearGiftCard();
        } else {
            navigate('/gift-cards');
        }
    }, []);

    return (
        <Page footer={false}>
            <Container className="gift-cards-confirmation" fluid>
                <Row>
                    <Col xs={12} sm={6} className="text">
                        <h1>{giftcards.confirmation.title}</h1>
                        <h4>{giftcards.confirmation.text}</h4>
                        <Button className="another" onClick={() => navigate(-1)}>
                            {giftcards.confirmation.button}
                        </Button>
                        <Link className="homepage" to="/">
                            {giftcards.confirmation.link}
                        </Link>
                    </Col>
                    <Col xs={12} sm={6} className="card-design">
                        <p className={`${giftcards.designs.classNames[cardDesign]}`}>
                            {giftcards.checkout.currency}
                            {amount}
                        </p>
                        <ImgWithFallback
                            src={image.src}
                            fallback={image.fallback}
                            className="fluid-giftcard"
                        />
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

export default Confirmation;
