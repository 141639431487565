import './Style.scss';

import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { StickyButton } from '../../../components';
import content from '../../../content.json';
import { Style as StyleService } from '../../../services';
import { MP_EVENTS, MP_PROPS, trackEvent } from '../../../services/mixpanel';
import { StylesRating } from '../components';
import { TRatedStyles, TStepComponentProps, TStyle, TStyleId } from '../types';

const {
    onboarding: {
        styles: { button }
    }
} = content;

export const Styles: FC<TStepComponentProps> = ({
    onboardingState: { gender, styles },
    proceed,
    rollback,
    navigateToStep
}) => {
    const isInitialized = useRef(false);
    const { innerStepIndex } = useParams<{ innerStepIndex: string }>();
    const styleIndex = Number(innerStepIndex) || 0;
    const [stylesList, setStylesList] = useState<TStyle[]>([]);
    const [ratedStyles, setRatedStyles] = useState<TRatedStyles>(new Map(styles));
    const isLastIndex = styleIndex === stylesList.length - 1;
    const proceedButtonText = isLastIndex ? button.meet : button.skip;

    const onProceedClick = () => {
        if (isLastIndex) {
            proceed({ styles: new Map(ratedStyles) });
        } else {
            trackStyleRate('skip');
            nextStyle();
        }
    };

    const rate = (style: TStyleId, value: number) => {
        const newState = new Map(ratedStyles);

        newState.set(style, value);

        setRatedStyles(newState);

        trackStyleRate(value);

        isLastIndex ? proceed({ styles: newState }) : nextStyle();
    };

    const nextStyle = () => navigateToStep('styles', styleIndex + 1);

    const trackStyleRate = (value: number | 'skip') =>
        trackEvent({
            name: MP_EVENTS.ONBOARDING_STYLE_SELECTION,
            properties: {
                [MP_PROPS.PAGE_NAME]: stylesList[styleIndex].style,
                [MP_PROPS.STYLE_SELECTION]: value
            }
        });

    useEffect(() => {
        !gender && rollback();
    }, [gender, rollback]);

    useEffect(() => {
        stylesList[styleIndex] &&
            trackEvent({
                name: MP_EVENTS.ONBOARDING_STYLE_VIEWS,
                properties: {
                    [MP_PROPS.PAGE_NAME]: stylesList[styleIndex].style
                }
            });
    }, [stylesList, styleIndex]);

    if (!gender) {
        return <></>;
    }

    if (!isInitialized.current) {
        StyleService.getMoodBoards(gender).then(({ data }) => {
            setStylesList(data as unknown as TStyle[]);
        });

        isInitialized.current = true;
    }

    return (
        <Row className="style">
            <Col className="content">
                <StylesRating
                    styleIndex={styleIndex}
                    stylesList={stylesList}
                    rate={rate}
                    ratedStyles={ratedStyles}
                />
                {styleIndex > 5 && (
                    <span>
                        <Button
                            variant="warning"
                            className="d-none d-sm-flex"
                            onClick={onProceedClick}>
                            {proceedButtonText}
                        </Button>
                        <StickyButton
                            className="d-flex d-sm-none"
                            onClick={onProceedClick}
                            text={proceedButtonText}
                            type="warning"
                        />
                    </span>
                )}
            </Col>
        </Row>
    );
};
