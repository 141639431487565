import React from 'react';
import { Card, Col, Collapse, Container, Row } from 'react-bootstrap';

const Filter = ({
    type,
    filter: { value, filter_options = [] },
    selected = [],
    isCollaped,
    onClick,
    onChange
}) => {
    return (
        <Card className={`filter-row ${isCollaped ? 'active' : ''}`}>
            <div className="filter" as={Card.Header} onClick={onClick}>
                {value}
                <span className="expand-sign" />
            </div>
            <Collapse in={isCollaped}>
                <Card.Body>
                    <Container fluid>
                        {filter_options.map(({ key, text }) => (
                            <Row key={key}>
                                <Col className="filter-item">
                                    <input
                                        className="styled-checkbox"
                                        type="checkbox"
                                        id={key}
                                        onChange={() => onChange({ key, text }, type)}
                                        checked={
                                            selected.filter((filter) => filter.key === key).length >
                                            0
                                        }
                                    />
                                    <label className="filter-label" htmlFor={key}>
                                        {text}
                                    </label>
                                </Col>
                            </Row>
                        ))}
                    </Container>
                </Card.Body>
            </Collapse>
        </Card>
    );
};

export default Filter;
