import './BodyType.scss';

import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Col } from 'react-bootstrap';

import { StickyButton } from '../../../components';
import content from '../../../content.json';
import { Style as StyleService } from '../../../services';
import { MP_EVENTS, MP_PROPS, registerProperties, trackEvent } from '../../../services/mixpanel';
import { TBodyType, TSelectedBodyTypes, TStepComponentProps } from '../types';

const {
    onboarding: {
        bodyTypes: { question, button }
    }
} = content;

export const BodyTypes: FC<TStepComponentProps> = ({
    onboardingState: { gender, bodyTypes },
    proceed,
    rollback
}) => {
    const isInitialized = useRef(false);
    const [bodyTypesList, setBodyTypesList] = useState<TBodyType[]>([]);
    const [selectedBodyTypes, setSelectedBodyTypes] = useState<TSelectedBodyTypes>(
        new Map(bodyTypes)
    );
    const proceedButtonText = selectedBodyTypes.size ? button.next : button.skip;

    const onProceedClick = () => {
        if (selectedBodyTypes.size) {
            const bodyTypesNames = [...selectedBodyTypes.values()]
                .map(({ name }) => name.toLowerCase())
                .join(',');

            registerProperties({ [MP_PROPS.BODY_TYPE]: bodyTypesNames });
            trackEvent({
                name: MP_EVENTS.ONBOARDING_BODY_TYPE_SELECTION
            });
        }

        proceed({ bodyTypes: new Map(selectedBodyTypes) });
    };

    const toggleBodyTypeSelection = (bodyType: TBodyType) => {
        setSelectedBodyTypes((prevState) => {
            const newState = new Map(prevState);
            const { uuid } = bodyType;
            newState.has(uuid) ? newState.delete(uuid) : newState.set(uuid, bodyType);

            return newState;
        });
    };

    useEffect(() => {
        !gender && rollback();
    }, [gender, rollback]);

    if (!gender) {
        return <></>;
    }

    if (!isInitialized.current) {
        StyleService.getBodyTypes(gender).then(({ data }) => {
            setBodyTypesList(data as unknown as TBodyType[]);
        });

        trackEvent({
            name: MP_EVENTS.ONBOARDING_BODY_TYPE_VIEWS
        });

        isInitialized.current = true;
    }

    return (
        <div className="body-type">
            <Col className="content">
                <p className="question web">{question.web}</p>
                <p className="question mobile">{question.mobile}</p>
                <div className="types">
                    {bodyTypesList.map((bodyType) => (
                        <div
                            key={bodyType.uuid}
                            className="tag"
                            data-selected={selectedBodyTypes.has(bodyType.uuid)}
                            onClick={() => toggleBodyTypeSelection(bodyType)}>
                            {bodyType.name}
                        </div>
                    ))}
                </div>
                <Button variant="warning" className="d-none d-sm-flex" onClick={onProceedClick}>
                    {proceedButtonText}
                </Button>
                <StickyButton
                    className="d-flex d-sm-none"
                    onClick={onProceedClick}
                    text={proceedButtonText}
                    type="warning"
                />
            </Col>
        </div>
    );
};
