import { createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'
import { stylistApi } from './stylist-api-slice';
import { RootState } from 'store';

interface NewStylistState {
  stylistId: string;
}

const initialState = { stylistId: '' } satisfies NewStylistState as NewStylistState

const newStylistSlice = createSlice({
  name: 'newStylist',
  initialState,
  reducers: {
    // increment(state) {
    //   state.value++
    // },
    // decrement(state) {
    //   state.value--
    // },
    // incrementByAmount(state, action: PayloadAction<number>) {
    //   state.value += action.payload
    // },
  },
  extraReducers(builder) {
    builder.addMatcher(stylistApi.endpoints.getStylist.matchFulfilled, (state, { payload }) => {
        state.stylistId = payload.uuid;
    })
  },
})

// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const selectLastStylistId = (state: RootState) => state.newStylistSlice.stylistId;

export default newStylistSlice.reducer;