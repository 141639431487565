import React from 'react';
import './ImgWithFallback.styles.scss';

interface ImgWithFallbackProps extends React.HTMLAttributes<HTMLImageElement> {
    src: string;
    fallbackSrc: string;
    mediaType?: string;
    alt?: string;
    fluid?: string;
    containerClass?: string;
}

const ImgWithFallback: React.FC<ImgWithFallbackProps> = ({
    src,
    fallbackSrc,
    mediaType = 'image/webp',
    containerClass,
    ...delegated
}) => {
    return (
        <picture className={containerClass ?? 'picture-container'}>
            <source srcSet={src} type={mediaType} />
            <img src={fallbackSrc} {...delegated} />
        </picture>
    );
};

export default ImgWithFallback;
