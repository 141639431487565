import './style.scss';

import React from 'react';
import { useRef } from 'react';

const Masonry = ({ children, columns = 4 }) => {
    const ref = useRef(null);

    const mapChildren = () => {
        let col = [];
        const numC = columns;
        for (let i = 0; i < numC; i++) {
            col.push([]);
        }
        return children.reduce((p, c, i) => {
            p[i % numC].push(c);
            return p;
        }, col);
    };

    return (
        <div className="masonry" ref={ref}>
            {mapChildren().map((col, ci) => (
                <div className="column" key={ci}>
                    {col.map((child, i) => (
                        <div key={i} className="tile">
                            {child}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Masonry;
