import '../style.scss';

import React, { useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';

export default ({ onChange, value = '', className = '', picture, checked = false }) => {
    const [check, setCheck] = useState(false);
    useEffect(() => {
        setCheck(checked);
    }, [checked]);

    const onChecked = () => {
        onChange({ text: value });
        setCheck(value);
    };

    return (
        <Form.Group className={`color ${check ? 'selected' : ''} ${className}`} onClick={onChecked}>
            <Image src={picture} alt="" />
        </Form.Group>
    );
};
