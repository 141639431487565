import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ActionTypes, PaymentsState } from './types';
import { Actions } from '../actions';

const initialState: PaymentsState = {
    model: 'onetime'
};
export const paymentReducer = (state = initialState, action: Actions): PaymentsState => {
    switch (action.type) {
        case ActionTypes.SET_MODEL: {
            return {
                ...state,
                model: action.payload ?? initialState.model
            };
        }
        default:
            return state;
    }
};
// TODO: should be payments - but there is duplication with old payments.
export const name = 'payment';
export const usePaymentsStore: TypedUseSelectorHook<PaymentsState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
