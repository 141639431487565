import 'rc-slider/assets/index.css';
import './style.scss';

import { Range } from 'rc-slider';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';

import { Formatter } from '../../services';

interface IFilterSlider {
    filterKey: any;
    options: Record<string, any>;
    selected: Array<any>;
    min: number | undefined;
    max: number | undefined;
    onChange: ({ key, value }: { key: string; value: Record<any, any> | number }) => void;
    onClear: (filterKey: string) => void;
    filterSubmited: boolean;
}

const FilterSlider: React.FC<IFilterSlider> = ({
    filterKey,
    options,
    selected,
    min = 1,
    max = 5000,
    onChange,
    onClear,
    filterSubmited
}) => {
    const [range, setRange] = useState({ min: min, max: max });
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (selected) {
            const select = getSelected();
            if (select) setRange({ min: select.min, max: select.max });
        }
    }, [selected]);

    useEffect(() => {
        if (!filterSubmited) setSubmitted(filterSubmited);
    }, [filterSubmited]);

    const submit = () => {
        if (!submitted) {
            if (Object.keys(options).length) {
                onChange({
                    key: filterKey,
                    value: Object.keys(options).map((key) => ({
                        key: options[key].key,
                        value: range[key == 'min' ? key : 'max'],
                        text: options[key].text
                    }))
                });
                setSubmitted(true);
            } else {
                onChange({ key: filterKey, value: range });
                setSubmitted(true);
            }
        } else {
            setSubmitted(false);
            onClear('minPrice');
            setRange({ min: min, max: max });
        }
    };

    const getSelected = () => {
        if (selected && selected[0] && selected[1]) {
            const _min = selected.find((item) => item && item.key && item.key.includes('min'));
            const _max = selected.find((item) => item && item.key && item.key.includes('max'));

            return {
                min: _min.value && _min.value[0] && _min.value[0].key ? _min.value[0].key : min,
                max: _max.value && _max.value[0] && _max.value[0].key ? _max.value[0].key : max
            };
        }
    };

    return (
        <Col className="filter-slider">
            <Form>
                <Form.Control
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parseInt(e.target.value.substr(1));
                        const values = {
                            ...range,
                            min: isNaN(value) || value < 0 ? range.min : value
                        };
                        setRange(values);
                    }}
                    value={`${Formatter.price(range.min)}`}
                />
                <Form.Control
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parseInt(e.target.value.substr(1));
                        const values = {
                            ...range,
                            max: isNaN(value) || value < 1 ? range.max : value
                        };
                        setRange(values);
                    }}
                    value={`${Formatter.price(range.max)}${range.max === max ? '+' : ''}`}
                />
                <Button onClick={submit} className={!submitted ? 'submit' : 'clear'} />
            </Form>
        </Col>
    );
};

export default FilterSlider;
