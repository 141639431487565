import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectApiToken } from 'store/auth-service/auth-slice';
import { useSelector } from 'react-redux';

export const ProtectedRoute: React.FC<{ redirectPath?: string }> = ({ redirectPath = '/' }) => {
    const token = useSelector(selectApiToken);
    const location = useLocation();

    if (!token) {
        return <Navigate to={redirectPath} replace state={{ from: location }} />;
    }
    return <Outlet />;
};
