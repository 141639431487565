import './style.scss';

import React from 'react';
import { Carousel, Image } from 'react-bootstrap';

const ImagesCarousel = ({ items, controls = false, indicators = false, interval = 5000 }) => (
    <Carousel
        className="images-carousel"
        controls={controls}
        indicators={indicators}
        interval={interval}
    >
        {items.map((item) => (
            <Carousel.Item key={item}>
                <Image src={item} alt="wishi" fluid />
            </Carousel.Item>
        ))}
    </Carousel>
);
export default ImagesCarousel;
