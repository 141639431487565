import { Dispatch } from 'redux';
import { AuthService, User } from 'services';
import { ICredentials } from 'services/auth-service';
import { LdEvent } from 'services/LaunchDarkly';
import {
    MP_EVENTS,
    MP_PROPS,
    registerProperties,
    resetMixpanelIdentity,
    trackEvent
} from 'services/mixpanel';
import ReduxService from 'services/redux-service';
import { sentryException } from 'services/SentryLogging';
import { getUserId, getUserToken } from 'services/utils/user-utils';
import { store } from 'store';
import { setupError } from 'store/error/actions';
import { rootSplitApi } from 'store/root-api-slice';
import { toggleModal } from 'store/ui/actions';
import { Client, Stylist, SystemUser } from 'types/user';

import { Tracking } from '../../services';
import { ActionTypes } from './types';
import { signIn, signOut } from 'store/auth-service/auth-slice';

export const logoutUser = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.LOGOUT_USER
    });
export const toggleFavoriteStylist = (dispatch: Dispatch, stylist: Stylist) =>
    dispatch({
        type: ActionTypes.TOGGLE_STYLIST,
        payload: stylist
    });
export const updateUser = (dispatch: Dispatch, user: Partial<Client>) => {
    dispatch({
        type: ActionTypes.UPDATE_USER,
        payload: user
    });
};

export const login = async (
    dispatch: Dispatch,
    credentials: Partial<ICredentials>,
    source?: string
) => {
    try {
        const user = await AuthService.login(credentials);

        if (user) {
            updateUser(dispatch, user);

            if (source === 'form') {
                trackEvent({ name: MP_EVENTS.LOGIN });
            }

            dispatch(
                signIn({
                    apiToken: user.token,
                    sdkToken: user.sdkAccessToken,
                    userId: user.user_uuid
                })
            );
            return user;
        } else {
            dispatch({
                type: ActionTypes.LOGOUT_USER,
                payload: null
            });
            toggleModal(dispatch, { type: null });
        }
    } catch (err) {
        setupError(dispatch, {
            error: err,
            message: (err as any)?.message,
            errors: (err as any).errors
        });
    }
};
export const logout = (dispatch: Dispatch) => {
    AuthService.logout();
    dispatch(rootSplitApi.util.resetApiState());
    logoutUser(dispatch);
    dispatch(signOut());
    resetMixpanelIdentity();
};
export const signup = async (
    dispatch: Dispatch,
    credentials: ICredentials,
    trackLdEvent: (event: LdEvent) => void
) => {
    try {
        const user = await AuthService.signup(credentials);

        updateUser(dispatch, user);
        registerProperties({
            [MP_PROPS.SIGNUP_PLATFORM]: 'web',
            [MP_PROPS.SIGNUP_DATE]: new Date().toDateString()
        });
        trackLdEvent(LdEvent.SIGNUP_COMPLETED);
        dispatch(
            signIn({ apiToken: user.token, sdkToken: user.sdkAccessToken, userId: user.user_uuid })
        );

        trackEvent({
            name: MP_EVENTS.SIGNUP_COMPLETED,
            properties: {
                [MP_PROPS.SIGNUP_METHOD]: credentials.email
                    ? 'email'
                    : credentials.googleAuthCode
                      ? 'google'
                      : 'unknown'
            }
        });

        Tracking.google({
            type: 'event',
            event: 'sign_up',
            data: {
                user_full_name: `${user.firstName} ${user.lastName}`,
                user_uuid: user.uuid,
                user_email: user.email
            }
        });
    } catch (err) {
        setupError(dispatch, {
            error: err,
            message: (err as any)?.message,
            errors: (err as any).errors
        });
    }
};
export const loadFavoriteStylists = async (dispatch: Dispatch, params = { from: 0, count: 30 }) => {
    const userId = getUserId();
    if (!userId) return;
    await ReduxService.fetch({
        dispatch,
        targetAction: ActionTypes.LOAD_FAVORITE_STYLISTS,
        url: User.urls.favorites({ uuid: userId }),
        params,
        config: { headers: { token: getUserToken() } },
        prettifyData: ({ quota_max, items }: { quota_max: number; items: any[] }) => items
    });
};
export const updateGeneralData = async (dispatch: Dispatch, data: SystemUser) => {
    try {
        await User.update(data);
        const { data: newUser } = await User.get(data.user_uuid);
        updateUser(dispatch, newUser);
        return;
    } catch (err) {
        sentryException(err as Error, 'Cannot update general user data');
        setupError(dispatch, {
            error: err,
            message: (err as any)?.message,
            errors: (err as any).errors
        });
    }
};
export const updateProfilePicture = async (
    dispatch: Dispatch,
    files: FileList,
    { user_uuid, token }: { user_uuid: string; token: string }
) => {
    try {
        const formData = new FormData();

        formData.append('picture', files[0], files[0].name);
        await User.profilePicture({
            uuid: user_uuid,
            token: token,
            data: formData
        });
        const { data } = await User.get(user_uuid);
        updateUser(dispatch, data);
        return;
    } catch (err) {
        sentryException(err as Error, "Cannot update user's profile picture");
    }
};

export const updateFavoriteStylist = async (dispatch: Dispatch, stylist: Stylist) => {
    try {
        const userId = getUserId();
        if (userId) {
            const favoriteStylists = store.getState().user.favoriteStylists;
            const isFavorite = favoriteStylists.find(
                (favorite: Stylist) => favorite.uuid === stylist.uuid
            );
            toggleFavoriteStylist(dispatch, stylist);

            await (User[isFavorite == undefined ? 'favorite' : 'unfavorite'] as any)({
                user_uuid: userId,
                stylist_uuid: stylist.uuid
            });
        }
    } catch (e) {
        sentryException(e as Error, 'Cannot update favorite stylist');
    }
};

export const updateFavoriteSection = (dispatch: Dispatch, section: string) => {
    dispatch({
        type: ActionTypes.UPDATE_FAVORITE_SECTION,
        payload: section
    });
};
