import { useEffect, useState } from 'react';

export const useMatchMedia = (query: string) => {
    const isMatch = () => window.matchMedia(query).matches;
    const [match, setMatch] = useState(isMatch());

    useEffect(() => {
        const onResize = () => {
            isMatch() !== match && setMatch(isMatch());
        };

        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, [match, setMatch, isMatch]);

    return match;
};
