import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { PressCarousel } from '../../../components';

const Press = ({ data }) => (
    <Row>
        <Col className="no-padding">
            <PressCarousel items={data} />
        </Col>
    </Row>
);

export default Press;
