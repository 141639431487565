import { FC } from 'react';

import { GetStartedButton } from './GetStartedButton';

export const FixedButtonContainer: FC = () => {
    return (
        <div className="fixed-button-container">
            <GetStartedButton />
        </div>
    );
};
