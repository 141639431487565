import { FC } from 'react';

type TReason = {
    title: string;
    description: string;
};

const reasons: TReason[] = [
    {
        title: 'Seasonal Capsules',
        description:
            'Trusting an expert ensures the best wardrobe for your needs. Your Wishi stylist is a fashion-trained pro with knowledge of classics and trends.'
    },
    {
        title: 'Build your Digital Closet',
        description:
            'Maximize the wardrobe you already have! Our sustainable approach to loving and reimagining what you own is key to getting more out of your session!'
    },
    {
        title: 'Personalized Concierge',
        description:
            'Experience the session on the next level, by chatting one-on-one with your stylist. Having two possible calls to bounce off ideas is perfect for elevating your style!'
    }
];

export const Why: FC = () => {
    return (
        <div className="why">
            <h2>Why Book Lux?</h2>
            <ul>
                {reasons.map(({ title, description }, index) => (
                    <li key={index}>
                        <h3>{title}</h3>
                        <p>{description}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};
