import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import { useIsMobile } from 'hooks';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick/lib';

const Clients = ({ data }) => {
    const isMobile = useIsMobile();
    return (
        <Row className="clients">
            <Container>
                <Row>
                    <Col>
                        <p className="section-title">{data.title}</p>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Slider
                    className="clients-carousel"
                    dots={false}
                    infinite={true}
                    centerMode={true}
                    centerPadding="30px"
                    speed={500}
                    slidesToShow={5}
                    slidesToScroll={1}
                    autoplay={false}
                    arrows={!isMobile}
                    responsive={[
                        {
                            breakpoint: 2000,
                            settings: {
                                slidesToShow: 4
                            }
                        },
                        {
                            breakpoint: 1060,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]}
                >
                    {data.images.map((item) => (
                        <Card key={item.name}>
                            <Card.Body>
                                <ImgWithFallback
                                    src={item.image.src}
                                    fallbackSrc={item.image.fallback}
                                    className="img-fluid"
                                />
                                <p>{item.name}</p>
                            </Card.Body>
                        </Card>
                    ))}
                </Slider>
            </Container>
        </Row>
    );
};

export default Clients;
