import { FC } from 'react';

type TNewsArticle = {
    outlet: {
        name: string;
        logo: string;
    };
    url: string;
};

const news: TNewsArticle[] = [
    {
        outlet: {
            name: 'Vogue',
            logo: 'https://media-cf.wishi.me/react/lux/vogue.png'
        },
        url: 'https://www.vogue.com/article/karla-welch-wishi-app-personal-stylist?verso=true'
    },
    {
        outlet: {
            name: 'Forbes',
            logo: 'https://media-cf.wishi.me/react/lux/forbes.png'
        },
        url: 'https://www.forbes.com/sites/sharonedelson/2022/01/20/saks-fifth-avenue-styles-a-partnership-with-wishi-for-new-service/'
    },
    {
        outlet: {
            name: 'WWD',
            logo: 'https://media-cf.wishi.me/react/lux/wwd.png'
        },
        url: 'https://wwd.com/fashion-news/fashion-scoops/lauren-santo-domingo-joining-wishi-styling-platform-1234899533/'
    },
    {
        outlet: {
            name: 'Instyle',
            logo: 'https://media-cf.wishi.me/react/lux/instyle.png'
        },
        url: 'https://www.instyle.com/best-online-styling-services-7101194'
    },
    {
        outlet: {
            name: 'GQ',
            logo: 'https://media-cf.wishi.me/react/lux/gq.png'
        },
        url: 'https://www.gq.com/story/celebrity-stylist-karla-welch-app'
    }
];

export const News: FC = () => {
    return (
        <div className="news">
            <h2>Wishi In The News</h2>
            <ul>
                {news.map(({ outlet, url }, index) => (
                    <li key={index}>
                        <a href={url} target="_blank" rel="noreferrer">
                            <img src={outlet.logo} alt={outlet.name} />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
