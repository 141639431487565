import { connect } from 'react-redux';

import { loadOrders } from '../../redux/reducers/users/actions';
import Orders from './Orders';

const mapStateToProps = ({ users: { orders, ordersTotal, nextPage }, user: { user } }) => ({
    user,
    orders,
    ordersTotal,
    nextPage
});

const mapDispatchToProps = (dispatch) => ({
    loadOrders: (params) => dispatch(loadOrders(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
