import './style.scss';

import React, { FC } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { useWithDispatch } from '../../hooks';
import { toggleModal } from '../../store/ui/actions';
import { useUIStore } from '../../store/ui/reducer';
import ModalContent from '../ModalContent';

const _Modal: FC = () => {
    const modalData = useUIStore((store) => store.modalData);
    const modalType = useUIStore((store) => store.modalType);
    const toggleModalAction = useWithDispatch(toggleModal);
    const show = useUIStore((store) => store.indicators.showModal);
    const Content = modalType && ModalContent[modalType];

    return (
        <BootstrapModal show={show} onHide={toggleModalAction} className={`modal ${modalType}`}>
            {Content && <Content data={modalData as any} />}
        </BootstrapModal>
    );
};

export const Modal = connect()(_Modal);
