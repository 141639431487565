import { FC } from 'react';
import { Link } from 'react-router-dom';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';

export const BuyButton: FC = () => {
    const report = () => {
        klaviyoTrack(KlaviyoEvent.GIFTCARD_BUY_CLICK);
    };

    return (
        <Link to="checkout" className="btn btn-dark" onClick={report}>
            Buy gift cards
        </Link>
    );
};
