import { connect } from 'react-redux';

import { updateItem } from 'redux/reducers/style/actions';
import {
    addToCart,
    addToCloset,
    clearCart,
    clearCartFilter,
    removeFromCart,
    updateCartFilter
} from 'redux/reducers/users/actions';
import _ShoppingFilter from './ShoppingFilter';
import ShoppingListComponent from './ShoppingList';

const mapStateToProps = ({
    users: { cartLoading, cart, cartTotal, cartFilter, activeCartFilter }
}) => ({
    cartLoading,
    cart,
    cartTotal,
    cartFilter,
    activeCartFilter
});

const mapDispatchToProps = (dispatch) => ({
    addToCart: (item) => dispatch(addToCart(item)),
    removeFromCart: (item) => dispatch(removeFromCart(item)),
    addToCloset: (item) => dispatch(addToCloset(item)),
    filter: (filter) => dispatch(updateCartFilter(filter)),
    clear: () => dispatch(clearCartFilter()),
    clearCart: () => dispatch(clearCart()),
    updateItem: (item) => dispatch(updateItem(item))
});

export const ShoppingFilter = connect(mapStateToProps, mapDispatchToProps)(_ShoppingFilter);
export const ShoppingList = connect(mapStateToProps, mapDispatchToProps)(ShoppingListComponent);
