import './style.scss';

import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';

const Collage = ({ images = [] }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (images.length > 0) {
            setShow(true);
        }
    }, [images]);

    return (
        <Container className="collage" fluid>
            {show && (
                <Row className="images-row">
                    {images.map((item) => (
                        <ImgWithFallback
                            key={item.src}
                            src={item.src}
                            fallbackSrc={item.fallback}
                        />
                    ))}
                </Row>
            )}
        </Container>
    );
};

export default Collage;
