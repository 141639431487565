import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import { addClosetItem } from '../../../redux/reducers/users/actions';
import AddClosetItem from './AddClosetItem';

const mapStateToProps = ({ users: { filters }, user: { user } }) => ({ user, filters });

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    addClosetItem: (item) => dispatch(addClosetItem(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClosetItem);
