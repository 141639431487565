import './style.scss';

import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import React, { useEffect, useRef, useState } from 'react';

/*import { Payments } from '../../../services';

const stripe = require('stripe')(Payments.getStripeKey());
stripe.applePayDomains.create({ domain_name: 'wishi.me' });*/

const getPriceInCents = (price) => {
    const value = !price ? 0 : 
                (price && typeof price === 'string') ? 
                    parseFloat(price.replace(/,/g, '')) : 
                    price;
    return Math.floor(value * 100);
};

const PaymentRequestButton = ({
    stripe,
    price,
    show = false,
    onShow,
    onSuccess,
    shipping = {},
    onClick,
    disabled = false,
    props = {}
}) => {
    const [paymentRequest, setPaymentRequest] = useState(null);
    const initialized = useRef(false);

    const setStripe = async (stripe) => {
        const pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            requestPayerName: shipping.show,
            requestPayerEmail: shipping.show,
            requestPayerPhone: shipping.show,
            requestShipping: shipping.show,
            total: {
                label: props.stripeLabel
                    ? props.stripeLabel
                    : `Styling session ${props.user ? `for ${props.user.first_name}` : ''}`,
                amount: getPriceInCents(price)
            }
        });

        pr.on('token', (result) => {
            const { token, complete } = result;
            complete('success');
            onSuccess(token.id, { ...result, props });
        });

        pr.on('shippingaddresschange', async (ev) => {
            const { country, city, postalCode, region = '' } = ev.shippingAddress;
            const price = await shipping.price({
                data: { state: region, country, city, zipcode: postalCode }
            });

            if (!postalCode) {
                ev.updateWith({ status: 'invalid_shipping_address' });
            } else {
                ev.updateWith({
                    status: 'success',
                    total: {
                        amount: getPriceInCents(price.total),
                        label: price.item.name
                    },
                    shippingOptions: [
                        {
                            id: 'shipping',
                            label: 'Wishi',
                            detail: 'Wishi',
                            amount: getPriceInCents(price.shipping),
                        }
                    ]
                });
            }
        });

        if (await pr.canMakePayment()) {
            onShow();
            setPaymentRequest(pr);
        }
    };

    useEffect(() => {
        if (stripe && paymentRequest) {
            paymentRequest.update({
                total: {
                    label: props.stripeLabel
                        ? props.stripeLabel
                        : `Styling session ${props.user ? `for ${props.user.first_name}` : ''}`,
                    amount: getPriceInCents(price)
                }
            });
        }
    }, [stripe, paymentRequest, props, price]);

    if (stripe && !initialized.current) {
        initialized.current = true;
        setStripe(stripe);
    }

    return paymentRequest && show ? (
        <PaymentRequestButtonElement
            options={{
                style: {
                    paymentRequestButton: {
                        type: 'default',
                        theme: 'dark',
                        height: `${props.height ? props.height : 47}px`
                    }
                },
                paymentRequest
            }}
            className={`StripeElement payment-request-button ${disabled ? 'disabled' : ''}`}
            onClick={onClick ? onClick : (event) => event}
        />
    ) : (
        <></>
    );
};

export default PaymentRequestButton;
