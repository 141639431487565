import './style.scss';

import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { StylistUser } from 'types/stylist';

interface IGoalsHeader {
    onBack: () => void;
    stylist: StylistUser;
}

const GoalsHeader: React.FC<IGoalsHeader> = ({ stylist, onBack }) => (
    <Container className="goals-header" fluid>
        <Row>
            <Col className="profile-image">
                <div className="back-btn no-text" onClick={onBack} />
                {stylist && <Image src={stylist.profile_picture} />}
            </Col>
        </Row>
        <Row className="name">{stylist && <Col>{stylist.name}</Col>}</Row>
    </Container>
);

export default GoalsHeader;
