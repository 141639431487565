import './style.scss';

import { Filters, GridItem, Loader } from 'components';
import { useWithDispatch } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Col, Container, Image, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { useProfileStore } from 'store/profile/reducer';
import { toggleModal } from 'store/ui/actions';
import { useUserStore } from 'store/user/reducer';

import content from 'content.json';
import Empty from './Empty';

const texts = content.profile;

interface ICloset {
    loading: any;
    loadingFilters: any;
    closet: any;
    filters: any;
    totalItems: any;
    selectedFilters: any;
    error: any;
    loadCloset: any;
    loadClosetFilters: any;
    updateFilters: any;
}
const Closet: React.FC<ICloset> = ({
    loading,
    loadingFilters,
    closet,
    filters,
    totalItems,
    selectedFilters,
    error,
    loadCloset,
    loadClosetFilters,
    updateFilters
}) => {
    const navigate = useNavigate();
    const resultsRef = useRef<HTMLDivElement>();
    const [gridItems, setGridItems] = useState([]);
    const user = useUserStore((store) => store.user);
    const gridSize = useProfileStore((store) => store.gridSize);
    const toggleModalAction = useWithDispatch(toggleModal);

    useEffect(() => {
        if (user) {
            loadClosetFilters({
                fields: 'categories,colors,retailers,prices,brands,sizes'
            });
            fetchData({});
        }
    }, [user]);

    useEffect(() => {
        if (!loading && closet) setGridItems(closet);
    }, [closet]);

    useEffect(() => {
        if (error && error.profile)
            toggleModalAction({ type: 'Error', data: { error: texts.errors.closet } });
    }, [error]);

    const fetchData = (filter: any) => {
        const activeFilters = filter ? filter : selectedFilters;
        const params = Object.keys(activeFilters).reduce(
            (ac, a) =>
                activeFilters[a].length
                    ? {
                          ...ac,
                          [a]: activeFilters[a].map((selection: any) => selection.key).join(',')
                      }
                    : ac,
            {}
        );
        loadCloset({ ...params, from: !filter && closet ? closet.length : 0 });
    };

    const onFilterChange = (filter: any | undefined = undefined) => {
        const isSelected =
            selectedFilters[filter.key] &&
            selectedFilters[filter.key].findIndex((item: any) => item.key === filter.value.key) >
                -1;
        updateFilters(filter, isSelected, fetchData);
    };

    const onItemRemove = (item: any) => toggleModalAction({ type: 'RemoveItem', data: item });

    const addItem = () => toggleModalAction({ type: 'AddClosetItem' });

    return (
        <Container className="profile-closet" fluid>
            <Row>
                <Col className="filters-column d-none d-md-block">
                    {loadingFilters && <Loader />}
                    <p className="total">{texts.items.replace('%count%', totalItems)}</p>
                    <Filters
                        className={
                            selectedFilters.category_uuid && !selectedFilters.category_uuid.length
                                ? 'categories-all'
                                : ''
                        }
                        filters={filters}
                        selected={selectedFilters}
                        setFilter={onFilterChange}
                        showSelection={true}
                    />
                </Col>
                <Col id="results" className="results" ref={resultsRef as any}>
                    {loading && <Loader />}

                    {gridItems.length ? (
                        <span>
                            <div className="results-bar">
                                <div className="filter-badges">
                                    {Object.keys(selectedFilters).map((key) =>
                                        selectedFilters[key]?.map((filter: any) => (
                                            <Badge variant="light" key={filter.key}>
                                                <div
                                                    className="close"
                                                    onClick={() =>
                                                        onFilterChange({ key, value: filter })
                                                    }
                                                />
                                                {filter.text}
                                            </Badge>
                                        ))
                                    )}
                                </div>
                            </div>
                            <InfiniteScroll
                                dataLength={gridItems ? gridItems.length : 0}
                                next={fetchData as any}
                                hasMore={true}
                                scrollableTarget="results"
                                loader={null}>
                                {gridItems.map((item: any) => (
                                    <GridItem
                                        key={item.item_uuid}
                                        type="profile"
                                        size={gridSize}
                                        item={item}
                                        onDetailsClick={() => navigate(`/item/${item.uuid}`)}
                                        onImageClick={() =>
                                            toggleModalAction({
                                                type: 'Photo',
                                                data: item.picture_large
                                            })
                                        }
                                        onRemove={() => onItemRemove(item)}>
                                        {[]}
                                    </GridItem>
                                ))}
                            </InfiniteScroll>
                        </span>
                    ) : (
                        <Empty section="closet" />
                    )}
                    <div className="add-btn" onClick={addItem}>
                        {!gridItems.length && (
                            <div className="empty-add-text">
                                <p>{texts.add}</p>
                                <Image src={texts.addArrow} />
                            </div>
                        )}
                        <label>+</label>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
export default Closet;
