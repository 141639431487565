import { connect } from 'react-redux';
import { setCampaignBooking } from 'store/booking/actions';
import { loadUserMatches } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';
import StylistComponent from './Stylist';

const mapStateToProps = ({ user: { user }, common: { mixpanelStore } }) => ({
    user,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    loadUserMatches: () => loadUserMatches(dispatch),
    setCampaign: (campaign) => setCampaignBooking(dispatch, campaign),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export const Stylist = connect(mapStateToProps, mapDispatchToProps)(StylistComponent);
