import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MP_EVENTS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { itemToProps } from 'services/utils/mixpanel-utils';

import { Loader, Outfit as OutfitItem, Page } from 'components';
import content from 'content.json';
import { isExternalItem } from 'services/utils/item-utils';

const { feed } = content;

const Outfit = ({
    loading,
    looksLoading,
    loadOutfit,
    loadStylistLooks,
    loadFavoriteLooks,
    outfit,
    looks,
    looksTotal,
    clearLooks,
    clearOutfit,
    updateStylistLook,
    updateItem,
    updateOutfit,
    favoriteLooks = [],
    cart = [],
    user
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    let { outfit_uuid } = useParams();
    const PAGE_ITEMS = 5;
    const [jump, setJump] = useState(false);
    const isMobile = useIsMobile();
    const isNotMainOutfit = (look) => look.uuid !== outfit_uuid && look.unique !== outfit_uuid;
    const onBackClick = () =>
        location.state?.back ? navigate(-1) : navigate(`/feed#${outfit.unique}`);

    const onItemClick = (item, outfitId) => {
        trackEvent({
            name: MP_EVENTS.ITEM_CLICKS,
            properties: itemToProps(
                item.item_uuid,
                location.state?.source ?? MP_VALUES.FEED,
                item.brand_name,
                outfit.unique
            )
        });

        if (isExternalItem(item)) {
            window.open(item.buy_url, '_blank');
        }

        return navigate(`/feed/outfit/${outfitId}/item/${item.uuid}`);
    };

    useEffect(() => {
        if (outfit_uuid) loadOutfit(outfit_uuid);
        updateItem(); // clear previously shown item from store

        return () => {
            clearOutfit();
            clearLooks();
        };
    }, []);

    useEffect(() => {
        if (outfit.owner && !looksLoading) {
            loadStylistLooks(outfit.owner.uuid, {
                gender: outfit.attributes.gender,
                from: 1,
                count: PAGE_ITEMS
            });
            loadFavoriteLooks();
        }
    }, [outfit]);

    return (
        <Page footer={false} header={!isMobile}>
            {loading && <Loader />}
            <div className="outfit-page">
                {isMobile && !user && (
                    <Link className="take-btn" to="/onboarding?source=outfit" target="_self">
                        <span>Let’s Find Your Style Match</span>
                        <u>Take our style quiz</u>
                    </Link>
                )}
                <div className="header">
                    <div className="back-btn" onClick={onBackClick}>
                        <label>
                            {location.state && location.state.text
                                ? location.state.text
                                : feed.item.back}
                        </label>
                    </div>

                    {user && cart && (
                        <Link to="/shopping-list" className="cart">
                            <span className="cart-icon">{cart.length > 0 && cart.length}</span>
                        </Link>
                    )}
                </div>
                <OutfitItem
                    key={outfit.unique}
                    outfit={outfit}
                    isFavorite={favoriteLooks.find((favorite) => favorite.uuid === outfit.unique)}
                    onChange={(changed) => updateOutfit(changed)}
                    onItemSelect={(item) => onItemClick(item, outfit.uuid ?? outfit.unique)}
                    isMainOutfit={true}
                    outfitSource="feed"
                />
                {outfit.owner && looks.length > 0 && (
                    <div className="more">
                        {feed.item.more.replace('%stylist%', outfit.owner.first_name)}
                    </div>
                )}
                <InfiniteScroll
                    style={{ overflow: 'visible' }}
                    dataLength={looks.length}
                    onScroll={() => setJump(window.pageYOffset > 0)}
                    next={() => {
                        loadStylistLooks(outfit.owner.uuid, {
                            gender: outfit.gender,
                            from: looks.length,
                            count: PAGE_ITEMS
                        });
                    }}
                    hasMore={looks.length < looksTotal}>
                    {looks.map(
                        (look) =>
                            isNotMainOutfit(look) && (
                                <OutfitItem
                                    key={look.uuid}
                                    outfit={look}
                                    isFavorite={favoriteLooks.find(
                                        (favorite) => favorite.uuid === look.uuid
                                    )}
                                    onChange={(changed) => updateStylistLook(look.uuid, changed)}
                                    onItemSelect={(item) => onItemClick(item, look.uuid)}
                                    isMainOutfit={false}
                                />
                            )
                    )}
                </InfiniteScroll>
                {jump && (
                    <div className="top-btn d-none d-sm-flex" onClick={() => window.scrollTo(0, 0)}>
                        {feed.top}
                    </div>
                )}
            </div>
        </Page>
    );
};

export default Outfit;
