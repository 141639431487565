import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Content from '../../../content';

const Recommendation = ({ stylist, user }) => (
    <Row className="why">
        {stylist.why_we_recommend && (
            <Col>
                <div className="title">
                    {stylist.uuid &&
                        Content.stylist.why.replace(
                            '%name%',
                            user && stylist && user.user_uuid === stylist.uuid
                                ? Content.stylist.you
                                : stylist.first_name
                        )}
                </div>
                <p>{stylist.why_we_recommend}</p>
            </Col>
        )}
        <hr />
    </Row>
);

export default Recommendation;
