import { FC } from 'react';

export const Schedule: FC = () => {
    const onScheduleClick = () => {
        window.open('https://calendly.com/ninane-wishi/wishi-consultation?month=2024-09');
    };

    return (
        <div className="schedule">
            <h2>Not Sure Which Plan Suits You Best?</h2>
            <p>
                Schedule a free consultation to discuss your style goals. We'll help you understand
                our plans and find the perfect fit for your needs.
            </p>
            <button className="btn btn-light" onClick={onScheduleClick}>
                <i className="icon" />
                Schedule Your Call
            </button>
        </div>
    );
};
