import axios from 'axios';
import { Client as ConversationsClient, Conversation, Paginator } from '@twilio/conversations';
import { sentryException } from 'services/SentryLogging';

import { Config } from '.';
import { getUserId, getUserToken } from 'services/utils/user-utils';

class TwilioService {
    client: ConversationsClient | null = null;
    channels: Paginator<Conversation> | undefined;
    subscribed: boolean;
    token: string;
    constructor() {
        this.client = null;
        this.channels = undefined;
        this.subscribed = false;
        this.token = '';
    }
    async init() {
        try {
            const token = await this.getTokenFromServer();
            this.client = new ConversationsClient(token);
            return;
        } catch (e) {
            throw e;
        }
    }
    async getChannels() {
        this.channels =
            (await this.client?.getSubscribedConversations()) as unknown as Paginator<Conversation>;
        return this.channels;
    }
    async getMessages(channel: Conversation) {
        const { items } = await channel.getMessages();
        return items;
    }
    getToken() {
        return this.token;
    }
    async getTokenFromServer() {
        try {
            const userId = getUserId();
            const token = getUserToken();
            
            const headers = token ? { user_auth_token: token } : {};
            const res = await axios.get<{ identity: string; token: string }>(
                `${Config.get('apiroot2')}token`,
                {
                    params: { user_uuid: userId },
                    headers
                }
            );
            this.token = res?.data?.token;
            return this.token;
        } catch (err) {
            sentryException(err as Error, 'get token error');
            return '';
        }
    }
    async refreshToken() {
        const token = await this.getTokenFromServer();
        await this.client?.updateToken(token);
    }
    listenMessages(callback: (channel: Conversation) => void) {
        if (!this.subscribed) {
            this.subscribed = true;
            this.client = this.client?.on('conversationUpdated', (channels) => {
                callback(channels.conversation);
            }) as ConversationsClient;
            this.client = this.client?.on('tokenExpired', () => {
                this.refreshToken();
            }) as ConversationsClient;
            this.client = this.client?.on('tokenAboutToExpire', () => {
                this.refreshToken();
            }) as ConversationsClient;
        }
    }
    getChannelByMembers(client_uuid: string, stylist_uuid: string) {
        if (!(this.channels && this.channels.items.length)) return {};
        return this.channels.items.find((channel) => {
            const {
                attributes: { stylist_data, client_data }
            } = (channel as any).state;
            return stylist_data.uuid === stylist_uuid && client_data.uuid === client_uuid;
        });
    }
    chatReply({
        channel_id,
        message_id,
        replyContent,
        token
    }: {
        channel_id: string;
        message_id: string;
        replyContent: any;
        token?: string;
    }) {
        axios.put(
            `${Config.get('apiroot2')}message/${channel_id}/${message_id}/reply`,
            replyContent,
            { headers: { token: getUserToken() ?? token } }
        );
    }
    async sendMessage({
        channel_sid,
        sender,
        type = 'text',
        content = ''
    }: {
        channel_sid: string;
        sender: string;
        type?: string;
        content?: string;
    }) {
        const channel = await this.client?.getConversationBySid(channel_sid);
        await channel?.sendMessage(
            JSON.stringify({
                content,
                type,
                sender
            }),
            { sender }
        );
        const messages = await channel?.getMessages();
        return messages?.items[messages?.items.length - 1];
    }
}
const twilioService = new TwilioService();
export default twilioService;
