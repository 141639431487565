import FilterCheckBox from './FilterCheckBox';
import FilterSearch from './FilterSearch';
import FilterSlider from './FilterSlider';

export default (props) => {
    const FILTER_TYPES = {
        checkbox: FilterCheckBox,
        slider: FilterSlider,
        search: FilterSearch
    };
    return FILTER_TYPES[props.type](props);
};
