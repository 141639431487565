import './style.scss';

import { CardElement } from '@stripe/react-stripe-js';
import React from 'react';

const CreditCardElement = ({ onChange, onFocus, onBlur, isDisabled, className = '' }) => (
    <CardElement
        options={{
            style: {
                base: {
                    fontSize: '14px',
                    fontWeight: '100',
                    fontFamily: 'inherit'
                }
            },
            hidePostalCode: true
        }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`StripeElement stripe-card-element ${isDisabled ? 'disabled' : ''} ${className}`}
    />
);

export default CreditCardElement;
