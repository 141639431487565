import './style.scss';

import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { MobileSideCollapse, Page, Payment } from 'components';
import { GoalsHeader } from './';
import { useIsMobile } from 'hooks';

const GoalsCheckout = ({ plan, mobileCollapse, clearModel }) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    useEffect(() => {
        return () => {
            clearModel();
        };
    }, []);

    const onBack = () => {
        navigate(-1);
    };

    return (
        <Page footer={false} header={false} fixedHeader={false}>
            <Container className={`goals-checkout ${mobileCollapse ? 'side-collapse-open' : ''}`}>
                <GoalsHeader onBack={onBack} />
                {plan && <Payment />}
                {isMobile && <MobileSideCollapse />}
            </Container>
        </Page>
    );
};

export default GoalsCheckout;
