import { Dispatch } from 'redux';
import { ActionTypes, ModalData, TToastData } from './types';

export const toggleModal = (dispatch: Dispatch, data?: ModalData) => {
    dispatch({
        type: ActionTypes.TOGGLE_MODAL,
        payload: data ?? {}
    });
};

export const changeModal = (dispatch: Dispatch, { type, data = {} }: { type: string; data: any }) =>
    dispatch({
        type: ActionTypes.CHANGE_MODAL,
        payload: { type, data }
    });

export const checkUnreadMessages = () => {};

export const showToast = (dispatch: Dispatch, toastData: Partial<TToastData>) => {
    dispatch({
        type: ActionTypes.SHOW_TOAST,
        payload: toastData
    });
};

export const hideToast = (dispatch: Dispatch) => {
    dispatch({
        type: ActionTypes.HIDE_TOAST
    });
};
