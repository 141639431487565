import './style.scss';

import { FC } from 'react';

import { Page } from '../../components';
import { FixedButtonContainer } from './components';
import {
    Hero,
    HowItWorks,
    News,
    Questions,
    Schedule,
    Testimonials,
    UseCases,
    Why
} from './sections';

export const Lux: FC = () => {
    return (
        <Page className="lux">
            <Hero />
            <Why />
            <UseCases />
            <HowItWorks />
            <Schedule />
            <Testimonials />
            <Questions />
            <News />
            <FixedButtonContainer />
        </Page>
    );
};
