import { isRejected } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware, UnknownAction } from '@reduxjs/toolkit'
import { logout } from 'store/user/actions';

const isWishi = (url: string = '') => url.includes('api-gw.api.wishi.me')

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action: any) => {
    if (isRejected(action)) {
      const url = action?.meta?.baseQueryMeta?.request?.url;
      if (isWishi(url)) {
        switch (action?.payload?.status) {
          case 404:
            console.warn(`Wishi URL not found: ${url}`);
            break;
          case 401:
            console.warn(`Wishi authorization failure: ${url}`);
            logout(api.dispatch)
            break;
          default:
            break;
        }  
      }
    }

    return next(action)
  }