import './style.scss';

import React, { useState } from 'react';
import { Accordion, Button, Card, Carousel, Col, Container, Image, Row } from 'react-bootstrap';

import campaigns from 'campaigns';
import texts from 'content';
import { Tracking } from 'services';
import { useNavigate } from 'react-router-dom';

const { logo } = texts;
const content = campaigns.wedding;

const Wedding = ({ user }) => {
    const navigate = useNavigate();
    const [faqOpened, setFaqOpened] = useState([]);

    const onButtonClick = ({ type = 'onboarding', id, report = '' }) => {
        Tracking.google({ type: 'event', event: 'Funnel', data: { event_category: report } });
        navigate(
                type === 'stylist'
                    ? `/stylist/${id}/profile`
                    : user
                    ? '/stylistSearch'
                    : '/onboarding',
            {state: { campaign: 'wedding' }}
        );
    };

    return (
        <Container className="wedding" fluid>
            <Row className="header">
                <Carousel controls={false} interval={5000}>
                    {content.header.slides.map(({ image, text }) => (
                        <Carousel.Item key={image}>
                            <div className="header-text">
                                <h1>{text}</h1>
                                <Button
                                    variant="light"
                                    onClick={() =>
                                        onButtonClick({
                                            type: 'onboarding',
                                            report: 'letsGetStyling1'
                                        })
                                    }>
                                    {content.button}
                                </Button>
                            </div>
                            <Image src={image} alt="" fluid />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Row>

            <Container fluid>
                <Row className="description">
                    <Image src={logo.black} alt="" />
                    <p>{content.description}</p>
                    <Button
                        variant="dark"
                        onClick={() =>
                            onButtonClick({
                                type: 'onboarding',
                                report: 'letsGetStyling2'
                            })
                        }>
                        {content.button}
                    </Button>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <div className="how">
                            <h2>{content.how.title}</h2>
                            {content.how.steps.map((step) => (
                                <div key={step.title}>
                                    <label>{step.title}</label>
                                    <p>{step.text}</p>
                                </div>
                            ))}
                        </div>

                        <div className="prices">
                            <h2>{content.prices.title}</h2>
                            {content.prices.prices.map((price) => (
                                <Container key={price.price} fluid>
                                    <Row>
                                        <Col>
                                            <label>{price.title}</label>
                                            <p dangerouslySetInnerHTML={{ __html: price.text }} />
                                        </Col>
                                        <Col md={2} className="price">
                                            <p>{`$${price.price}`}</p>
                                        </Col>
                                    </Row>
                                </Container>
                            ))}
                        </div>
                    </Col>
                    <Col md={6} className="stylists d-none d-md-block">
                        <Carousel controls={false} interval={5000}>
                            {content.stylists.list.map((stylist) => (
                                <Carousel.Item key={stylist.id}>
                                    <span>
                                        <Image
                                            className="profile-image"
                                            src={stylist.profile}
                                            alt=""
                                        />
                                        <p>
                                            {content.stylists.title.replace('%name%', stylist.name)}
                                        </p>
                                        <p className="name">{stylist.title}</p>
                                        <Image
                                            className="moodboard"
                                            src={stylist.image}
                                            alt=""
                                            fluid
                                        />
                                        <Button
                                            variant="warning"
                                            onClick={() =>
                                                onButtonClick({
                                                    type: 'stylist',
                                                    id: stylist.id,
                                                    report: 'underBoard'
                                                })
                                            }>
                                            {content.stylists.button.replace(
                                                '%name%',
                                                stylist.name
                                            )}
                                        </Button>
                                    </span>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            </Container>

            <Row className="reviews">
                <h2>{content.reviews.title}</h2>
                <div className="items">
                    {content.reviews.items.map((item) => (
                        <div key={item.id} className="review">
                            <Image src={item.image} alt="" />
                            <span>
                                <label>{item.title}</label>
                                <p>{item.text}</p>
                                <Button
                                    variant="warning"
                                    onClick={() =>
                                        onButtonClick({
                                            type: 'stylist',
                                            id: item.id,
                                            report: 'fromReviews'
                                        })
                                    }>
                                    {content.reviews.button.replace('%name%', item.name)}
                                </Button>
                            </span>
                        </div>
                    ))}
                </div>
            </Row>

            <Row className="faq">
                <Col className="text">
                    <h2>{content.faq.title}</h2>
                    {content.faq.questions.map((question, index) => (
                        <Accordion className="question" key={index}>
                            <Card className={faqOpened.includes(index) ? 'opened' : ''}>
                                <Card.Header>
                                    <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={question.title}
                                        onClick={() =>
                                            setFaqOpened(
                                                faqOpened.includes(index)
                                                    ? faqOpened.filter((i) => i !== index)
                                                    : [...faqOpened, index]
                                            )
                                        }>
                                        <span>{question.title}</span>
                                        <i className="d-none d-md-block" />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={question.title}>
                                    <Card.Body>
                                        <div dangerouslySetInnerHTML={{ __html: question.text }} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    ))}
                </Col>
                <div className="images-column-1 d-none d-md-block">
                    <Image src={content.faq.images[0]} alt="" />
                    <Image src={content.faq.images[1]} alt="" />
                </div>
                <div className="images-column-2 d-none d-md-block">
                    <Image src={content.faq.images[2]} alt="" />
                </div>
            </Row>
        </Container>
    );
};

export default Wedding;
