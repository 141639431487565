import { FC } from 'react';

import { GetStartedButton } from '../components';

type TUseCase = {
    imageUrl: string;
    title: string;
    text: string;
};

const useCases: TUseCase[] = [
    {
        imageUrl: 'https://media-cf.wishi.me/react/lux/boss_ladies.webp',
        title: 'Boss Ladies',
        text: 'You have no time to waste. With your Wishi capsule’s efficient workwear that captures and exudes your confident essence, you will project leadership and smart style without lifting a finger!'
    },
    {
        imageUrl: 'https://media-cf.wishi.me/react/lux/busy_moms.webp',
        title: 'Busy Moms',
        text: 'Your lifestyle is the determining factor in  what you wear and we are here to streamline your dressing process so you can stress less and spend time focusing on what’s most important to you!'
    },
    {
        imageUrl: 'https://media-cf.wishi.me/react/lux/life_updates.webp',
        title: 'Life Updates',
        text: 'Wishi stylists know how to pivot to the right wardrobe for the big changes in your life. Relocations, promotions, motherhood and beyond! We are with you at every stage!'
    }
];

export const UseCases: FC = () => {
    return (
        <div className="use-cases">
            <h2>What Lux is Good For</h2>
            <ul>
                {useCases.map(({ imageUrl, text, title }, index) => (
                    <li key={index}>
                        <img src={imageUrl} alt={title} />
                        <h3>{title}</h3>
                        <p>{text}</p>
                    </li>
                ))}
            </ul>
            <GetStartedButton />
        </div>
    );
};
