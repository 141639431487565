import { connect } from 'react-redux';

import { updateItem } from '../../redux/reducers/style/actions';
import {
    addToCart,
    addToCloset,
    clearCart,
    loadCart,
    removeFromCart
} from '../../redux/reducers/users/actions';
import Cart from './Cart';
import _CartItem from './CartItem';

const mapStateToProps = ({
    users: { cartLoading, cart, cartTotal, cartFilter, activeCartFilter },
    user: { user }
}) => ({
    user,
    cartLoading,
    cart,
    cartTotal,
    cartFilter,
    activeCartFilter
});

const mapDispatchToProps = (dispatch) => ({
    loadCart: (params) => dispatch(loadCart(params)),
    clearCart: () => dispatch(clearCart()),
    updateItem: (item) => dispatch(updateItem(item)),
    addToCart: (item, source) => dispatch(addToCart(item, source)),
    removeFromCart: (item) => dispatch(removeFromCart(item)),
    addToCloset: (item) => dispatch(addToCloset(item))
});

export const CartItem = connect(mapStateToProps, mapDispatchToProps)(_CartItem);
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
