import './style.scss';

import React, { useState } from 'react';
import { Accordion, Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick/lib';

import campaigns from 'campaigns.json';

const texts = campaigns.gifts;

// TODO: Split sections into sub-components

const Landing = () => {
    const [opened, setOpened] = useState([]);
    const itemsCarouselProps = {
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: '30px',
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <Container className="gifts-landing" fluid>
            <Row className="header">
                <Col>
                    <h1>{texts.landing.title}</h1>
                    <Button
                        as={Link}
                        variant="light"
                        to="/gifts/shopping"
                        className="d-none d-sm-block">
                        {texts.landing.shop}
                    </Button>
                </Col>
            </Row>

            <Row className="stylists">
                <Col>
                    <h3>{texts.landing.stylists.title}</h3>
                    <p>{texts.landing.stylists.subtitle}</p>
                </Col>
                <Container>
                    <Row className="list">
                        {texts.landing.stylists.list.map((stylist, index) => (
                            <Col xs={12} sm="auto" key={`stylists_${index}`}>
                                <Image src={stylist.image} />
                                <Link
                                    to={`/gifts/shopping${
                                        stylist.key ? `?stylist_uuid=${stylist.key}` : ''
                                    }`}>
                                    {stylist.title}
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Row>

            <Row className="karla">
                <Col
                    xs={{ span: 12, order: 2 }}
                    sm={{ span: 5, order: 1 }}
                    className="image main"
                />
                <Col xs={{ span: 12, order: 1 }} sm={{ span: 5, order: 2 }}>
                    <div>
                        <h1>{texts.landing.karla.title}</h1>
                        <h3>{texts.landing.karla.subtitle}</h3>
                        <Link
                            to={`/gifts/shopping${
                                texts.landing.karla.key
                                    ? `?stylist_uuid=${texts.landing.karla.key}`
                                    : ''
                            }`}
                            className="d-none d-sm-block">
                            {texts.landing.karla.button}
                        </Link>
                    </div>
                </Col>
                <Col sm={{ span: 2, order: 3 }} className="d-none d-sm-block">
                    <Container fluid>
                        <Row>
                            <Col className="image single" />
                        </Row>
                        <Row>
                            <Col xs={8} className="image left" />
                            <Col xs={4} className="image right" />
                        </Row>
                    </Container>
                </Col>
                <Container className="items" fluid>
                    <Slider className="items-carousel" {...itemsCarouselProps}>
                        {texts.landing.karla.items.map((item) => (
                            <Card key={item.title}>
                                <Card.Body>
                                    <Image src={item.image} fluid />
                                    <Link
                                        to={`/gifts/shopping${
                                            texts.landing.karla.key
                                                ? `?stylist_uuid=${texts.landing.karla.key}`
                                                : ''
                                        }`}>
                                        {item.title}
                                    </Link>
                                </Card.Body>
                            </Card>
                        ))}
                    </Slider>
                    <Link to="/gifts/shopping" className="d-block d-sm-none">
                        {texts.landing.karla.button}
                    </Link>
                </Container>
            </Row>

            <Row className="clea">
                <Col xs={{ span: 12, order: 1 }} sm={{ span: 5, order: 1 }}>
                    <div>
                        <h1>{texts.landing.clea.title}</h1>
                        <h3>{texts.landing.clea.subtitle}</h3>
                        <Link
                            to={`/gifts/shopping${
                                texts.landing.clea.key
                                    ? `?stylist_uuid=${texts.landing.clea.key}`
                                    : ''
                            }`}
                            className="d-none d-sm-block">
                            {texts.landing.clea.button}
                        </Link>
                    </div>
                </Col>
                <Col sm={{ order: 3 }} className="d-none d-sm-block">
                    <Container fluid>
                        <Row>
                            <Col xs={6} className="image left" />
                            <Col xs={6} className="image right" />
                        </Row>
                        <Row>
                            <Col className="image single" />
                        </Row>
                    </Container>
                </Col>
                <Col sm={{ order: 3 }} className="image main d-none d-sm-block" />
                <Container className="items" fluid>
                    <Slider className="items-carousel" {...itemsCarouselProps}>
                        {texts.landing.clea.items.map((item) => (
                            <Card key={item.title}>
                                <Card.Body>
                                    <Image src={item.image} fluid />
                                    <Link
                                        to={`/gifts/shopping${
                                            texts.landing.clea.key
                                                ? `?stylist_uuid=${texts.landing.clea.key}`
                                                : ''
                                        }`}>
                                        {item.title}
                                    </Link>
                                </Card.Body>
                            </Card>
                        ))}
                    </Slider>
                </Container>
            </Row>

            <Row className="perfect">
                <Col xs={12}>
                    <h1>{texts.landing.perfect.title}</h1>
                </Col>
                <Col xs={12}>
                    <Container fluid>
                        <Slider
                            {...itemsCarouselProps}
                            className="perfect-carousel"
                            slidesToShow={3}
                            responsive={[
                                {
                                    breakpoint: 1060,
                                    settings: {
                                        vertical: false,
                                        rows: 1,
                                        slidesToShow: 3
                                    }
                                },
                                {
                                    breakpoint: 992,
                                    settings: {
                                        vertical: true,
                                        rows: 3,
                                        slidesToShow: 1,
                                        draggable: false
                                    }
                                }
                            ]}>
                            {texts.landing.perfect.items.map((item) => (
                                <div key={item.title} className="perfect-item">
                                    <Image src={item.image} />
                                    <h6>{item.title}</h6>
                                    <p>{item.text}</p>
                                    <Link
                                        to={`/gifts/shopping${
                                            item.key ? `?interest=${item.key}` : ''
                                        }`}>
                                        {item.button}
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    </Container>
                </Col>
            </Row>

            <Row className="cards">
                <Col>
                    <div className="cards-content">
                        <div>
                            <h2>{texts.landing.cards.title}</h2>
                            <Button as={Link} variant="light" to="/gift-cards">
                                {texts.landing.cards.button}
                            </Button>
                        </div>
                        <div className="image">
                            <Image className="card1" src={texts.landing.cards.card1} />
                            <Image className="card2" src={texts.landing.cards.card2} />
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="faq">
                <Col>
                    <h3>{texts.landing.faq.title}</h3>
                </Col>
                <Col xs={12} className="faq-list">
                    {texts.landing.faq.questions.map(({ question, answer }, index) => (
                        <Accordion className="question" key={index}>
                            <Card className={opened.includes(index) ? 'opened' : ''}>
                                <Card.Header>
                                    <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={index}
                                        onClick={() =>
                                            setOpened(
                                                opened.includes(index)
                                                    ? opened.filter((i) => i !== index)
                                                    : [...opened, index]
                                            )
                                        }>
                                        {question}
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={index}>
                                    <Card.Body>{answer}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

export default Landing;
