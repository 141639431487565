import { useIsMobile, useWithDispatch } from 'hooks';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectApiToken } from 'store/auth-service/auth-slice';
import { toggleZEWidget } from 'store/common/actions';
import { initializeTwilio } from 'store/services/actions';
import { login } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';

import { AppRoutes } from './AppRoutes';
import { Modal, Toast } from './components';
import { Tracking } from './services';
import { klaviyoIdentify } from './services/Klaviyo';
import { identify as identifyMixpanel } from './services/mixpanel';
import { extractUserData } from './services/utils/user-utils';

const getTokenFromUrl = () => new URLSearchParams(window.location.search).get('token');

export const App: React.FC = () => {
    const path = useRef<string | null>(null);
    const loggingIn = useRef(false);
    const twilioInitialized = useRef(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const location = useLocation();
    const token = useSelector(selectApiToken);
    const loginToken = getTokenFromUrl() || token;
    const user = useUserStore((store) => store?.user);
    const loginAction = useWithDispatch(login);
    const initializeTwilioAction = useWithDispatch(initializeTwilio);
    const toggleZEWidgetAction = useWithDispatch(toggleZEWidget);
    const ldClient = useLDClient()!; // Since we are initializing LD w/ asyncWithLDProvider(), ldClient is always available
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!user && !loggingIn.current) {
            loggingIn.current = true;

            if (loginToken) {
                loginAction({ token: loginToken }).then(() => setIsLoaded(true));
            } else {
                setIsLoaded(true);
            }
        }
    }, [user, loginToken, loginAction, setIsLoaded]);

    useEffect(() => {
        const { pathname } = location;
        if (pathname !== path.current) {
            path.current = pathname;

            window.scrollTo(0, 0);
            Tracking.tag({
                event: 'ws.virtualPageView',
                pagePath: pathname,
                pageTitle: document.title
            });
            if (!isMobile && process.env.NODE_ENV === 'production') {
                toggleZEWidgetAction(pathname);
            }
        }
    }, [location, isMobile, toggleZEWidgetAction]);

    useEffect(() => {
        if (user) {
            const userDetails = extractUserData(user);
            userDetails && ldClient.identify(userDetails);
            identifyMixpanel(user);
            klaviyoIdentify(user.user_uuid, user.email ?? undefined);
        }
    }, [user, ldClient]);

    if (!twilioInitialized.current && user) {
        twilioInitialized.current = true;
        initializeTwilioAction();
    }

    return (
        <>
            {isLoaded && ldClient && <AppRoutes />}
            <Modal />
            <Toast />
        </>
    );
};
