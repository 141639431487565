/* eslint-disable no-useless-escape */
import axios from 'axios';
import moment from 'moment';
import { capitalize } from './utils/string-utils';

const Formatter = {
    price: (price, fractionDigits = 0, currency = 'USD') => {
        let options = {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        };
        if (currency) {
            options.style = 'currency';
            options.currency = currency;
        }

        const formatter = new Intl.NumberFormat('en-US', options);
        if (typeof price === 'string') price = price.replace(',', '');
        if (!price) price = 0;

        const formatted = parseInt(price, 10);

        return formatter.format(formatted);
    },
    name: (name) =>
        name
            .split(' ')
            .map((word) => capitalize(word))
            .join(' '),
    queryString: (params) =>
        params
            .substring(1)
            .split('&')
            .reduce((ac, a) => ({ ...ac, [a.split('=')[0]]: a.split('=')[1] }), {}),
    time: (timestamp, format = 'MMMM DD, YYYY') => {
        let date = moment(new Date(timestamp));
        if (!date.isValid()) date = moment.unix(timestamp);
        let formatted = date.fromNow();

        if (moment().diff(date, 'days') > 7) {
            formatted = date.format(format);
        }
        return formatted;
    },
    base64: (url) => axios.post('https://app.api.wishi.me/image/base64', { img: url }),
    number: (number) => new Intl.NumberFormat().format(number)
};

export default Formatter;
