import { connect } from 'react-redux';

import { contact } from 'redux/reducers/stylists/actions';
import Contact from './Contact';

const mapStateToProps = ({ stylists: { lastMessage } }) => ({
    lastMessage
});

const mapDispatchToProps = (dispatch) => ({
    contact: (stylist_id, message) => dispatch(contact(stylist_id, message))
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
