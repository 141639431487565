import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Collage, ImagesCarousel, StylingButton } from 'components';

const Header = ({ data }) => (
    <Row className="landing-header">
        <Col xs={12} className="d-none d-sm-block no-padding">
            <Container fluid>
                <Row>
                    <Col xs={7}>
                        <img className="wishi-logo" src={data.logo.black} alt="wishi" />
                        <h2>{data.title}</h2>
                        <h4>{data.text}</h4>
                        <StylingButton text={data.button.text} />
                        <div className="more d-none d-sm-block">{data.more}</div>
                    </Col>
                    <Col xs={5}>
                        <Collage images={data.web} />
                    </Col>
                </Row>
            </Container>
        </Col>
        <Col xs={12} className="d-block d-sm-none no-padding">
            <Container className="mobile-collage-overlay">
                <Row>
                    <Col>
                        <img className="wishi-logo" src={data.logo.white} alt="wishi" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>{data.title}</p>
                    </Col>
                </Row>
            </Container>

            <ImagesCarousel items={data.mobile} />
            <div className="mobile-sticky">
                <StylingButton />
            </div>
        </Col>
    </Row>
);

export default Header;
