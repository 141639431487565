import './style.scss';

import React from 'react';
import { Form } from 'react-bootstrap';

const RadioButton = ({
    id,
    value,
    isChecked,
    onClick,
    label,
    direction = 'horizontal',
    type = 'light',
    fill = true
}) => (
    <Form.Check
        className={`radio-button ${direction} ${type} ${fill ? 'fill' : 'dot'} ${
            isChecked ? 'checked' : ''
        }`}
        type="radio"
        name={id}
        id={value}
        label={label}
        checked={isChecked}
        onClick={onClick}
        onChange={() => {}} // required by FormCheckInput component if using checked
    />
);

export default RadioButton;
