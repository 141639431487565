import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import FavoriteButton from './FavoriteButton';

const mapStateToProps = ({ user: { user } }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteButton);
