import './style.scss';

import React from 'react';
import { Link, useParams } from 'react-router-dom';

import content from '../../../content.json';

const texts = content.settings;

export default () => {
    const { tab = 'main' } = useParams();

    const getSectionTitle = () =>
        tab !== 'main' ? texts.sections.find((item) => item.key === tab).title : '';

    return (
        <div className="breadcrumbs">
            <Link to="/settings">Settings</Link>
            <div className="arrow" />
            <div>{getSectionTitle(tab)}</div>
        </div>
    );
};
