import { IActionWithPayload } from '../types';

export enum ActionTypes {
    SET_TWILIO_INITIALIZED = 'services/SET_TWILIO_INITIALIZED'
}
export interface ServicesState {
    isTwilioInitialized: boolean;
}

export type Actions = IActionWithPayload<ActionTypes.SET_TWILIO_INITIALIZED, boolean>;
