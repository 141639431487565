import { connect } from 'react-redux';

import { toggleBirthdaMessage } from 'redux/reducers/users/actions';
import BirthdayMessage from './BirthdayMessage';

const mapStateToProps = ({ users: { birthdayMessage }, user: { user } }) => ({
    user,
    birthdayMessage
});

const mapDispatchToProps = (dispatch) => ({
    toggleBirthdaMessage: () => dispatch(toggleBirthdaMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(BirthdayMessage);
