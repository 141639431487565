import {
    ADD_FAVORITE_LOOK_SUCCESS,
    ADD_FAVORITE_STYLIST_SUCCESS,
    ADD_TO_CART,
    ADD_TO_CLOSET,
    CHANGE_MODAL,
    CHOSEN_ITEM,
    CLEAR_CART,
    CLEAR_CART_FILTER,
    CLEAR_FAVORITE_LOOKS,
    CLEAR_PROFILE_SELECTED_FILTERS,
    CLEAR_USER_CLOSET,
    CLEAR_USER_PHOTOS,
    LOAD_CART_START,
    LOAD_CART_SUCCESS,
    LOAD_FAVORITE_LOOKS_START,
    LOAD_FAVORITE_LOOKS_SUCCESS,
    LOAD_FAVORITE_STYLISTS_START,
    LOAD_FAVORITE_STYLISTS_SUCCESS,
    LOGIN_START,
    PAGE_LOADED,
    REMOVE_FAVORITE_LOOK_SUCCESS,
    REMOVE_FAVORITE_STYLIST_SUCCESS,
    REMOVE_FROM_CART,
    RESET_PASSWORD_SUCCESS,
    SET_CART_FILTER,
    SET_CART_FILTERS,
    SET_CURRENT_ITEM,
    SET_CURRENT_OUTFIT,
    SIGNUP_START,
    START_FILTERS_LOADING,
    START_LOADING,
    STOP_LOADING,
    TOGGLE_BIRTHDAY_MESSAGE,
    TOGGLE_MODAL,
    UPDATE_BILLING_DETAILS,
    UPDATE_CLOSET_TAGS,
    UPDATE_ORDERS,
    UPDATE_PROFILE_FILTERS,
    UPDATE_PROFILE_SELECTED_FILTERS,
    UPDATE_USER_CLOSET,
    UPDATE_USER_LOOKS,
    UPDATE_USER_ORDERS,
    UPDATE_USER_PHOTOS
} from './actions';

const initialState = {
    showModal: false,
    modalType: null,
    loading: false,
    loadingFilters: false,
    resetSuccess: false,
    messagesCount: 0,
    user: null,
    error: null,
    favoriteLooks: [],
    favoriteStylists: [],
    redirectUrl: null,
    cartLoading: false,
    cart: [],
    cartTotal: 0,
    cartPrice: 0,
    cartFilter: {},
    cartFilters: [],
    activeCartFilter: '',
    messages: [],
    channel: null,
    birthdayMessage: false,
    billingDetails: {},
    filters: [],
    filtersType: null,
    selectedFilters: {},
    closet: null,
    photos: [],
    looks: null,
    orders: [],
    ordersTotal: 0,
    totalItems: 0,
    closetTags: [],
    chosenItem: null,
    currentOutfit: null,
    currentItem: null,
    nextPage: 1
};

export default function (state = initialState, action) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case SIGNUP_START:
        case LOGIN_START: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                loading: false,
                resetSuccess: true
            };
        }
        case TOGGLE_MODAL: {
            const { type = '', url = null, data = null } = action.payload;
            return {
                ...state,
                showModal: !state.showModal,
                loading: false,
                error: null,
                redirectUrl: url || state.redirectUrl,
                modalType: type ? type : state.modalType,
                modalData: data
            };
        }
        case CHANGE_MODAL: {
            const { type = '', data = {} } = action.payload;
            return {
                ...state,
                error: null,
                loading: false,
                modalType: type,
                modalData: data
            };
        }
        // case UPDATE_MESSAGES_COUNT: {
        //     const { messagesCount } = action.payload;
        //     return {
        //         ...state,
        //         messagesCount
        //     };
        // }
        case LOAD_FAVORITE_LOOKS_START: {
            return {
                ...state,
                favoriteLooks: [],
                loading: true
            };
        }
        case LOAD_FAVORITE_LOOKS_SUCCESS: {
            const { favorites } = action.payload;
            return {
                ...state,
                favoriteLooks: favorites,
                loading: false
            };
        }
        case CLEAR_FAVORITE_LOOKS: {
            return {
                ...state,
                favoriteLooks: []
            };
        }
        case ADD_FAVORITE_LOOK_SUCCESS: {
            const { outfit } = action.payload;
            return {
                ...state,
                favoriteLooks: [...state.favoriteLooks, outfit]
            };
        }
        case REMOVE_FAVORITE_LOOK_SUCCESS: {
            const { outfit } = action.payload;
            return {
                ...state,
                favoriteLooks: [
                    ...state.favoriteLooks.filter((favorite) => favorite.uuid !== outfit.uuid)
                ]
            };
        }

        case LOAD_FAVORITE_STYLISTS_START: {
            return {
                ...state,
                favoriteStylists: [],
                loading: true
            };
        }
        case LOAD_FAVORITE_STYLISTS_SUCCESS: {
            const { stylists } = action.payload;
            return {
                ...state,
                favoriteStylists: stylists,
                loading: false
            };
        }
        case ADD_FAVORITE_STYLIST_SUCCESS: {
            const { stylist } = action.payload;
            return {
                ...state,
                favoriteStylists: [...state.favoriteStylists, stylist]
            };
        }
        case REMOVE_FAVORITE_STYLIST_SUCCESS: {
            const { stylist } = action.payload;
            return {
                ...state,
                favoriteStylists: [
                    ...state.favoriteStylists.filter((favorite) => favorite.uuid !== stylist.uuid)
                ]
            };
        }
        case CLEAR_CART: {
            return {
                ...state,
                cart: []
            };
        }
        case LOAD_CART_START: {
            return {
                ...state,
                cart: [],
                cartLoading: true
            };
        }
        case LOAD_CART_SUCCESS: {
            const { cart, quota_max, total_price } = action.payload;
            return {
                ...state,
                cart,
                cartTotal: quota_max,
                cartPrice: total_price,
                cartLoading: false
            };
        }
        case ADD_TO_CART: {
            const { item } = action.payload;
            const currentCart = state.cart ? state.cart : [];
            const cart = [...currentCart, { ...item, is_in_cart: true }];
            const cartTotalPrice = cart.reduce(
                (sum, cartItem) => sum + parseFloat(cartItem.price),
                0
            );
            return {
                ...state,
                cart,
                cartTotal: cart.length,
                cartPrice: cartTotalPrice
            };
        }
        case ADD_TO_CLOSET: {
            const { item } = action.payload;
            const cart = [...state.cart].map((ci) =>
                ci.item_uuid === item.item_uuid ? { ...ci, is_in_closet: true } : ci
            );
            return {
                ...state,
                cart,
                cartTotal: cart.length
            };
        }
        case REMOVE_FROM_CART: {
            const { item } = action.payload;
            const itemUUID = item.unique || item.uuid;
            const cart = state.cart.filter(
                (i) => i.uuid !== itemUUID && i.originalItemUnique !== itemUUID
            );
            const cartTotalPrice = cart.reduce(
                (sum, cartItem) => sum + parseFloat(cartItem.price),
                0
            );
            return {
                ...state,
                cart,
                cartTotal: cart.length,
                cartPrice: cartTotalPrice
            };
        }
        case SET_CART_FILTER: {
            const {
                filter: { key, value }
            } = action.payload;
            return {
                ...state,
                cart: null,
                activeCartFilter: key,
                cartFilter: value
            };
        }
        case SET_CART_FILTERS: {
            const { filters } = action.payload;
            return {
                ...state,
                cartFilters: filters
            };
        }
        case CLEAR_CART_FILTER: {
            return {
                ...state,
                activeCartFilter: '',
                cartFilter: {}
            };
        }
        case CHOSEN_ITEM: {
            return {
                ...state,
                chosenItem: { item: action.payload }
            };
        }
        case TOGGLE_BIRTHDAY_MESSAGE: {
            return {
                ...state,
                birthdayMessage: !state.birthdayMessage
            };
        }
        case UPDATE_BILLING_DETAILS: {
            const { billingDetails } = action.payload;
            return {
                ...state,
                billingDetails
            };
        }
        case UPDATE_ORDERS: {
            const { orders, total, nextPage } = action.payload;
            return {
                ...state,
                orders,
                ordersTotal: total,
                nextPage: nextPage,
                loading: false
            };
        }
        case CLEAR_USER_CLOSET: {
            return {
                ...state,
                closet: null,
                totalItems: 0
            };
        }
        case UPDATE_USER_CLOSET: {
            const { items, total } = action.payload;
            return {
                ...state,
                closet: items,
                totalItems: total,
                loading: false
            };
        }
        case UPDATE_PROFILE_FILTERS: {
            const { filters, type } = action.payload;
            return {
                ...state,
                filters,
                filtersType: type,
                loadingFilters: false
            };
        }

        case UPDATE_PROFILE_SELECTED_FILTERS: {
            const { selected } = action.payload;
            return {
                ...state,
                selectedFilters: selected
            };
        }

        case CLEAR_PROFILE_SELECTED_FILTERS: {
            return {
                ...state,
                selectedFilters: {}
            };
        }

        case UPDATE_USER_PHOTOS: {
            const { photos, total } = action.payload;
            return {
                ...state,
                photos,
                totalItems: total,
                loading: false
            };
        }

        case CLEAR_USER_PHOTOS: {
            return {
                ...state,
                photos: [],
                totalItems: 0
            };
        }

        case UPDATE_USER_LOOKS: {
            const { items, total } = action.payload;
            return {
                ...state,
                looks: items,
                looksTotalItems: total,
                loading: false
            };
        }

        case UPDATE_USER_ORDERS: {
            const { items, total } = action.payload;
            return {
                ...state,
                orders: items,
                totalItems: total,
                loading: false
            };
        }

        case UPDATE_CLOSET_TAGS: {
            const { tags } = action.payload;
            return {
                ...state,
                closetTags: tags,
                totalItems: tags.length,
                loading: false
            };
        }
        case SET_CURRENT_OUTFIT: {
            const { outfit } = action.payload;
            return {
                ...state,
                currentOutfit: outfit
            };
        }
        case SET_CURRENT_ITEM: {
            const { item } = action.payload;
            return {
                ...state,
                currentItem: item
            };
        }

        case START_FILTERS_LOADING: {
            return {
                ...state,
                loadingFilters: true
            };
        }

        case STOP_LOADING: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false
            };
        }
        case PAGE_LOADED: {
            return {
                ...state,
                loading: false
            };
        }

        default:
            return state;
    }
}
