import './style.scss';

import React from 'react';

import { ProfilePhoto } from '../../../components';
import content from '../../../content.json';
import { useUserName } from '../../../hooks';
import Breadcrumbs from './Breadcrumbs';

const texts = content.settings;

export default ({ user, photo = false, section = 'style' }) => {
    const { fullname } = useUserName(user);
    return (
        <div className="header">
            {user && photo && (
                <div>
                    <ProfilePhoto user={user} level={true} />
                    <div className="name">{fullname}</div>
                </div>
            )}
            <Breadcrumbs />
            <label>{texts[section].title}</label>
        </div>
    );
};
