import { connect } from 'react-redux';

import { getCountries, getStates } from '../../redux/reducers/payments/actions';
import { getBillingDetails } from '../../redux/reducers/users/actions';
import BillingForm from './BillingForm';

const mapStateToProps = ({
    users: { billingDetails },
    payments: { countries, states, cards },
    user: { user }
}) => ({
    user,
    billingDetails,
    countries,
    states,
    cards
});

const mapDispatchToProps = (dispatch) => ({
    getBillingDetails: () => dispatch(getBillingDetails()),
    getCountries: () => dispatch(getCountries()),
    getStates: (country) => dispatch(getStates(country))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingForm);
