import axios from 'axios';
import moment from 'moment';
import TagManager from 'react-gtm-module';

import { Config, Cookies } from './';

const Tracking = {
    facebook: (type, event, data) => Tracking.send('facebook', { type, event, data }),
    google: (event) => Tracking.send('google', event),
    tag: (event) => Tracking.send('tag', { event }),
    report: (data, token) => Tracking.send('report', { data, token }),
    track: (data, token) => Tracking.send('track', { data, token }),
    send: (platform, { type, event, data, token }) => {
        if (process.env.REACT_APP_ENV === 'production') {
            switch (platform) {
                case 'facebook':
                    window.fbq && window.fbq(type, event, data);
                    break;
                case 'google':
                    window.gtag && window.gtag(type, event, data);
                    break;
                case 'tag':
                    TagManager.dataLayer({ dataLayer: event });
                    break;
                case 'report':
                    return axios.post(`${Config.get('apiroot2')}events`, data, {
                        headers: { token }
                    });
                case 'track':
                    const date = Cookies.get('tracking');
                    if (isNaN(date) || moment().diff(moment(date), 'days') >= 1) {
                        Cookies.set('tracking', moment().valueOf());
                        return axios.post(
                            `${Config.get('apiroot2')}user/${data.id}/trackSession`,
                            { client: data.device },
                            { headers: { token } }
                        );
                    }
                    break;
                default:
                    console.log(type, event, data);
            }
        }
    }
};

export default Tracking;
