import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getUserId, getUserToken } from 'services/utils/user-utils';
import { RootState } from 'store';
import { getSDKAccessToken } from 'services/sdk';


interface AuthState {
	apiToken?: string;
	sdkToken?: string;
	userId?: string;
}

const initialState: AuthState = {
	apiToken: getUserToken(),
    sdkToken: getSDKAccessToken(),
	userId: getUserId()
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signOut(state) {
			state.apiToken = undefined;
			state.sdkToken = undefined;
			state.userId = undefined;
		},
		signIn(state, action: PayloadAction<{apiToken: string, sdkToken: string, userId: string}>) {
			state.apiToken = action.payload.apiToken;
			state.sdkToken = action.payload.sdkToken;
			state.userId = action.payload.userId;
		}
	},
    // getting ready to transfer login to RTK api
	// extraReducers: (builder) => {
	// 	builder.addMatcher(extendedApi.endpoints.login.matchFulfilled, (state, { payload }) => {
	// 		state.apiToken = payload.apiToken;
	// 		state.sdkToken = payload.sdkToken;
	// 	});
	// }
});

export const selectApiToken = (state: RootState) => state.authSlice.apiToken;
export const selectSdkToken = (state: RootState) => state.authSlice.sdkToken;
export const selectUserId = (state: RootState) => state.authSlice.userId;

export const { signOut, signIn } = authSlice.actions;
export default authSlice.reducer;
