import _environments from '../config';

const Config = {
    _environments,
    _environment: null,

    getEnvironment: () => {
        const host = window.location.host;
        if (Config._environment) {
            return Config._environment;
        }

        for (let env in _environments) {
            if (
                typeof _environments[env].host !== 'undefined' &&
                _environments[env].host === host
            ) {
                Config._environment = env;
                return Config._environment;
            } else if (
                typeof _environments[env].hosts !== 'undefined' &&
                _environments[env].hosts.indexOf(host) > -1
            ) {
                _environments[env].host = host;
                Config._environment = env;
                return Config._environment;
            }
        }
        if (!Config._environment) {
            Config._environment = 'default';
        }

        console.log(`Running on ${Config._environment} environment`);

        return null;
    },
    get: (property) => {
        const env = Config.getEnvironment();
        return env ? Config._environments[env].config[property] : '';
    },
    isDev: () => {
        const env = Config.getEnvironment();
        return !(env === 'prod' || env === 'prodWeb');
    },
    isMobile: () =>
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
            navigator.userAgent.toLowerCase()
        ) || window.screen.width <= 768,
    getDevice: () => window.navigator.platform.toLowerCase(),
    setMetaTags: (tags = []) => {
        let metatags = document.getElementsByTagName('meta');
        Array.from(metatags).map((tag) => {
            if (tags[tag.attributes[0].nodeValue]) {
                tag.content = tags[tag.attributes[0].nodeValue];
            }
            return tag;
        });
    },
    removeMetaTags: (tags) => {
        tags.map((tag) => {
            tag.remove();
        });
    },
    resetMetaTags: () => {
        let metatags = document.getElementsByTagName('meta');
        Array.from(metatags).map((tag) => {
            if (tag.property === 'og:site_name') {
                tag.content = 'wishi.me';
            } else if (tag.property === 'twitter:description') {
                tag.content = 'Premium Destination for Online Styling';
            } else {
                tag.remove();
            }
        });
    }
};

export default Config;
