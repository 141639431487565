import { Dispatch } from 'redux';
import { ActionTypes } from '../actions';

interface IError {
    error?: any;
    message?: string;
    showMessage?: boolean;
    errors?: Record<string, string>[];
}

export function setupError(
    dispatch: Dispatch,
    { error = {}, message = '', showMessage = false, errors = [] }: IError
) {
    dispatch({ type: ActionTypes.SETUP_ERROR, payload: { error, message, showMessage, errors } });
    //   reportErrorToServer(error, message);
}

export function resetError(dispatch: Dispatch) {
    dispatch({ type: ActionTypes.RESET_ERROR });
}
