import './style.scss';

import React from 'react';
import { Image } from 'react-bootstrap';
import Slider from 'react-slick';

interface ILightbox {
    variant?: string;
    photos: string[];
    opacity?: number;
    closePosition?: string;
    onClose: () => void;
    selected?: number;
}
const Lightbox: React.FC<ILightbox> = ({
    variant = 'dark',
    photos = [],
    opacity = 1,
    closePosition = 'left',
    onClose
}) => (
    <div className={`lightbox ${variant} ${opacity === 1 ? 'opaque' : ''}`}>
        <div className={`close ${closePosition}`} onClick={onClose} />
        <Slider
            className="lightbox-carousel"
            dots={false}
            infinite={true}
            centerMode={false}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={false}
            arrows={true}
        >
            {photos.map((photo, index) => (
                <Image key={index} src={photo} />
            ))}
        </Slider>
    </div>
);

export default Lightbox;
