import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { StylingButton } from '../../../components';

const Karla = ({ data }) => (
    <Row className="karla">
        <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
            <ImgWithFallback
                src={data.img.src}
                fallbackSrc={data.img.fallback}
                className="image"
                alt="Karla"
            />
        </Col>
        <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
            <div className="text">
                <p>{data.text}</p>
                <StylingButton />
            </div>
        </Col>
    </Row>
);

export default Karla;
