import { connect } from 'react-redux';
import { bookStylist } from 'store/booking/actions';
import { loadUserMatches } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';

import { updateFavoriteStylist } from 'redux/reducers/users/actions';
import BookBar from './BookBar';
import Experience from './Experience';
import Expertise from './Expertise';
import Matches from './Matches';
import Recommendation from './Recommendation';
import Reviews from './Reviews';
import StylingExperience from './StylingExperience';
import StylistHeader from './StylistHeader';
import Work from './Work';

const mapStateToProps = ({
    users: { favoriteStylists },
    style: { matches },
    common: { stylistsMatchs, mixpanelStore }
}) => ({
    matches,
    favoriteStylists,
    stylistsMatchs,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    loadUserMatches: (user) => loadUserMatches(dispatch, user),
    bookStylist: (stylist) => bookStylist(dispatch, stylist),
    updateFavoriteStylist: (stylist) => dispatch(updateFavoriteStylist(stylist))
});

export default {
    Recommendation,
    Reviews,
    Experience,
    Expertise,
    StylistHeader,
    BookBar: connect(mapStateToProps, mapDispatchToProps)(BookBar),
    Work,
    StylingExperience,
    Matches: connect(mapStateToProps, mapDispatchToProps)(Matches)
};
