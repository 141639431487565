import React from 'react';

interface IHeaderData {
    title: string;
}
const PageHeader = (props: { data: IHeaderData }) => {
    const data = props.data;
    return (
        <div className={'how-it-works section header'}>
            <div className="how-it-works pageHeader">{data.title}</div>
        </div>
    );
};

export default PageHeader;
