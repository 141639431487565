import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Loader, Page } from 'components';
import content from 'content.json';
import { Validator } from 'services';
import Sections from './Sections';
import { isUserLoggedIn } from 'services/utils/user-utils';

const { giftcards } = content;

const Checkout = ({
    giftcard,
    giftcardState,
    updateGiftCardState,
    giftCardError,
    loading,
    result,
    refreshUser,
    user
}) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    useEffect(() => {
        updateGiftCardState(isMobile && giftcardState > 1 ? 1 : 0);
        if (isUserLoggedIn(user)) refreshUser();
    }, []);

    useEffect(() => {
        if (result) navigate('/gift-cards/confirmation');
    }, [result]);

    const onSubmit = () => {
        if (!isMobile || (isMobile && giftcardState === 1)) {
            const { amount, recipientName, recipientEmail, senderName, senderEmail } = giftcard;
            const errors = {
                recipientName: Validator.name(recipientName, false),
                senderName: Validator.name(senderName, false),
                recipientEmail: Validator.email(recipientEmail),
                senderEmail: Validator.email(senderEmail),
                amount: Validator.number(amount)
            };
            const filtered = Object.keys(errors)
                .filter((key) => !!errors[key])
                .reduce((obj, key) => {
                    obj[key] = errors[key];
                    return obj;
                }, {});

            if (!!amount && !Object.keys(filtered).length) {
                updateGiftCardState(isMobile ? giftcardState + 1 : 1);
            } else {
                giftCardError(filtered);
            }
        } else {
            updateGiftCardState(isMobile ? giftcardState + 1 : 1);
        }
    };

    return (
        <Page footer={false} header={false}>
            <Container className="gift-cards-checkout">
                {loading && <Loader />}
                {giftcardState > 0 && (
                    <div
                        className="back-btn"
                        id="back"
                        onClick={() =>
                            updateGiftCardState(giftcardState > 0 ? giftcardState - 1 : 0)
                        }
                    />
                )}
                {isMobile ? (
                    <>
                        <Row className="d-flex">
                            <Col>
                                {giftcardState === 0 && <Sections.Designs />}
                                {giftcardState === 1 && <Sections.Details />}
                                {giftcardState === 2 && <Sections.CheckoutForm />}
                            </Col>
                        </Row>
                        {giftcardState < 2 && (
                            <div className="continue d-flex">
                                <Button variant="dark" onClick={onSubmit}>
                                    {giftcards.checkout.continue}
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <Row className="d-sm-flex">
                        <Col>{giftcardState ? <Sections.Overview /> : <Sections.Designs />}</Col>
                        <Col>
                            {giftcardState ? (
                                <Sections.CheckoutForm />
                            ) : (
                                <Sections.Details onSubmit={onSubmit} />
                            )}
                        </Col>
                    </Row>
                )}
            </Container>
        </Page>
    );
};

export default Checkout;
