import './style.scss';

import _campaigns from 'campaigns.json';
import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { setupError } from 'store/error/actions';
import { useUserStore } from 'store/user/reducer';

import { Footer, NavBar } from '..';
import { getUserId } from 'services/utils/user-utils';
type CampaignsKeys = keyof typeof _campaigns;
interface IPage {
    header?: boolean;
    footer?: boolean;
    fixedHeader?: string | boolean;
    className?: string;
    type?: string;
    children?: React.ReactNode;
}
const Page: React.FC<IPage> = ({
    header = true,
    fixedHeader = 'top',
    footer = true,
    children,
    className = ''
}) => {
    const [canLoad, setCanLoad] = useState(false);
    const [userChecked, setUserChecked] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const userData = useUserStore((store) => store.user);

    const setupErrorAction = useWithDispatch(setupError);

    const campaigns = Object.keys(_campaigns)
        // @ts-ignore
        .filter((key) => _campaigns[key].type === 'page' && _campaigns[key].active)
        // @ts-ignore
        .map((key: CampaignsKeys) => _campaigns[key]);

    useEffect(() => {
        if (userData !== null) {
            setFirstLoad(false);
        }
        const canLoadPage = !canLoad
            ? userData &&
              !!userChecked &&
              (window.location.pathname !== '/' || (window.location.pathname === '/' && !firstLoad))
            : true;
        setCanLoad(canLoadPage ?? false);
    }, [userData, userChecked]);

    useEffect(() => {
        setupErrorAction({});
        const userId = getUserId();
        if (!userId || window.location.pathname.includes('chat')) {
            setCanLoad(true);
        }
        setUserChecked(userId);
    }, []);

    return (
        <div>
            {canLoad ? (
                <div
                    className={`page ${header ? 'with-header' : ''} ${
                        header && fixedHeader ? 'fixed-header' : ''
                    } ${footer ? 'width-footer' : 'no-footer'} ${className}`}>
                    {header && <NavBar fixed={fixedHeader} campaigns={campaigns} />}
                    {children}
                    {footer && <Footer campaigns={campaigns} />}
                </div>
            ) : null}
        </div>
    );
};

export default Page;
