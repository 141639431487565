import React, { useEffect, useState } from 'react';
import { useIsMobile } from 'hooks';
import ReviewsCarousel from './ReviewsCarousel';
import { Stylist } from 'services';
import { Container } from 'react-bootstrap';
import { reviewFormatter } from '../utils/reviewFormatter';

type devices = 'desktop' | 'mobile';

interface IReviews {
    header: Record<devices, string>;
}
const Reviews = (props: { data: IReviews }) => {
    const [latestReviews, setLatestReviews] = useState<any>();
    const isMobile = useIsMobile();
    const device = isMobile ? 'mobile' : ('desktop' as devices);
    const reviewsLimit = 20;
    useEffect(() => {
        const fetchData = async () => {
            const { data } = await Stylist.getLatestReviews(reviewsLimit);
            const formattedReviews = reviewFormatter(data.reviews);
            setLatestReviews(formattedReviews);
        };
        fetchData();
    }, []);

    return (
        <div className="how-it-works reviews-section fluid">
            <Container className={'how-it-works no-overflow'}>
                <span className="reviews-header">{props.data.header[device]}</span>
            </Container>
            {latestReviews && <ReviewsCarousel items={latestReviews} />}
        </div>
    );
};

export default Reviews;
