import { EndpointDefinitions } from '@reduxjs/toolkit/query';

import { rootSplitApi } from '../root-api-slice';

type TSessionId = string;
type TOrderId = string;

type TGetItemCheckoutSessionProps = {
    items: {
        uuid: string;
        size: string;
    }[];
    returnUrl: string;
};

type TOrderDetails = {
    confirmationNumber: string;
    shippingAddress: {
        country: string;
        state: string;
        city: string;
        line1: string;
        line2: string;
        postalCode: string;
        name: string;
    };
    items: {
        name: string;
        pictureUrl: string;
        price: string;
        uuid: string;
    }[];
    completed: boolean;
    priceBreakdown: {
        total: string;
        subtotal: string;
        tax: string;
        shipping: string;
        taxRate: string;
    };
};

const paymentsApi = rootSplitApi
    .enhanceEndpoints({
        addTagTypes: ['ItemsCheckoutSession', 'Order']
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getItemsCheckoutSession: build.query<
                { secret: string; id: TSessionId },
                TGetItemCheckoutSessionProps
            >({
                query: ({ items, returnUrl }) => ({
                    url: `/session-checkout/`,
                    method: 'POST',
                    body: {
                        items,
                        returnUrl: `${location.origin}${returnUrl}`
                    }
                }),
                providesTags: ['ItemsCheckoutSession']
            }),
            getOrderDetails: build.query<TOrderDetails, { orderId: TOrderId }>({
                query: ({ orderId }) => ({
                    url: `/checkout`,
                    method: 'get',
                    params: {
                        orderId
                    }
                }),
                providesTags: (result, error, props) => [{ type: 'Order' }]
            }),
            completeOrder: build.mutation<any, { orderId: TOrderId }>({
                query: ({ orderId }) => ({
                    url: `/complete/`,
                    method: 'POST',
                    body: {
                        orderId
                    }
                }),
                invalidatesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }]
            })
        })
    });

export const {
    useGetItemsCheckoutSessionQuery,
    useGetOrderDetailsQuery,
    useCompleteOrderMutation
} = paymentsApi;
