import React, { useEffect, useState } from 'react';

import { GeneralUser } from '../types/user';

export const useUserName = (user: GeneralUser | null) => {
    const [fname, setFname] = useState(user?.first_name ?? '');
    const [lname, setLname] = useState(user?.last_name ?? '');

    useEffect(() => {
        if (user?.name) {
            setFname(user?.name.split(' ')[0] ?? '');
            setLname(
                user.name.split(' ').length > 1 && user.name.split(' ')[1]
                    ? user.name.split(' ')[1]
                    : ''
            );
        }
        if (user?.first_name) setFname(user.first_name);
        if (user?.last_name) setLname(user.last_name);
    }, [user]);
    return {
        fname,
        lname,
        fullname: `${fname} ${lname}`,
        initials: `${fname[0]} ${lname[0]}`
    };
};
