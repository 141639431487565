export const enum KlaviyoEvent {
    GIFTCARDS_PAGE_VIEW = 'giftcards_page_view',
    GIFTCARD_BUY_CLICK = 'giftcards_buy_click',
    GOAL_VIEW = 'goal_view',
    GOAL_CLICK = 'goal_click',
    PLAN_VIEW = 'plan_view',
    PLAN_CLICK = 'plan_click'
}

export const enum KlaviyoEventProps {
    GIFT_TYPE = 'gift_type'
}

const canSend = () => window?.klaviyo && window.location.protocol === 'https:';

const track = (event: string, data = {}) => {
    if (canSend()) {
        const props = { ...data, platform: 'web' };
        window.klaviyo.track(event, props);
    }
};

export const klaviyoIdentify = (uuid: string, email?: string) => {
    if (email && canSend()) {
        window.klaviyo
            .identify({ external_id: uuid, email })
            .catch((error) => console.log('klaviyo error', error));
    }
};

export const klaviyoTrack = (event: KlaviyoEvent, data = {}) => {
    const props = { ...data, platform: 'web' };
    track(event, props);
};
