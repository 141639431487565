import React, { useState, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import debounce from 'lodash/debounce';

export const useWithDispatch = <T extends any[], U>(
    fn: (dispatch: Dispatch<any>, ...args: T) => U
) => {
    const dispatch = useDispatch();
    return (...args: T): U => fn(dispatch, ...args);
};

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const debouncedUpdateSize = useRef(debounce(() => {
        setIsMobile(window.innerWidth < 768);
    }, 250)).current;

    useLayoutEffect(() => {
        const updateSize = (): void => {
            debouncedUpdateSize();
        };
        window.addEventListener('resize', updateSize);
        return (): void => {
            window.removeEventListener('resize', updateSize);
            debouncedUpdateSize.cancel();
        };
    }, [debouncedUpdateSize]);

    return isMobile;
};


export * from './users';
