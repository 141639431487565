import '../style.scss';

//import { Elements } from '@stripe/react-stripe-js';
import { useIsMobile } from 'hooks';
//import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Goal, GoalPlan, PriceType } from 'types/plans';
import { Coupon, PaymentError } from 'types/stripForm';
import { StylistUser } from 'types/stylist';

import content from '../../../content.json';
//import { Payments } from '../../../services';
import { RadioButton } from '../..';

const { payment } = content;
//const stripePromise = loadStripe(Payments.getStripeKey() as string);

interface ISummary {
    stylist: StylistUser;
    plan: GoalPlan;
    goal: Goal;
    model: string;
    coupon: Coupon;
    onSelect: (name: string | null) => void;
    submitCoupon: (code: string) => void;
    clearCoupon: () => void;
    toggleMobileCollapse: (toggle: string) => void;
    campaign: any;
    error: PaymentError | null;
}

const Summary: React.FC<ISummary> = ({
    stylist,
    plan,
    goal,
    model,
    coupon,
    onSelect,
    submitCoupon,
    clearCoupon,
    toggleMobileCollapse,
    campaign,
    error
}) => {
    const [showCode, setShowCode] = useState(false);
    const [couponError, setCouponError] = useState<string | null>(null);
    const [code, setCode] = useState('');
    const isMobile = useIsMobile();

    useEffect(() => {
        if (
            goal.value !== 'closet_cleanout' &&
            plan.value !== 'lux' &&
            stylist &&
            !stylist.is_celebrity &&
            !campaign
        ) {
            submitCoupon('monthly-session');
        } else {
            clearCoupon();
        }
    }, []);

    useEffect(() => {
        if (error) {
            setCouponError(error.coupon ? payment.summary.errors.invalidCode : null);
        }
        if (coupon.percent_off || coupon.discount) {
            setCode('');
            setShowCode(false);
        }
    }, [error, coupon]);

    const submitCode = async () => {
        setCouponError(null);
        submitCoupon(code);
    };

    const toggleCode = () => {
        if (coupon.percent_off || coupon.discount) {
            setCouponError(null);
            clearCoupon();
            setCode('');
            setShowCode(false);
        } else {
            if (
                model === 'monthly' &&
                goal.value !== 'closet_cleanout' &&
                !stylist.is_celebrity &&
                !campaign
            ) {
                setCouponError(payment.summary.errors.onetimeOnly);
            } else {
                if (isMobile) window.scrollTo(0, 0);
                setCouponError(null);
                clearCoupon();
                setCode('');
                isMobile ? toggleMobileCollapse('coupon') : setShowCode(!showCode);
            }
        }
    };

    const onModelChange = ({ target: { name } }: Record<string, { name: string }>) => {
        setCouponError(null);
        onSelect(name);
        if (name === 'monthly') setShowCode(false);
    };
    return (
        <Container className="no-padding" fluid>
            {goal.value !== 'closet_cleanout' &&
                plan.value !== 'lux' &&
                !stylist.is_celebrity &&
                !campaign && (
                    <Row>
                        <Col>
                            <p>{payment.summary.text}</p>
                            <RadioButton
                                type="dark"
                                fill={false}
                                key="monthly"
                                id="monthly"
                                value={plan.price.monthly}
                                isChecked={model === 'monthly'}
                                onClick={onModelChange}
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: payment.summary.subscriptions.monthly.replace(
                                                '%plan_charge%',
                                                plan.price.monthly.toString()
                                            )
                                        }}
                                    />
                                }
                            />
                            <RadioButton
                                type="dark"
                                fill={false}
                                key="onetime"
                                id="onetime"
                                value={plan.price.onetime}
                                isChecked={model === 'onetime' || stylist.is_celebrity}
                                onClick={onModelChange}
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: payment.summary.subscriptions.onetime
                                                .replace(
                                                    '%plan_charge%',
                                                    plan.price.onetime.toString()
                                                )
                                                .replace('%plan%', plan.title)
                                        }}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                )}

            <hr />

            <Row className="price-summary">
                <Col>
                    {payment.summary.package.replace(
                        '%plan%',
                        `${
                            goal.value === 'closet_cleanout'
                                ? payment.summary.cleanout
                                : `Wishi ${plan.title}`
                        }`
                    )}
                </Col>
                <Col xs={3}>${coupon.subtotal || plan.price[model as PriceType]}</Col>
            </Row>
            <Row className={`price-summary ${coupon.discount ? 'show' : 'hide'}`}>
                <Col>{coupon.description}</Col>
                <Col xs={4}>-${coupon.discount}</Col>
            </Row>
            <Row className="price-summary bold">
                <Col>
                    {model === 'monthly' &&
                    goal.value !== 'closet_cleanout' &&
                    !stylist.is_celebrity
                        ? payment.summary.totalMonthly
                        : payment.summary.total}
                </Col>
                <Col xs={3}>
                    ${coupon.total !== undefined ? coupon.total : plan.price[model as PriceType]}
                </Col>
            </Row>
            {model === 'monthly' && goal.value !== 'closet_cleanout' && !stylist.is_celebrity && (
                <Row className="price-summary bold">
                    <Col>{payment.summary.due}</Col>
                    <Col xs={3}>$0</Col>
                </Row>
            )}

            <hr />

            <a className="add-code" onClick={toggleCode}>
                {coupon.percent_off || coupon.discount
                    ? payment.summary.remove
                    : payment.summary.add}
            </a>
            <Row className={`promotion-code ${showCode ? 'show' : 'hide'}`}>
                <Col>
                    <Form.Control
                        type="text"
                        value={code}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setCode(e.target.value)
                        }
                    />
                    <Button onClick={submitCode}>{payment.summary.submit}</Button>
                </Col>
            </Row>
            <Row>
                <Col className="error">{couponError}</Col>
            </Row>
        </Container>
    );
};

export default (props: any) => (
    //<Elements stripe={stripePromise}>
    <Summary {...props} />
    //</Elements>
);
