import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import content from '../../../content.json';
import { User } from '../../../services';
import { BillingForm } from '../../';

const texts = content.billing;

const Billing = ({ loading, user, updateBillingDetails, toggle }) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [billing, setBilling] = useState({});
    const [valid, setValid] = useState(false);

    const onConfirm = async () => {
        const { user_uuid } = user;
        setButtonDisabled(true);
        await User.updateBillingDetails({
            user_uuid,
            data: { billing_details: billing }
        });
        updateBillingDetails(billing);
        toggle();
    };

    return (
        <Container className="billing">
            <Row>
                <Col>
                    <div className="back-btn no-text" onClick={toggle} />
                    <div className="title">{texts.mobile.title}</div>
                </Col>
            </Row>
            <Row style={{ height: '100%' }}>
                <Col className="d-flex flex-column">
                    <BillingForm
                        editMode={true}
                        onChange={(data) => setBilling(data)}
                        onValidate={setValid}
                    />
                    <div className="button-container">
                        <Button
                            className="submit-button"
                            disabled={!valid || loading || buttonDisabled}
                            onClick={onConfirm}
                            variant="dark">
                            Confirm
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Billing;
