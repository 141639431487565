import './style.scss';

import React from 'react';

import { Loader } from '../';
import Content from './Content';

const MobileSideCollapse = ({ mobileCollapse, mobileCollapseContent, loading, children }) => {
    const ContentComponent = mobileCollapseContent ? Content[mobileCollapseContent] : null;
    return (
        <div
            className={`mobile-side-collapse d-block sm-d-none ${mobileCollapse ? 'show' : 'hide'}`}
        >
            {loading && <Loader />}
            {ContentComponent && <ContentComponent />}
            {children}
        </div>
    );
};

export default MobileSideCollapse;
