import './Onboarding.scss';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Page } from '../../components';
import { Tracking } from '../../services';
import { LdEvent } from '../../services/LaunchDarkly';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from '../../services/mixpanel';
import { Header, StepsProgressBar } from './components';
import { TOnboardingState, TStep } from './types';
import { useOnboardingComplete } from './useOnboardingComplete';
import { getProgress, getStepComponent } from './utils';

const rootUrl = '/onboarding';

export const Onboarding: FC = () => {
    const isInitialized = useRef(false);
    const { step = 'gender' } = useParams<{ step: TStep }>();
    const location = useLocation();
    const navigate = useNavigate();
    const ldClient = useLDClient();
    const [onboardingState, setOnboardingState] = useState<TOnboardingState>({
        source: location.state?.mpSource ?? MP_VALUES.ONBOARDING_QUIZ
    });
    const complete = useOnboardingComplete();
    const progress = useMemo(
        () => getProgress(step, onboardingState.gender),
        [step, onboardingState]
    );
    const Step = getStepComponent(step);

    const updateOnboardingState = (newState: TOnboardingState) => {
        setOnboardingState({ ...onboardingState, ...newState });
    };

    const navigateToStep = (step: TStep, innerStepIndex: string | number = '') => {
        navigate(`${rootUrl}/${step}/${innerStepIndex}`);
    };

    const proceed = (newState: TOnboardingState) => {
        const state = { ...onboardingState, ...newState };
        const progress = getProgress(step, state.gender); // It is important to re-fetch progress here because it is affected by newState.gender

        if (!progress) {
            rollback();
            return;
        }

        setOnboardingState(state);

        if (progress.nextStep) {
            navigateToStep(progress.nextStep);
        } else {
            complete(state);
        }
    };

    const rollback = useCallback(() => {
        navigate(`${rootUrl}/${progress?.prevStep ?? ''}`, {replace: true});
    }, [navigate, progress]);

    useEffect(() => {
        !progress && rollback();
    }, [progress, rollback]);

    if (!progress) {
        return <></>;
    }

    if (!isInitialized.current) {
        isInitialized.current = true;
        Tracking.tag({ event: 'ws.beginOnboardingQuiz' });
        trackEvent({
            name: MP_EVENTS.ONBOARDING_INTRO_VIEWS,
            properties: { [MP_PROPS.SOURCE]: onboardingState.source }
        });
        ldClient?.track(LdEvent.ONBOARDING_INTRO_VIEW);
    }

    return (
        <Page header={false} footer={false} fixedHeader={false}>
            <Container className="onboarding no-padding" fluid>
                <Header progress={progress} />
                <StepsProgressBar progress={progress} navigateToStep={navigateToStep} />
                <Step
                    onboardingState={onboardingState}
                    updateOnboardingState={updateOnboardingState}
                    proceed={proceed}
                    rollback={rollback}
                    navigateToStep={navigateToStep}
                />
            </Container>
        </Page>
    );
};
