import moment from 'moment';
import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import content from 'content.json';

import { Pagination } from 'components';
import { StylistReview } from 'store/stylist-service/stylist-api-types';
import { StylistUser } from 'types/stylist';
import { useGetStylistReviewsQuery } from 'store/stylist-service/stylist-api-slice';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'store/ui/actions';
import { selectUserId } from 'store/auth-service/auth-slice';

const { stylist: stylistContent} = content;

interface ReviewProps {
    review: StylistReview
}

const Review: React.FC<ReviewProps> = ({ review }) => (
    <Container className="review">
        <Row>
            <Col className="reviewer">
                <div className="profile-image">
                    {review.user?.pictureMedium ? (
                        <Image src={review.user.pictureMedium} />
                    ) : (
                        <div className="default-image">
                            {review.user?.firstName?.[0]}
                            {review.user?.lastName?.[0]}
                        </div>
                    )}
                </div>
                <div className="info">
                    <div className="name">{review.user?.firstName}</div>
                    <div className="date">{moment(review.created).format('MMMM YYYY')}</div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="description">
                {review.description}
                {review.picture && <Image src={review.picture} fluid />}
            </Col>
        </Row>
    </Container>
);

interface ReviewsProps {
    stylist: StylistUser;
}

const Reviews: React.FC<ReviewsProps> = ({ stylist }) => {
    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    const stylistId = stylist.uuid;
    const REVIEWS_PER_PAGE = 5;
    const reviewsRef = React.createRef<HTMLDivElement>();
    const [pageNum, setPageNum] = React.useState(0);
    const { data: reviews = [] } = useGetStylistReviewsQuery({ stylistId, from: pageNum* REVIEWS_PER_PAGE, count: REVIEWS_PER_PAGE });
    const canAddReview = userId && stylist?.has_session;

    const onPageChange = (page: number) => {
        reviewsRef.current ? 
            reviewsRef.current.offsetParent?.scrollIntoView({ behavior: 'smooth' })
            : window.scrollTo({ top: 0, behavior: 'smooth'});
        setPageNum(page);
    };

    return (
        <Row className="stylist-reviews">
            {canAddReview && (
                <Col>
                    <Button
                        className="add-review-btn"
                        variant="outline-dark"
                        onClick={() => toggleModal(dispatch, { type: 'AddReview', data: {data: {stylist, userId }} })}
                    >
                        {stylistContent.reviews.add}
                    </Button>
                </Col>
            )}

            {reviews.length > 0 && (
                <Col>
                    <div ref={reviewsRef}>
                        <div className="title">
                            {stylistContent.reviews.title.replace(
                                '%number%',
                                stylist.reviews_count?.toString() ?? ''
                            )}
                        </div>

                        <Container className="reviews">
                            {reviews.map((review, i) => (
                                <Row key={i} className="review-row">
                                    <Col>
                                        <Review review={review} />
                                    </Col>
                                </Row>
                            ))}
                        </Container>

                        {stylist.reviews_count > 5 && (
                            <Pagination
                                total={stylist.reviews_count}
                                pageSize={REVIEWS_PER_PAGE}
                                onChange={onPageChange}
                                showNumbers={false}
                            />
                        )}
                        <hr />
                    </div>
                </Col>
            )}
        </Row>
    );
};

export default Reviews;
