import { FC } from 'react';

import { BuyButton } from './BuyButton';

type TBenefit = {
    title: string;
    text: string;
    imageUrl: string;
    imageWidth: number;
    textWidth: number;
};

const benefits: TBenefit[] = [
    {
        title: 'Access to Professional Stylists',
        text: "Every Wishi stylist is trained by celebrity stylist Karla Welch, the talent behind Olivia Wilde, Sarah Paulson and Tracee Ellis Ross's award-winning looks.",
        imageUrl: '//media-cf.wishi.me/react/giftcards/access_to_stylist.webp',
        imageWidth: 576,
        textWidth: 466
    },
    {
        title: 'Receive Personalized Recommendations ',
        text: 'Our stylists can introduce your gift recipient to new brands or work with their favorites. They’ll create a series of unique, shoppable "style boards" tailored to their individual preferences and style needs.',
        imageUrl: '//media-cf.wishi.me/react/giftcards/personalized_recommendations.webp',
        imageWidth: 402,
        textWidth: 520
    },
    {
        title: 'Add Shopping Credit with your Gift card',
        text: 'Enhance their Wishi experience by adding Shopping Credit to your gift card! With so many carefully curated items to explore, this added credit will let them easily purchase the pieces they love.',
        imageUrl: '//media-cf.wishi.me/react/giftcards/shopping_credit.webp',
        imageWidth: 521,
        textWidth: 521
    }
];

export const Benefits: FC = () => {
    return (
        <div className="section benefits">
            <h2 className="section-title">Wishi Gift Card Benefits</h2>
            <ul className="benefits-list">
                {benefits.map(({ title, text, imageUrl, imageWidth, textWidth }, index) => (
                    <li key={index}>
                        <div className="image" style={{ maxWidth: imageWidth + 'px' }}>
                            <img src={imageUrl} alt={title} />
                        </div>
                        <div className="description" style={{ maxWidth: textWidth + 'px' }}>
                            <h3 className="title">{title}</h3>
                            <p className="text">{text}</p>
                        </div>
                    </li>
                ))}
            </ul>
            <BuyButton />
        </div>
    );
};
