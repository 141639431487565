import { IAction, IActionWithPayload } from '../types';

export enum ActionTypes {
    SETUP_ERROR = 'error/SETUP_ERROR',
    RESET_ERROR = 'error/RESET_ERROR'
}

export interface ErrorState {
    statusCode: number;
    message: string;
    subject: string;
    error: {};
    showMessage: boolean;
    errors: any[];
}

export type Actions =
    | IActionWithPayload<
          ActionTypes.SETUP_ERROR,
          {
              error: any;
              message: string;
              showMessage: boolean;
          }
      >
    | IAction<ActionTypes.RESET_ERROR>;
