import { connect } from 'react-redux';

import OrdersComponent from './Orders';

const mapStateToProps = ({ users: { loading, ordersTotal }, user: { user } }) => ({
    loading,
    user,
    ordersTotal
});

const mapDispatchToProps = (dispatch) => ({});

export const Orders = connect(mapStateToProps, mapDispatchToProps)(OrdersComponent);
