import { MP_EVENTS, trackEvent } from 'services/mixpanel';

const zeWidgetId = 'zewidget-visibility';

const removeScript = (id: string[]) => {
    id.forEach((elemId) => {
        const previousItems = document.getElementById(elemId);
        if (previousItems) {
            const parent = previousItems?.parentNode;
            if (parent) parent.removeChild(previousItems);
        }
    });
};

const generateScriptTag = (id: string, url?: string, text?: string) => {
    const script = document.createElement('script');
    if (text) {
        script.textContent = text;
        script.id = zeWidgetId;
        document.body.appendChild(script);
    } else if (url) {
        script.src = url;
        script.async = true;
        script.id = 'ze-snippet';
        document.body.appendChild(script);
    }
};

const zeElementsList = ['zewidget-visibility'];

export const InitZenDeskWidget = (url: string) => {
    removeScript(zeElementsList);
    if (document.getElementById('ze-snippet')) {
        generateScriptTag(zeWidgetId, undefined, "zE('webWidget', 'show')");
    } else {
        generateScriptTag('ze-snippet', url);
    }
};

export const HideZenDeskWidget = () => {
    removeScript(zeElementsList);
    generateScriptTag(zeWidgetId, undefined, "zE('webWidget', 'hide')");
};

export const urlUUIDConvertor = (url: string) => {
    const UUIDRegex = /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[a-fA-F]{1}/;
    const matchedString = url.match(UUIDRegex);
    if (matchedString && matchedString[0]) {
        return url.replace(matchedString[0], '');
    } else {
        return url;
    }
};

export const trackZEWidgetClick = (eventName: MP_EVENTS) => {
    const widget = document.querySelector('[tabindex="0"]') as HTMLIFrameElement;
    if (widget?.contentWindow) {
        const button = widget.contentWindow.document.querySelector('[data-testid=launcher]');
        if (button) {
            button.addEventListener('click', () => trackEvent({ name: eventName, properties: {} }));
        }
    }
};

export const zendeskEnbaledPage = [
    '/',
    '/gifts',
    '/stylistSearch',
    '/gifts/shopping',
    '/stylist/profile',
    '/feed',
    '/how-it-works'
];
