import './style.scss';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image } from 'react-bootstrap';
import { Formatter } from '../../services';
import { Item, ClosetItem } from '../../types/styling';

interface IGridItem {
    item: Item | ClosetItem;
    id?: string;
    type: string;
    onClick?: () => void | null;
    onDetailsClick?: () => void;
    onImageClick?: () => void;
    onFavorite?: () => void;
    onRemove?: () => void;
    size: string;
    children: any;
    availability?: { [index: string]: { [size: string]: string } };
}

const GridItem: React.FC<IGridItem> = ({
    item,
    id = '',
    type,
    onClick,
    onDetailsClick,
    onImageClick,
    onFavorite,
    onRemove,
    size = 'small',
    children,
    availability = {}
}) => {
    const showPrice = () => item.price && Number(item.price) > 0;
    item = item?.items ? item.items[0] : item;
    const isStockCheckRequired = type === 'shop' && Object.keys(availability).length > 0;
    const sizeArray = item.size && Array.isArray(item?.size) && item.size.join(',');
    return (
        <div className={`grid-item ${size} ${type}-item`} key={item.uuid}>
            {type === 'shop' && (
                <div style={{ display: 'contents' }}>
                    <div
                        className={`grid-item small fav-star ${item.favorite ? 'filled' : 'empty'}`}
                        onClick={onFavorite}
                    />
                </div>
            )}
            {type === 'store' && <div className="remove" onClick={onRemove} />}
            {type === 'profile' && (
                <FontAwesomeIcon className="trash" icon={faTrashAlt} onClick={onRemove} />
            )}
            <Image
                src={item.pictureUrl || item.photo_large || item.picture}
                onClick={onImageClick ? onImageClick : onClick}
            />
            <div className="details">
                <span onClick={onDetailsClick ? onDetailsClick : onClick}>
                    {isStockCheckRequired ? (
                        <p
                            className={`sizes ${
                                sizeArray ? '' : 'grid-item small stockIcon loading'
                            } `}
                        >
                            {sizeArray}
                        </p>
                    ) : (
                        type === 'shop' && <p className="sizes">{sizeArray}</p>
                    )}
                    <p className="title">{item.itemBrand || item.brand_name}</p>
                    <p className="price">
                        {type === 'shop' && showPrice() && Formatter.price(item.price)}
                    </p>
                </span>
                {(item.buyUrlShort || item.buy_url_short) &&
                    type === 'shop' &&
                    item.type !== 'inspiration' && (
                        <a href={item.productUrl || item.buy_url} target="_blank" rel="noreferrer">
                            {item.brand || item.buyUrlShort || item.buy_url_short}
                        </a>
                    )}
            </div>
            {children}
        </div>
    );
};
export default GridItem;
