import { connect } from 'react-redux';

import _Quiz from './Quiz';
import _Welcome from './Welcome';

const mapStateToProps = ({
    user: { user },
    booking: { stylist },
    payments: { price }
}) => ({
    user,
    stylist,
    price
});

export const Welcome = connect(mapStateToProps)(_Welcome);
export const Quiz = connect(mapStateToProps)(_Quiz);
