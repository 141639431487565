import { connect } from 'react-redux';

import {
    clearCoupon,
    pay,
    submitCoupon,
    toggleMobileCollapse,
    updatePaymentToken
} from 'redux/reducers/payments/actions';
import Details from './Details';
import MobileCheckout from './MobileCheckout';
import Summary from './Summary';

const mapStateToProps = ({
    booking: { plan, goal, campaign },
    payment: { model },
    user: { user },
    payments: { error, coupon, cards, price, mobileCollapse }
}) => ({
    plan,
    goal,
    model,
    user,
    coupon,
    error,
    cards,
    price,
    mobileCollapse,
    campaign
});

const mapDispatchToProps = (dispatch) => ({
    submitCoupon: (code) => dispatch(submitCoupon(code)),
    clearCoupon: () => dispatch(clearCoupon()),
    toggleMobileCollapse: (content) => dispatch(toggleMobileCollapse(content)),
    pay: (data) => dispatch(pay(data)),
    updatePaymentToken: (token) => dispatch(updatePaymentToken(token))
});

const components = {
    Details: connect(mapStateToProps, mapDispatchToProps)(Details),
    Summary: connect(mapStateToProps, mapDispatchToProps)(Summary),
    MobileCheckout: connect(mapStateToProps, mapDispatchToProps)(MobileCheckout)
};

export default components;
