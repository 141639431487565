import './style.scss';

import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Client } from 'types/user';

import { Loader, Orders, Page } from '../../components';
import content from '../../content.json';

const texts = content.orders;

interface IOrder {
    user: Client;
    loading: boolean;
    ordersTotal: number;
}

const Order: React.FC<IOrder> = ({ loading, user, ordersTotal }) => {
    const counterText = () => `${ordersTotal} ${ordersTotal > 1 ? 'orders' : 'order'}`;

    return (
        <Page className="orders" footer={false}>
            {loading && <Loader />}
            <Container>
                <h4>{texts.title}</h4>
                {ordersTotal > 0 && user && (
                    <h6>
                        {texts.count
                            .replace('%name%', user.first_name ?? '')
                            .replace('%count%', counterText())}
                    </h6>
                )}
                {!loading && ordersTotal === 0 && (
                    <div className="empty">
                        <h6>{texts.empty.title}</h6>
                        <h6>{texts.empty.stylist}</h6>
                        <Link to="/stylistSearch">{texts.empty.button}</Link>
                    </div>
                )}
                <Orders />
            </Container>
        </Page>
    );
};

export default Order;
