import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ActionTypes, ServicesState } from './types';
import { Actions } from '../actions';

const initialState: ServicesState = {
    isTwilioInitialized: false
};

export const servicesReducer = (state = initialState, action: Actions): ServicesState => {
    switch (action.type) {
        case ActionTypes.SET_TWILIO_INITIALIZED: {
            return {
                ...state,
                isTwilioInitialized: action.payload
            };
        }
        default:
            return state;
    }
};
export const name = 'services';
export const useServicesStore: TypedUseSelectorHook<ServicesState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
