import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ActionTypes, UserState } from './types';
import { Actions } from '../actions';

const initialState: UserState = {
    favoriteStylists: [],
    user: null,
    redirectUrl: null,
    favoriteLooks: [],
    cart: null,
    twilioInit: false,
    favoriteSection: null
};

export const userReducer = (state = initialState, action: Actions): UserState => {
    switch (action.type) {
        case ActionTypes.LOAD_FAVORITE_STYLISTS_SUCCESS:
            return {
                ...state,
                favoriteStylists: action.payload
            };
        case ActionTypes.TOGGLE_STYLIST: {
            const isFavorite = state.favoriteStylists?.find(
                (favorite) => favorite?.uuid === action.payload.uuid
            );
            return {
                ...state,
                favoriteStylists: isFavorite
                    ? state.favoriteStylists.filter((s) => s.uuid !== action.payload.uuid)
                    : [...state.favoriteStylists, action.payload]
            };
        }
        case ActionTypes.LOGOUT_USER: {
            return {
                ...state,
                user: null,
                redirectUrl: null,
                favoriteLooks: [],
                favoriteStylists: [],
                cart: null,
                twilioInit: false,
                favoriteSection: null
            };
        }
        case ActionTypes.REFRESH_USER_DATA:
        case ActionTypes.UPDATE_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                } as any
            };
        }
        case ActionTypes.UPDATE_FAVORITE_SECTION: {
            return {
                ...state,
                favoriteSection: action.payload
            };
        }
        default:
            return state;
    }
};
export const name = 'user';
export const useUserStore: TypedUseSelectorHook<UserState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
