import { connect } from 'react-redux';
import { loadUserMatches, setBookingSource } from 'store/common/actions';

import {
    clearOutfit,
    loadFeed,
    loadFeedFilters,
    loadItem,
    loadOutfit,
    setFilter,
    toggleFilters,
    updateFeedGender,
    updateItem,
    updateOutfit
} from 'redux/reducers/style/actions';
import { clearLooks, loadStylistLooks, updateStylistLook } from 'redux/reducers/stylists/actions';
import {
    clearFavoriteLooks,
    loadFavoriteLooks,
    updateFavoriteLook
} from 'redux/reducers/users/actions';
import FeedComponent from './Feed';
import OutfitComponent from './Outfit';
import FeedItem from './FeedItem';

const mapStateToProps = ({
    users: { favoriteLooks, cart },
    user: { user },
    style: { feed, feedTotal, feedGender, matches, showFilters, loading, outfit, item },
    stylists: { loading: looksLoading, looks, looksTotal },
    common: { stylistsMatchs, mixpanelStore }
}) => ({
    user,
    loading,
    looksLoading,
    feed,
    feedTotal,
    feedGender,
    showFilters,
    matches: stylistsMatchs,
    favoriteLooks,
    outfit,
    item,
    looks,
    looksTotal,
    cart,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    loadFeedFilters: () => dispatch(loadFeedFilters()),
    loadFeed: (gender) => dispatch(loadFeed(gender)),
    updateFeedGender: (gender) => dispatch(updateFeedGender(gender)),
    loadUserMatches: (user) => loadUserMatches(dispatch, user),
    toggleFilters: () => dispatch(toggleFilters()),
    setFilter: (filter) => dispatch(setFilter(filter)),
    loadOutfit: (uuid) => dispatch(loadOutfit(uuid)),
    loadItem: (uuid) => dispatch(loadItem(uuid)),
    loadStylistLooks: (uuid, params) => dispatch(loadStylistLooks(uuid, params)),
    updateStylistLook: (outfit_uuid, look) => dispatch(updateStylistLook(outfit_uuid, look)),
    updateFavoriteLook: (outfit, source) => dispatch(updateFavoriteLook(outfit, source)),
    updateOutfit: (outfit) => dispatch(updateOutfit(outfit)),
    clearFavoriteLooks: () => dispatch(clearFavoriteLooks()),
    loadFavoriteLooks: (params) => dispatch(loadFavoriteLooks(params)),
    clearOutfit: () => dispatch(clearOutfit()),
    clearLooks: () => dispatch(clearLooks()),
    updateItem: (item) => dispatch(updateItem(item)),
    setBookingSource: (source) => setBookingSource(dispatch, source)
});

export const Feed = connect(mapStateToProps, mapDispatchToProps)(FeedComponent);
export const Outfit = connect(mapStateToProps, mapDispatchToProps)(OutfitComponent);
export const FeedItemFragment = connect(mapStateToProps, mapDispatchToProps)(FeedItem);
