import './style.scss';

import React from 'react';
import { Image, Modal } from 'react-bootstrap';

const Photo: React.FC<{ data?: string }> = ({ data }) => (
    <span className="photo-modal">
        <Modal.Header closeButton />
        <Modal.Body>{data && <Image src={data} />}</Modal.Body>
    </span>
);
export default Photo;
