import './style.scss';

import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { OrderItems } from 'types/orders';

import content from '../../content.json';
import { Formatter } from '../../services';

const texts = content.orders;

interface IOrderItem {
    item: OrderItems;
}

const OrderItem: React.FC<IOrderItem> = ({ item }) => (
    <Container className="order-item" fluid>
        <Row>
            <Col xs={12} md={2}>
                <Image src={item.picture} alt="" />
            </Col>
            <Col className="order-item-info">
                <p className="brand d-none d-md-block">{item.brand_name}</p>
                <p className="price d-none d-md-block">{Formatter.price(item.price)}</p>
                <p className="size">
                    <span className="d-none d-md-block">{texts.size}</span>
                    {item.item_size}
                </p>
            </Col>
        </Row>
    </Container>
);

export default OrderItem;
