import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';

import content from '../../../content.json';

const texts = content.match;

const Banner = ({ onClose }: { onClose: () => void }) => (
    <Row className="matches-banner">
        <div className="close" onClick={onClose} />
        <Col className="banner-content">
            <p>{texts.banner.title}</p>
            <ListGroup>
                {texts.banner.list.map((item) => (
                    <ListGroup.Item key={item} className="banner-item">
                        {item}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Col>
    </Row>
);
export default Banner;
