import './style.scss';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Cart } from '../../../components';
import Empty from './Empty';

export default ({ cart }) => (
    <Container className="profile-cart" fluid>
        <Row>
            <Col id="results" className="results">
                {cart && !cart.length && <Empty section="cart" />}
                <Cart />
            </Col>
        </Row>
    </Container>
);
