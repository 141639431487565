import { Dispatch } from 'redux';
import { ActionTypes, BookingSource } from './types';
import ReduxService from 'services/redux-service';
import { Stylist, Style, Config } from 'services';
import { CoreStylist } from 'types/stylist';
import { store } from 'store';
import {
    InitZenDeskWidget,
    HideZenDeskWidget,
    zendeskEnbaledPage,
    urlUUIDConvertor
} from 'services/utils/zenDesk-widget';
import { Client as ClientUser } from 'types/user';
import { defaultStylists } from 'services/utils/user-utils';
import { getUserId, getUserToken } from 'services/utils/user-utils';

const loadDefaultStylists = async (dispatch: Dispatch, gender: string) => {
    const stylists = defaultStylists(gender);
    const { data } = await Stylist.get(stylists?.toString());
    dispatch({
        type: ActionTypes.LOAD_MATCHES_SUCCESS,
        payload: data.items
    });
};

export const clearFilters = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.CLEAR_FILTERS
    });

export const loadStylistsNames = async (dispatch: Dispatch) => {
    const legacyBaseUrl = Config.get('apiroot2');
    const api = `${legacyBaseUrl}stylist/all/names`;
    await ReduxService.fetch({
        dispatch,
        targetAction: ActionTypes.LOAD_STYLISTS,
        url: api,
        prettifyData: (data: CoreStylist[]) =>
            data.map((s) => ({
                value: s.stylist_uuid,
                label: s.stylist_name
            }))
    });
};
export const loadUserMatches = async (dispatch: Dispatch, user: ClientUser) => {
    clearFilters(dispatch);
    const userId = getUserId();
    const isValidUser = user && user.gender;
    if (userId && isValidUser) {
        const bsetMatchResults = await ReduxService.fetch({
            dispatch,
            targetAction: ActionTypes.LOAD_MATCHES,
            url: Style.urls.getMatches(userId),
            config: { headers: { token: getUserToken() } },
            prettifyData: (data: { items: any[] }) => data.items
        });
        if (!bsetMatchResults) {
            loadDefaultStylists(dispatch, user.gender);
        }
    } else if (user && userId) {
        loadDefaultStylists(dispatch, user.gender);
    }
};

export const setMixpanelStore = (
    dispatch: Dispatch,
    data: { [index: string]: { [key: string]: string | string[] | number | boolean } }
) => {
    dispatch({
        type: ActionTypes.SET_MIXPANEL_STORE,
        payload: data
    });
};

export const setBookingSource = async (dispatch: Dispatch, source: BookingSource) => {
    dispatch({
        type: ActionTypes.SET_BOOKING_SOURCE,
        payload: source
    });
};

export const toggleZEWidget = (dispatch: Dispatch, page: string) => {
    const convertedPage = urlUUIDConvertor(page);
    if (zendeskEnbaledPage.includes(convertedPage) && store.getState().common.zeStatus == false) {
        return toggleZEWidgetOn(dispatch);
    } else if (
        !zendeskEnbaledPage.includes(convertedPage) &&
        store.getState().common.zeStatus == true
    ) {
        return toggleZEWidgetOff(dispatch);
    }
};

export const toggleZEWidgetOn = (dispatch: Dispatch) => {
    InitZenDeskWidget(
        'https://static.zdassets.com/ekr/snippet.js?key=cb6a9252-bc4d-470d-8f07-58312f3a90e1'
    );
    return dispatch({
        type: ActionTypes.SET_ZE_ON
    });
};

export const toggleZEWidgetOff = (dispatch: Dispatch) => {
    HideZenDeskWidget();
    return dispatch({
        type: ActionTypes.SET_ZE_OFF
    });
};
