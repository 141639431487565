import './style.scss';

import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

const Pagination = ({ total, pageSize, onChange, showNumbers = true }) => {
    const [pages, setPages] = useState([]);
    const [current, setCurrent] = useState(0);
    const [isFirst, setIsFirst] = useState(true);
    const [isLast, setIsLast] = useState(false);

    useEffect(() => {
        setIsFirst(current === 0);
        setIsLast(current === Math.floor(total / pageSize));
    }, [current, total, pageSize]);

    useEffect(() => {
        let pages = [];
        for (let i = 0; i <= Math.floor(total / pageSize); i++) {
            pages.push(i);
        }
        setPages(pages);
    }, [total, pageSize]);

    const pageClick = (page) => {
        setCurrent(page);
        onChange(page);
    };

    const prev = () => {
        if (!isFirst) {
            pageClick(current - 1);
        }
    };

    const next = () => {
        if (!isLast) {
            pageClick(current + 1);
        }
    };

    const renderPageNumbers = pages.map((number) => (
        <li
            key={number}
            id={number}
            className={current === number ? 'active' : ''}
            onClick={(e) => pageClick(parseInt(e.target.id))}
        >
            {number}
        </li>
    ));

    return (
        <div className="pagination">
            <FontAwesomeIcon
                icon={faCaretLeft}
                onClick={prev}
                className={isFirst ? 'disabled' : ''}
            />
            {showNumbers && <ul>{renderPageNumbers}</ul>}
            <FontAwesomeIcon
                icon={faCaretRight}
                onClick={next}
                className={isLast ? 'disabled' : ''}
            />
        </div>
    );
};

export default Pagination;
