import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
    isMatchedStylist,
    MP_EVENTS,
    MP_PROPS,
    MP_VALUES,
    registerProperties,
    trackEvent
} from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { useCommonStore } from 'store/common/reducer';

import content from '../../content.json';
import { Tracking } from '../../services';
import { Loader } from '../';
import Sections from './sections';

const { payment } = content;

const Payment = ({
    plan,
    goal,
    setModel,
    campaign,
    setPlanModel,
    setPrice,
    submitCoupon,
    loading,
    result,
    clearResult,
    stylist,
    getUserCards,
    user,
    mixpanelStore
}) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const bookingSource = useCommonStore((store) => store.bookingSource);

    const paymentMixpanelEvents = (result) => {
        const date = new Date().toISOString();
        const bookingsCount =
            typeof user?.bookings_count === 'string'
                ? parseInt(user?.bookings_count)
                : user?.bookings_count;

        registerProperties({
            [MP_PROPS.LAST_BOOKING_DATE]: date,
            [MP_PROPS.LAST_BOOKING_AMOUNT]: result.price
        });
        if (result.subscription !== null)
            registerProperties({
                [MP_PROPS.ACTIVE_SUBSCRIPTION]: MP_VALUES.YES
            });
        if (bookingsCount === 0) {
            registerProperties({ [MP_PROPS.FIRST_BOOKING_DATE]: date });
        }
        trackEvent({
            name: MP_EVENTS.PAYMENT_COMPLETED,
            properties: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PAYMENT_TYPE]: 'order',
                [MP_PROPS.PLAN_CYCLE]: result.subscription === null ? 'single' : 'subscription',
                [MP_PROPS.USED_COUPON]: result.coupon === null ? MP_VALUES.NO : MP_VALUES.YES,
                [MP_PROPS.COUPON_NAME]: result.coupon === null ? '' : result.coupon,
                [MP_PROPS.PAYMENT_METHOD]: 'stripe',
                [MP_PROPS.PAYMENT_AMOUNT]: result.price,
                ...bookingSourceToProps(bookingSource)
            }
        });
        trackEvent({
            name: MP_EVENTS.SESSION_STARTED,
            properties: {
                [MP_PROPS.SESSION_UUID]: result.session_uuid,
                [MP_PROPS.USER_UUID]: user.user_uuid,
                [MP_PROPS.USER_EMAIL]: user.email,
                [MP_PROPS.SESSION_COUNT]: bookingsCount + 1,
                [MP_PROPS.SESSION_STATUS]: 'Ongoing',
                [MP_PROPS.SESSION_AMOUNT]: result.price,
                [MP_PROPS.IS_TRIAL]: result.status === 'trailing',
                [MP_PROPS.PLAN_TYPE]: result.plan_name,
                [MP_PROPS.STYLIST_NAME]: stylist.name,
                [MP_PROPS.STYLIST_UUID]: result.stylist_uuid,
                [MP_PROPS.IS_SESSION_STYLIST_MATCH]: isMatchedStylist(matches, stylist.uuid)
            }
        });
    };

    const paymentGAEvents = (result) => {
        Tracking.google({
            type: 'event',
            event: 'booking_completed',
            data: {
                booking_amount: result.price,
                plan_type: result.plan_name,
                plan_model: result.subscription === null ? 'single' : 'subscription',
                client_full_name: ''.concat(user.firstName, ' ', user.lastName),
                client_uuid: user.uuid,
                client_email: user.email,
                stylist_name: stylist.name,
                stylist_uuid: result.stylist_uuid
            }
        });
    };

    const trackingPaymntsEvent = () => {
        Tracking.tag({
            event: 'eec.checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 1 },
                    products: [
                        {
                            id: stylist.uuid,
                            name: stylist.name,
                            category: '/category/stylist/',
                            variant: plan.value,
                            quantity: 1,
                            dimension3: 'Ecommerce'
                        }
                    ]
                }
            }
        });
    };

    useEffect(() => {
        const initModel =
            goal.value === 'closet_cleanout' ||
            plan.value === 'lux' ||
            stylist.is_celebrity ||
            campaign
                ? 'onetime'
                : 'monthly';
        setPlanModel(initModel);
        setModel(initModel);
        trackEvent({
            name: MP_EVENTS.CHECKOUT_VIEWS,
            properties: {
                ...mixpanelStore.onboarding
            }
        });

        setPrice(plan.price[initModel]);
        getUserCards();
    }, []);

    useEffect(() => {
        clearResult();
        if (result) {
            trackingPaymntsEvent();
            paymentMixpanelEvents(result);
            paymentGAEvents(result);
            navigate('/thank-you');
        }
    }, [result]);

    const onSelect = (selection) => {
        if (selection === 'monthly') {
            submitCoupon('monthly-session');
        } else {
            submitCoupon();
        }
        setPrice(plan.price[selection]);
        setPlanModel(selection);
        setModel(selection);
    };
    return (
        <Container className="payment">
            {loading && <Loader />}
            <Row>
                {isMobile ? (
                    <Col xs={12} className="d-flex d-sm-none">
                        <Card>
                            <Card.Body>
                                <Sections.MobileCheckout onSelect={onSelect} stylist={stylist} />
                            </Card.Body>
                        </Card>
                    </Col>
                ) : (
                    <>
                        <Col md={6} className="d-none d-sm-flex">
                            <Card>
                                <Card.Header>{payment.summary.title}</Card.Header>
                                <Card.Body>
                                    <Sections.Summary onSelect={onSelect} stylist={stylist} />
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} className="d-none d-sm-flex">
                            <Card>
                                <Card.Header>{payment.details.title}</Card.Header>
                                <Card.Body>
                                    <Sections.Details />
                                </Card.Body>
                            </Card>
                        </Col>
                    </>
                )}
            </Row>
        </Container>
    );
};

export default Payment;
