import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserStore } from 'store/user/reducer';

export const GetStartedButton: FC = () => {
    const user = useUserStore((store) => store?.user);
    const navigate = useNavigate();

    const onClick = () => {
        navigate(user ? '/stylistSearch' : '/onboarding');
    };
    return (
        <button className="btn btn-dark" onClick={onClick}>
            Get Started
        </button>
    );
};
