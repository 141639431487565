import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import {
    checkoutList,
    checkoutPay,
    clearResult,
    getUserCards,
    paymentButtonDisabled,
    setPrice,
    toggleMobileCollapse,
    updatePaymentToken
} from 'redux/reducers/payments/actions';
import { loadItem, loadOutfit, updateItem } from 'redux/reducers/style/actions';
import {
    addToCart,
    addToCloset,
    getBillingDetails,
    removeFromCart,
    updateBillingDetails,
    setCurrentItem
} from 'redux/reducers/users/actions';
import ItemComponent from './Item';
import ItemCheckoutComponent from './ItemCheckout';
import ItemConfirmationComponent from './ItemConfirmation';

const mapStateToProps = ({
    users: { billingDetails, cartTotal, cartPrice, currentItem },
    user: { user },
    style: { loading: styleLoading, outfit, item },
    payments: { loading: paymentProcess, result: paymentResult, price, cards, buttonDisabled }
}) => ({
    styleLoading,
    paymentProcess,
    paymentResult,
    outfit,
    item,
    user,
    cartTotal,
    cartPrice,
    price,
    billingDetails,
    cards,
    buttonDisabled,
    currentItem
});

const mapDispatchToProps = (dispatch) => ({
    loadOutfit: (uuid) => dispatch(loadOutfit(uuid)),
    loadItem: (uuid) => dispatch(loadItem(uuid)),
    updateItem: (item) => dispatch(updateItem(item)),
    updatePaymentToken: (token) => dispatch(updatePaymentToken(token)),
    setPrice: (price) => dispatch(setPrice(price)),
    getUserCards: () => dispatch(getUserCards()),
    checkoutList: (data, billing) => dispatch(checkoutList(data, billing)),
    checkoutPay: (data) => dispatch(checkoutPay(data)),
    clearResult: () => dispatch(clearResult()),
    addToCloset: (item) => dispatch(addToCloset(item)),
    addToCart: (item, source) => dispatch(addToCart(item, source)),
    removeFromCart: (item) => dispatch(removeFromCart(item)),
    paymentButtonDisabled: (disabled) => dispatch(paymentButtonDisabled(disabled)),
    toggleMobileCollapse: (content) => dispatch(toggleMobileCollapse(content)),
    updateBillingDetails: (billingDetails) => dispatch(updateBillingDetails(billingDetails)),
    getBillingDetails: () => dispatch(getBillingDetails()),
    toggleModal: (data) => toggleModal(dispatch, data),
    setCurrentItem: (item) => dispatch(setCurrentItem(item))
});

export const Item = connect(mapStateToProps, mapDispatchToProps)(ItemComponent);
export const ItemCheckout = connect(mapStateToProps, mapDispatchToProps)(ItemCheckoutComponent);
export const ItemConfirmation = connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemConfirmationComponent);
