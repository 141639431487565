import { getOutfitUrl } from 'services/utils/url-utils';
import { OutfitType } from 'types/item';
import { Client } from 'types/user';

import { MP_PROPS } from './consts';

export interface IMixpanelPeopleProperties {
    UUID?: string;
    Email: string | null;
    Phone: string;
    $first_name?: string;
    $last_name?: string;
    $name: string;
    Gender: string;
    Country: string | null;
    [MP_PROPS.BOOKING_COUNT]?: string;
    [MP_PROPS.ORDER_COUNT]?: number;
}

export const getOutfitProperties = (outfit: OutfitType, outfitSource: string) => {
    const outfitUuid = outfit.uuid || outfit.unique;
    return {
        [MP_PROPS.LOOK_UUID]: outfitUuid,
        [MP_PROPS.LOOK_SOURCE]: outfitSource,
        [MP_PROPS.LOOK_URL]: getOutfitUrl(outfitUuid),
        [MP_PROPS.STYLIST_NAME]:
            outfitSource === 'favorites'
                ? outfit.owner.name
                : outfit.owner.first_name.concat(' ', outfit.owner.last_name),
        [MP_PROPS.STYLIST_UUID]: outfit.owner.uuid
    };
};

export const extractUserDataForMixpanelPeople = (data: Client): IMixpanelPeopleProperties => ({
    $first_name: data.first_name,
    $last_name: data.last_name,
    $name: `${data.first_name} ${data.last_name}`,
    [MP_PROPS.UUID]: data.user_uuid,
    [MP_PROPS.EMAIL]: data.email,
    [MP_PROPS.PHONE]: data.phone_number,
    [MP_PROPS.GENDER]: data.gender,
    [MP_PROPS.COUNTRY]: data.country,
    [MP_PROPS.BOOKING_COUNT]: data.bookings_count,
    [MP_PROPS.ORDER_COUNT]: data.counters?.purchases
});
