import { connect } from 'react-redux';
import { selectGoal, selectPlan } from 'store/booking/actions';
import { setModel } from 'store/payments/actions';

import { clearResult } from '../../redux/reducers/payments/actions';
import _GoalsCheckout from './GoalsCheckout';
import _GoalsHeader from './GoalsHeader';
import _GoalsPlans from './GoalsPlans';
import _GoalsSelect from './GoalsSelect';

const mapStateToProps = ({
    user: { user },
    booking: { stylist, goal, plan, campaign },
    payments: { mobileCollapse },
    common: { mixpanelStore }
}) => ({
    user,
    stylist,
    goal,
    plan,
    mobileCollapse,
    campaign,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    selectGoal: (goal) => selectGoal(dispatch, goal),
    selectPlan: (plan) => selectPlan(dispatch, plan),
    clearGoal: () => selectGoal(dispatch, null),
    clearPlan: () => selectPlan(dispatch, null),
    clearModel: () => setModel(dispatch),
    clearResult: () => dispatch(clearResult()),
    setModel: (model) => setModel(dispatch, model)
});

export const GoalsHeader = connect(mapStateToProps, mapDispatchToProps)(_GoalsHeader);
export const GoalsCheckout = connect(mapStateToProps, mapDispatchToProps)(_GoalsCheckout);
export const GoalsPlans = connect(mapStateToProps, mapDispatchToProps)(_GoalsPlans);
export const GoalsSelect = connect(mapStateToProps, mapDispatchToProps)(_GoalsSelect);
