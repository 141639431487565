import {
    CLEAR_COUPON,
    CLEAR_GIFTCARD,
    CLEAR_RESULT,
    GIFT_CARD_ERROR,
    LOAD_COUNTRIES_FAILED,
    LOAD_COUNTRIES_SUCCESS,
    LOAD_STATES_FAILED,
    LOAD_STATES_SUCCESS,
    PAYMENT_BUTTON_DISABLED,
    PAYMENT_FAILED,
    PAYMENT_START,
    PAYMENT_SUCCESS,
    SET_PLAN_MODEL,
    SET_PRICE,
    TOGGLE_MOBILE_SIDE_COLLAPSE,
    UPDATE_COUPON_FAILED,
    UPDATE_COUPON_START,
    UPDATE_COUPON_SUCCESS,
    UPDATE_GIFTCARD,
    UPDATE_GIFTCARD_STATE,
    UPDATE_PAYMENT_HISOTRY,
    UPDATE_PAYMENT_TOKEN,
    USER_CARDS_START,
    USER_CARDS_SUCCESS
} from './actions';

const initialState = {
    model: 'onetime',
    price: 0,
    coupon: {},
    cards: [],
    error: null,
    result: null,
    loading: false,
    mobileCollapse: false,
    mobileCollapseContent: null,
    paymentToken: '',
    buttonDisabled: true,
    giftcard: {
        amount: 0,
        additionalAmount: 0
    },
    giftcardState: 0,
    countries: [],
    states: [],
    paymentHistory: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PRICE: {
            const { price } = action.payload;
            return {
                ...state,
                price
            };
        }
        case SET_PLAN_MODEL: {
            return {
                ...state,
                model: action.payload
            };
        }
        case USER_CARDS_START:
        case UPDATE_COUPON_START: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_COUPON_SUCCESS: {
            const { coupon } = action.payload;
            return {
                ...state,
                coupon,
                loading: false,
                error: {
                    ...state.error,
                    coupon: null
                }
            };
        }
        case UPDATE_COUPON_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                error: {
                    ...state.error,
                    coupon: error
                }
            };
        }
        case CLEAR_COUPON: {
            return {
                ...state,
                error: null,
                coupon: {}
            };
        }
        case PAYMENT_START: {
            return {
                ...state,
                loading: true,
                error: null,
                result: null
            };
        }
        case PAYMENT_SUCCESS: {
            const { result } = action.payload;
            return {
                ...state,
                loading: false,
                error: null,
                paymentToken: result.paymentToken || '',
                result
            };
        }
        case PAYMENT_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                paymentToken: '',
                result: null,
                error: {
                    ...state.error,
                    payment: error.response
                        ? error.response.data.error || error.response.data.message
                        : error
                }
            };
        }
        case CLEAR_RESULT: {
            return {
                ...state,
                result: null
            };
        }
        case USER_CARDS_SUCCESS: {
            const { cards } = action.payload;
            return {
                ...state,
                cards,
                error: {
                    ...state.error,
                    payment: null
                }
            };
        }
        case TOGGLE_MOBILE_SIDE_COLLAPSE: {
            const { content } = action.payload;
            return {
                ...state,
                mobileCollapse: !state.mobileCollapse,
                mobileCollapseContent: content,
                error: null
            };
        }
        case UPDATE_PAYMENT_TOKEN: {
            const { token } = action.payload;
            return {
                ...state,
                paymentToken: token
            };
        }
        case PAYMENT_BUTTON_DISABLED: {
            const { buttonDisabled } = action.payload;
            return {
                ...state,
                buttonDisabled
            };
        }
        case UPDATE_GIFTCARD: {
            const { giftcard } = action.payload;
            return {
                ...state,
                giftcard: { ...state.giftcard, ...giftcard },
                error: null
            };
        }
        case CLEAR_GIFTCARD: {
            return {
                ...state,
                giftcard: {},
                giftcardState: 0,
                error: null,
                result: null
            };
        }
        case LOAD_COUNTRIES_SUCCESS: {
            const { countries } = action.payload;
            return {
                ...state,
                countries
            };
        }
        case LOAD_STATES_SUCCESS: {
            const { states } = action.payload;
            return {
                ...state,
                states
            };
        }
        case UPDATE_GIFTCARD_STATE: {
            const { giftcardState } = action.payload;
            return {
                ...state,
                giftcardState
            };
        }
        case LOAD_COUNTRIES_FAILED:
        case LOAD_STATES_FAILED:
        case GIFT_CARD_ERROR: {
            const { error } = action.payload;
            return {
                ...state,
                error
            };
        }
        case UPDATE_PAYMENT_HISOTRY: {
            return {
                ...state,
                paymentHistory: action.payload
            };
        }
        default:
            return state;
    }
}
