const SDK_ACCESS_TOKEN_LOCAL_STORAGE_KEY = '[sdk-access-token]';
const B2B_SDK_ACCESS_TOKEN_LOCAL_STORAGE_KEY = '[wishi-sdk-token]'; // B2B SDK token

export const getSDKAccessToken = (): string =>
    localStorage.getItem(SDK_ACCESS_TOKEN_LOCAL_STORAGE_KEY) ?? ``;
export const setSDKAccessToken = (token: string): void => {
    localStorage.setItem(SDK_ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
    localStorage.setItem(B2B_SDK_ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
};
export const deleteSDKAccessToken = (): void => {
    localStorage.removeItem(SDK_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(B2B_SDK_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
};
