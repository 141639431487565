import './style.scss';

import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, InputGroup } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { sentryException } from 'services/SentryLogging';
import {
    Card,
    CardHolder,
    CardType,
    Coupon,
    Goal,
    PaymentError,
    StylistUser
} from 'types/stripForm';
import { Client } from 'types/user';

import content from '../../content.json';
import { Payments, Tracking } from '../../services';
import { CreditCardElement, PaymentRequestButton } from '..';

const { payment } = content;

/*const stripe = require('stripe')(Payments.getStripeKey());
stripe.applePayDomains.create({ domain_name: 'wishi.me' });*/

//const stripePromise = loadStripe(Payments.getStripeKey() as string);

interface IStripeForm {
    model: string;
    goal: Goal;
    price: number;
    user: Client;
    cardholder: CardHolder;
    coupon: Coupon;
    pay: () => void;
    cards: Card[];
    error: PaymentError;
    onSubmit: (token: any, captchaToken?: any) => void;
    onChange: (data: any) => void;
    onFormInvalid: (invalid: boolean) => void;
    updatePaymentToken: (token: string | null | undefined) => void;
    paymentButtonDisabled: (disabled: boolean) => void;
    buttonDisabled: boolean;
    buttonText: string;
    showName: boolean;
    disclaimer: boolean;
    disclaimerText: string;
    stripeButton: boolean;
    recaptcha: boolean;
    checkCoupon: boolean;
    loading: boolean;
    children: React.ReactElement[];
    stylist: StylistUser;
}

const StripeForm: React.FC<IStripeForm> = ({
    model,
    goal,
    price,
    user,
    cardholder,
    coupon,
    pay,
    cards,
    error,
    onSubmit,
    onChange,
    onFormInvalid,
    updatePaymentToken,
    paymentButtonDisabled,
    buttonDisabled,
    buttonText,
    showName = true,
    disclaimer = false,
    disclaimerText = payment.details.disclaimer,
    stripeButton = true,
    recaptcha = false,
    checkCoupon = true,
    loading,
    children,
    stylist
}) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [fname, setFname] = useState(
        cardholder ? cardholder.first_name : user ? user.first_name : ''
    );
    const [lname, setLname] = useState(
        cardholder ? cardholder.last_name : user ? user.last_name : ''
    );
    const [editable, setEditable] = useState(cards.length === 0);
    const [showStripeButton, setShowStripeButton] = useState(false);
    const [fieldEmpty, setFieldEmpty] = useState(true);
    const [captcha, setCaptcha] = useState<any>();
    const [cardInvalid, setCardInvalid] = useState(true);
    const [invalidCardHolder, setInvalidCardHolder] = useState(false);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const isDisabled = paymentInProgress || buttonDisabled;
    

    useEffect(() => {
        if (cards.length > 0) setCardInvalid(false);
    }, [cards]);

    useEffect(() => {
        let couponInvalid,
            captchaInvalid,
            cardCheck = cardInvalid;
        setEditable(cards.length === 0);

        const cardDetailsCheck = fname && fname.length > 0 && lname && lname.length > 0;

        couponInvalid = false;
        if (checkCoupon) {
            if (coupon.percent_off === 100 || coupon.discount === price) {
                couponInvalid = false;
                cardCheck = false;
                setShowStripeButton(false);
            } else if (!coupon.percent_off && editable && fieldEmpty) {
                couponInvalid = true;
            }
        }
        if (!cardDetailsCheck) {
            setInvalidCardHolder(true);
        } else {
            setInvalidCardHolder(false);
        }
        if (recaptcha)
            captchaInvalid =
                process.env.REACT_APP_ENV === 'production' && (!captcha || !captcha.length);
        const invalid = couponInvalid || captchaInvalid || cardCheck || !cardDetailsCheck;
        onFormInvalid ? onFormInvalid(invalid) : paymentButtonDisabled(invalid);
    }, [fname, lname, cards, coupon, captcha, cardInvalid]);

    useEffect(() => {
        if (error && error.payment) {
            setPaymentInProgress(false);
            if (stylist && stylist.is_approved == '0') {
                navigate('/stylistSearch');
            }
        }
    }, [error,navigate]);

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        setPaymentInProgress(true);
        if (e) e.preventDefault();
        let stripeToken;
        let result;

        const element = elements?.getElement(CardElement);
        if (element) {
            try {
                result = await stripe?.createToken(element);
                if (result?.token) {
                    stripeToken = result.token.id;
                }
                if (cards.length && result?.token?.card?.last4 === cards[0].last4) {
                    stripeToken = null;
                }
            } catch (err) {
                if (!error?.payment) error.payment = { message: "Couldn't process payment" };
                sentryException(err as Error, "Couldn't process payment");
                setPaymentInProgress(false);
            }
        }
        if (coupon.percent_off === 100) {
            stripeToken = null;
        }
        updatePaymentToken(stripeToken);
        onSubmit ? onSubmit(result && result.token ? result.token.card : null, captcha) : pay();
    };

    const onCaptcha = (value: any) => setCaptcha(value ? value : captcha);

    return (
        <Container className="stripe-form no-padding">
            <Form>
                <Form.Row className={showName ? 'show' : 'hide'}>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder={payment.details.form.firstname}
                            value={fname}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFname(e.target.value)
                            }
                            required
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            value={lname}
                            placeholder={payment.details.form.lastname}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setLname(e.target.value)
                            }
                            required
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {editable ? (
                            <CreditCardElement
                                onChange={(e: any) => {
                                    setFieldEmpty(e.empty);
                                    setCardInvalid(!e.complete);
                                    onChange && onChange(e.complete);
                                    if (e.complete) {
                                        Tracking.tag({
                                            event: 'eec.checkout',
                                            ecommerce: {
                                                checkout: {
                                                    actionField: { step: 2, option: e.brand }
                                                }
                                            }
                                        });
                                    }
                                }}
                                isDisabled={coupon && coupon.percent_off === 100}
                                onFocus={undefined}
                                onBlur={undefined}
                            />
                        ) : (
                            <InputGroup
                                onClick={() => {
                                    if (cards.length == 0 && coupon && coupon.percent_off !== 100) {
                                        setEditable(true);
                                        onFormInvalid
                                            ? onFormInvalid(true)
                                            : paymentButtonDisabled(true);
                                    }
                                }}
                                className={
                                    coupon && coupon.percent_off === 100 ? 'stripe-disabled' : ''
                                }>
                                <Image
                                    src={
                                        cards[0] &&
                                        payment.cards[
                                            cards[0].brand
                                                .toLowerCase()
                                                .replace(/\s/g, '') as CardType
                                        ]
                                    }
                                    onError={() => payment.cards.default}
                                />
                                <Form.Control
                                    className="last4"
                                    type="text"
                                    value={cards[0] && cards[0].last4}
                                    disabled
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>
                                        {cards[0] && cards[0].exp_month}/
                                        {cards[0] && cards[0].exp_year}
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        )}
                    </Col>
                </Form.Row>
                {((error && error.payment) || invalidCardHolder) && (
                    <Form.Row>
                        <Col className="error">
                            {invalidCardHolder
                                ? payment.details.errors.cardholder
                                : error
                                ? error?.payment?.message
                                : payment.details.errors.invalid}
                        </Col>
                    </Form.Row>
                )}
                {children}
                <div
                    className={`disclaimer ${model === 'monthly' || disclaimer ? 'show' : 'hide'}`}>
                    {disclaimerText}
                </div>

                {recaptcha && (
                    <div className="captcha-container">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string}
                            onChange={onCaptcha}
                        />
                    </div>
                )}
                <Button variant="dark" onClick={handleSubmit} disabled={isDisabled}>
                    {buttonText
                        ? buttonText
                        : model === 'monthly' && goal.value !== 'closet_cleanout'
                        ? payment.details.form.buttonTrial
                        : payment.details.form.buttonComplete}
                </Button>
                {!loading && stripeButton && (
                    <PaymentRequestButton
                        show={showStripeButton}
                        stripe={stripe}
                        price={coupon.total !== undefined ? coupon.total : price}
                        onShow={() => setShowStripeButton(true)}
                        onSuccess={(token: string | null | undefined) => {
                            updatePaymentToken(token);
                            onSubmit ? onSubmit(token) : pay();
                        }}
                        props={{ user }}
                        onClick={undefined}
                    />
                )}
            </Form>
        </Container>
    );
};

export default (props: any) => <StripeForm {...props} />;
