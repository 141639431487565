import { FC } from 'react';

import { GetStartedButton } from '../components';

export const Hero: FC = () => {
    return (
        <div className="hero">
            <div className="left-side">
                <img src="https://media-cf.wishi.me/react/lux/logo.png" alt="Wishi Lux" />
                <p>The most highly curated digital styling experience in the industry.</p>
                <GetStartedButton />
            </div>
            <div className="right-side">
                <img src="https://media-cf.wishi.me/react/lux/hero.webp" alt="Wishi" />
            </div>
        </div>
    );
};
