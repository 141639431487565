import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import content from '../../content';
import { useUserName } from '../../hooks';
import { Input } from '..';

const texts = content.billing;
const BillingForm = ({
    user = {},
    billingDetails = {},
    getBillingDetails,
    getCountries,
    getStates,
    countries,
    states,
    editMode = null,
    onChange,
    onValidate
}) => {
    const { username, fname, lname } = useUserName(user);
    const [billing, setBilling] = useState({
        address: {
            city: '',
            country: '',
            line1: '',
            line2: '',
            state: '',
            postal_code: ''
        },
        email: '',
        name: username,
        phone: ''
    });
    const [validation, setValidation] = useState({});
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        getBillingDetails();
        getCountries();
        setEdit(editMode);
    }, []);

    useEffect(() => {
        if (billingDetails.address && user) {
            validate({
                ...billing,
                ...billingDetails,
                address: {
                    ...billing.address,
                    ...billingDetails.address
                }
            });
        } else {
            setEdit(editMode ? editMode : true);
        }
    }, [billingDetails, user]);

    useEffect(() => {
        onChange(billing);
    }, [billing]);

    const onValueChange = ({ target: { id, value } }) => {
        let update;
        if (id === 'phone' || id === 'email') {
            update = {
                ...billing,
                [id]: value
            };
        } else {
            update = {
                ...billing,
                address: {
                    ...billing.address,
                    [id]: value
                }
            };
        }
        validate(update);
    };

    const onNameChange = ({ target: { id, value } }) => {
        let nameValue = billing.name ? billing.name.split(' ') : [fname, lname];
        if (nameValue.length) {
            nameValue[id === 'first_name' ? 0 : 1] = value;
        } else {
            nameValue = [value];
        }
        validate({
            ...billing,
            name: nameValue.join(' ')
        });
    };

    const onPhoneChange = (phone) => {
        validate({
            ...billing,
            phone
        });
    };

    const validate = (details) => {
        const { address = {} } = details;
        const { first_name, last_name, phone_number, email } = user;
        const name = details.name ? details.name : `${first_name} ${last_name}`;
        const phone = details.phone ? details.phone : phone_number;
        let valid = {
            name: !name || !name.length || name.length < 2 || name.split(' ').length < 2,
            line1: !address.line1 || address.line1.length === 0,
            city: !address.city || address.city.length === 0,
            state: !address.state || address.state.length === 0,
            country: !address.country || address.country.length === 0,
            postal_code:
                !address.postal_code ||
                address.postal_code.length === 0 ||
                address.postal_code.split(' ').length - 1 === address.postal_code.length,
            phone: !phone || phone.length === 0
        };
        const result = Object.values(valid).includes(true);

        setValidation(valid);
        if (result) {
            setEdit(editMode ? editMode : true);
        }
        onValidate && onValidate(!result);
        setBilling({ ...details, name, phone: phone || '', email: email || '' });
    };
    const address =
        billing.address.city && billing.address.state && billing.address.postal_code
            ? `${billing.address.city}, ${billing.address.state} ${billing.address.postal_code}`
            : null;

    return (
        <div className="billing-form">
            {!edit ? (
                <div className="billing-details">
                    <p>{billing.name}</p>
                    <p>
                        {billing.address.line1} {billing.address.line2}
                    </p>
                    <p>{address}</p>
                    <div className="edit-btn" onClick={() => setEdit(true)}>
                        Edit
                    </div>
                </div>
            ) : (
                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.first_name}</Form.Label>
                            <Form.Control
                                value={billing.name ? billing.name.split(' ')[0] : fname}
                                id="first_name"
                                onChange={onNameChange}
                            />
                            {validation.name && (
                                <div className="field-error">
                                    {texts.invalid.replace('%field%', 'name')}
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.last_name}</Form.Label>
                            <Form.Control
                                value={billing.name ? billing.name.split(' ')[1] : lname}
                                id="last_name"
                                onChange={onNameChange}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.country}</Form.Label>
                            {countries.length > 0 ? (
                                <div className="country">
                                    <Form.Control
                                        as="select"
                                        id="country"
                                        onChange={(e) => {
                                            getStates(e.target.value);
                                            onValueChange(e);
                                        }}>
                                        <option selected disabled>
                                            {texts.select}
                                        </option>
                                        {countries.map(({ name, code }) => (
                                            <option
                                                key={code}
                                                value={code}
                                                selected={
                                                    billing.address.country &&
                                                    billing.address.country.toLowerCase() ===
                                                        code.toLowerCase()
                                                }>
                                                {name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                            ) : (
                                <Form.Control
                                    value={billing.address.country}
                                    id="country"
                                    onChange={onValueChange}
                                />
                            )}
                            {validation.country && (
                                <div className="field-error">
                                    {texts.invalid.replace('%field%', 'country')}
                                </div>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.address}</Form.Label>
                            <Form.Control
                                value={billing.address.line1}
                                id="line1"
                                onChange={onValueChange}
                            />
                            {validation.line1 && (
                                <div className="field-error">
                                    {texts.invalid.replace('%field%', 'address')}
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.address2}</Form.Label>
                            <Form.Control
                                value={billing.address.line2}
                                id="line2"
                                onChange={onValueChange}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.city}</Form.Label>
                            <Form.Control
                                value={billing.address.city}
                                id="city"
                                onKeyDown={(e) => {
                                    if (e.keyCode > 47 && e.keyCode < 58) e.preventDefault();
                                }}
                                onChange={onValueChange}
                            />
                            {validation.city && (
                                <div className="field-error">
                                    {texts.invalid.replace('%field%', 'city')}
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.state}</Form.Label>
                            {states.length > 0 ? (
                                <div className="state">
                                    <Form.Control as="select" id="state" onChange={onValueChange}>
                                        <option selected disabled>
                                            {texts.select}
                                        </option>
                                        {states.map(({ name, code }) => (
                                            <option
                                                key={code}
                                                value={code}
                                                selected={
                                                    billing.address.state &&
                                                    billing.address.state.toLowerCase() ===
                                                        code.toLowerCase()
                                                }>
                                                {name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                            ) : (
                                <Form.Control
                                    value={billing.address.state}
                                    id="state"
                                    onKeyDown={(e) => {
                                        if (e.keyCode > 47 && e.keyCode < 58) e.preventDefault();
                                    }}
                                    onChange={onValueChange}
                                />
                            )}
                            {validation.state && (
                                <div className="field-error">
                                    {texts.invalid.replace('%field%', 'state')}
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.zip_code}</Form.Label>
                            <Form.Control
                                value={billing.address.postal_code}
                                id="postal_code"
                                onChange={onValueChange}
                            />
                            {validation.postal_code && (
                                <div className="field-error">
                                    {texts.invalid.replace('%field%', 'zip code')}
                                </div>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{texts.labels.phone}</Form.Label>
                            <Input
                                type="phone"
                                number={billing.phone}
                                country={
                                    billing.address.country
                                        ? billing.address.country.toLowerCase()
                                        : 'us'
                                }
                                onChange={onPhoneChange}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            )}
        </div>
    );
};

export default BillingForm;
