import { connect } from 'react-redux';

import Filters from './Filters';

const mapStateToProps = ({ user: { user } }) => ({
    user
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
