import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { MatchItem } from 'components';
import Content from 'content';
import { Stylist, Tracking } from 'services';
import {
    isMatchedStylist,
    MP_EVENTS,
    MP_PROPS,
    MP_VALUES,
    trackEvent
} from 'services/mixpanel';

const Matches = ({
    newUser: user,
    newStylist: stylist,
    favoriteStylists = [],
    updateFavoriteStylist,
    stylistsMatchs = [],
    loadUserMatches
}) => {
    const navigate = useNavigate();
    const [showMore, setShowMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [allMatches, setAllMatches] = useState([]);

    const matches = useMemo(
        () =>
            [
                ...stylistsMatchs,
                ...allMatches.filter(
                    ({ uuid }) => stylistsMatchs.findIndex((match) => match.uuid === uuid) < 0
                )
            ].filter(({ uuid }) => uuid !== stylist.uuid),
        [stylistsMatchs, allMatches, stylist]
    );

    const onPageBottom = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            Tracking.tag({
                event: 'eec.impressionView',
                ecommerce: {
                    impressions: stylistsMatchs
                        .filter((match) => match.uuid !== stylist.uuid)
                        .map((match, index) => ({
                            id: match.uuid,
                            name: match.name,
                            category: '/category/stylist/',
                            list: 'Other Recommended Stylists',
                            position: index
                        }))
                }
            });
        }
    };

    const selectStylist = ({ uuid, name }) => {
        Tracking.tag({
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: { list: 'Other Recommended Stylists' },
                    products: [
                        {
                            id: uuid,
                            name,
                            category: '/category/stylist/'
                        }
                    ]
                }
            }
        });
        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: {
                [MP_PROPS.STYLIST_NAME]: name,
                [MP_PROPS.STYLIST_UUID]: uuid,
                [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, uuid),
                [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLIST_PROFILE
            }
        });

        navigate(`/stylist/${uuid}/profile`);
    };

    const loadMore = async () => {
        setLoading(true);

        trackEvent({
            name: MP_EVENTS.VIEW_MORE_STYLISTS_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.STYLIST_PROFILE
            }
        });

        const { data } = await Stylist.all({ count: 1000, user_gender: user?.gender });

        setAllMatches(data.items || []);
        setLoading(false);
        setShowMore(false);
    };

    const onLoadMoreClick = () => {
        trackEvent({
            name: MP_EVENTS.VIEW_MORE_STYLISTS_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.STYLIST_PROFILE
            }
        });
        navigate(`/stylistSearch/`);
    };

    useEffect(() => {
        if (matches.length && stylist.uuid) {
            document.addEventListener('scroll', onPageBottom);
        }
        return () => {
            document.removeEventListener('scroll', onPageBottom);
        };
    }, [matches, stylist]);

    useEffect(() => {
        if (user) loadUserMatches(user);
    }, [user]);

    if (!user) {
        return <></>;
    }

    return (
        <div className="matches-section">
            <div className="title">{Content.stylist.matches}</div>
            <Row className="matches">
                {matches.map((match) => (
                    <Col className="match-item-container" xs={12} md={4} key={match.uuid}>
                        <MatchItem
                            match={match}
                            onClick={selectStylist}
                            onFavorite={updateFavoriteStylist}
                            isFavorite={favoriteStylists.find(
                                (favorite) => favorite.uuid === match.uuid
                            )}
                        />
                    </Col>
                ))}
            </Row>
            {showMore && (
                <Row>
                    <Col className="button-container">
                        <button
                            className="more-btn"
                            onClick={onLoadMoreClick}
                            data-test-id="load-more"
                            disabled={loading}>
                            {Content.match.more}
                        </button>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default Matches;
