import './style.scss';

import { Elements, useStripe } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';
import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, registerProperties, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { itemToProps } from 'services/utils/mixpanel-utils';
import { getUserId } from 'services/utils/user-utils';

import {
    BillingForm,
    Loader,
    MobileSideCollapse,
    Page,
    PaymentRequestButton,
    StripeForm
} from 'components';
import content from 'content.json';
import { Formatter, Payments, Tracking, User } from 'services';

const { item: texts, payment } = content;

//const stripePromise = loadStripe(Payments.getStripeKey());

const ItemCheckout = ({
    paymentProcess,
    paymentResult = {},
    item,
    checkoutList,
    checkoutPay,
    loadItem,
    user,
    billingDetails = {},
    cards,
    paymentButtonDisabled,
    buttonDisabled,
    toggleMobileCollapse,
    updateBillingDetails,
    updatePaymentToken,
    setPrice,
    getUserCards
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    let { outfit_uuid, itemId: item_uuid } = useParams();
    const stripe = useStripe();
    const [context, setContext] = useState('');
    const [validBilling, setValidBilling] = useState(!!billingDetails.address);
    const [invalidStripeForm, setInvalidStripeForm] = useState(false);
    const [validCard, setValidCard] = useState(false);
    const [tax, setTax] = useState();
    const [showStripeButton, setShowStripeButton] = useState(false);
    const isMobile = useIsMobile();
    const windowLocation = window.location.search;
    const itemSource = windowLocation.includes('session')
        ? 'session'
        : windowLocation.includes('gifts')
          ? 'gifts'
          : windowLocation.includes('favorites')
            ? 'favorites'
            : 'feed';

    useEffect(() => {
        if (location) {
            const path = location.pathname.split('/')[1];
            setContext(path === 'item' ? '' : path);
        }
        if (!item.item_uuid && item_uuid) {
            loadItem(item_uuid);
        }
    }, [location]);

    useEffect(() => {
        if (user) getUserCards();
    }, [user, getUserCards]);

    useEffect(() => {
        if (paymentResult) {
            setTax(paymentResult);
            setPrice(paymentResult.total);

            if (paymentResult.payment) {
                const eventProperties = itemToProps(item.item_uuid, itemSource, item.brand_name);
                trackEvent({
                    name: MP_EVENTS.ITEM_ORDERED,
                    properties: eventProperties
                });
                trackEvent({
                    name: MP_EVENTS.ORDER_COMPLETED,
                    properties: eventProperties
                });
                registerProperties({
                    [MP_PROPS.LAST_ORDER_DATE]: new Date(Date.now()).toDateString(),
                    [MP_PROPS.LAST_ORDER_AMOUNT]: paymentResult.total
                });
                Tracking.google({
                    type: 'event',
                    event: 'order_completed',
                    data: {
                        order_amount: paymentResult.total,
                        client_full_name: ''.concat(user.firstName, ' ', user.lastName),
                        client_uuid: user.uuid,
                        client_email: user.email,
                        item_brand: item.brand_name,
                        item_uuid: item.item_uuid
                    }
                });
                const newLocation = `${context.length ? `/${context}` : context}${
                    outfit_uuid ? `/outfit/${outfit_uuid}` : ''
                }/item/${item_uuid}/confirmation`;
                navigate(newLocation);
            }
        }
    }, [paymentResult, navigate]);

    useEffect(() => {
        if (window.billing && !invalidStripeForm && validBilling && (item.item_uuid || item.uuid)) {
            calculateTax(window.billing, true);
        }

        paymentButtonDisabled(!validBilling || invalidStripeForm || (!cards.length && !validCard));
    }, [validBilling, invalidStripeForm, validCard, item]);

    const calculateTax = async (data, isCheckNeeded = false) => {
        const { item_uuid, size = '' } = item;

        if (data && window.billing && window.billing.address) {
            const {
                address: { country: newCountry, state: newState }
            } = data;
            const {
                address: { country: currentCountry, state: currentState }
            } = window.billing;
            // caluculate taxes only if country or state were changed
            if (
                item_uuid &&
                (isCheckNeeded || newCountry !== currentCountry || newState !== currentState)
            ) {
                checkoutList({ items: [{ uuid: item_uuid, size }] }, data);
            }
        }
    };

    const checkout = async (data) => {
        try {
            const { user_uuid } = user;
            const { item_uuid, size = '' } = item;
            let payerEmail,
                payerName,
                payerPhone,
                shippingAddress = {};
            if (data) {
                payerEmail = data.payerEmail;
                payerName = data.payerName;
                payerPhone = data.payerPhone;
                shippingAddress = data.shippingAddress;
            }
            let billing = window.billing;
            const stripeShipping = shippingAddress ? shippingAddress : {};

            if (stripeShipping) {
                if (!billing.address) billing.address = {};
                billing = {
                    address: {
                        city: stripeShipping.city || billing.address.city,
                        line1: stripeShipping.addressLine
                            ? stripeShipping.addressLine.join(',')
                            : billing.address.line1,
                        country: stripeShipping.country || billing.address.country,
                        state: stripeShipping.region || billing.address.state,
                        postal_code: stripeShipping.postalCode || billing.address.postal_code
                    },
                    email: payerEmail || billing.email,
                    name: stripeShipping.recipient
                        ? stripeShipping.recipient
                        : payerName || billing.name,
                    phone: stripeShipping.phone ? stripeShipping.phone : payerPhone || billing.phone
                };
            }
            updateBillingDetails(billing);
            await User.updateBillingDetails({
                user_uuid,
                data: { billing_details: billing }
            });
            checkoutPay({
                items: [{ uuid: item_uuid, size }]
            });
        } catch (e) {
            sentryException(e, 'Cannot complete item checkout details');
        }
    };
    const subTotal = tax ? tax.subtotal : 0;
    const itemPrice =
        item.price && typeof item.price === 'string'
            ? parseInt(item.price.replace(/,/g, ''))
            : typeof item.price !== 'string'
              ? item.price
              : 0;
    const price = Math.floor(subTotal > 0 ? subTotal : itemPrice);
    const getPaymentCompo = () => (
        <PaymentRequestButton
            show={showStripeButton}
            stripe={stripe}
            price={price}
            onShow={() => setShowStripeButton(true)}
            onSuccess={(token, data) => {
                updatePaymentToken(token);
                checkout(data);
            }}
            shipping={{
                show: true,
                price: async (billing) => {
                    const userId = getUserId();
                    const { size, item_uuid } = item;
                    await User.updateBillingDetails({
                        user_uuid: userId,
                        data: {
                            billing_details: {
                                address: billing.data
                            }
                        }
                    });
                    const { data } = await Payments.checkoutList({
                        items: [{ uuid: item_uuid, size }],
                        user_uuid: userId
                    });
                    return { ...data.data, item };
                }
            }}
            props={{ stripeLabel: item.name }}
        />
    );

    return (
        <Page footer={false} header={!isMobile}>
            {isMobile && <MobileSideCollapse />}
            <Container className="item-checkout">
                {paymentProcess && <Loader />}
                <Row className="header">
                    <Col>
                        <div className="back-btn" onClick={() => navigate(-1)} />
                        <div>{texts.checkout}</div>
                    </Col>
                </Row>
                <Row>
                    <Col className="disclaimer">
                        <p>
                            <i />
                            {texts.disclaimer}
                        </p>
                    </Col>
                </Row>
                {isMobile ? (
                    <Row className="mobile-checkout">
                        <Col xs={12}>
                            <Container className="credit-card">
                                {cards.length > 0 ? (
                                    <>
                                        <p>{texts.payment.title}</p>
                                        <div
                                            className="card-number"
                                            onClick={() => {
                                                toggleMobileCollapse('card');
                                                getUserCards();
                                            }}>
                                            <Image src={Payments.paymentCardType(payment, cards)} />
                                            {cards[0].last4}
                                        </div>
                                    </>
                                ) : (
                                    <div
                                        className="add"
                                        onClick={() => toggleMobileCollapse('card')}>
                                        {texts.payment.addCard}
                                    </div>
                                )}
                            </Container>

                            <Container
                                className="billing-form"
                                onClick={() => toggleMobileCollapse('billing')}>
                                <p>{texts.shipping.title}</p>
                                {billingDetails.address && (
                                    <div className="billing-details">
                                        <p>{billingDetails.name}</p>
                                        <p>
                                            {billingDetails.address.line1}{' '}
                                            {billingDetails.address.line2}
                                        </p>
                                        <p>{`${billingDetails.address.city}, ${billingDetails.address.state} ${billingDetails.address.postal_code}`}</p>
                                    </div>
                                )}
                            </Container>

                            <OrderSummary tax={tax} item={item} />
                        </Col>
                        <div className="buttons">
                            <Button
                                variant="dark"
                                onClick={checkout}
                                disabled={buttonDisabled || !validBilling}>
                                {texts.payment.button}
                            </Button>
                            {!!price && getPaymentCompo()}
                        </div>
                    </Row>
                ) : (
                    <Row>
                        <Col sm={6} className="shipping-address d-none d-sm-flex">
                            <Card>
                                <Card.Header>{texts.shipping.title}</Card.Header>
                                <Card.Body>
                                    <BillingForm
                                        onChange={(data) => {
                                            calculateTax(data);
                                            window.billing = data; // walkaround for stripe loosing context on callback
                                        }}
                                        onValidate={setValidBilling}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={6} className="d-none d-sm-flex">
                            <Card>
                                <Card.Header>{texts.payment.title}</Card.Header>
                                <Card.Body>
                                    <StripeForm
                                        buttonText={texts.payment.button}
                                        disclaimer={false}
                                        onSubmit={checkout}
                                        onChange={setValidCard}
                                        onFormInvalid={setInvalidStripeForm}
                                        stripeButton={false}
                                        checkCoupon={false}>
                                        {price && getPaymentCompo()}
                                        <OrderSummary tax={tax} item={item} />
                                    </StripeForm>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </Page>
    );
};

const OrderSummary = ({ tax, item }) => (
    <Container className="order-summary" fluid>
        <Row>
            <Col>{texts.payment.subtotal.replace('%items%', tax ? tax.items.length : '1')}</Col>
            <Col>{Formatter.price(tax ? tax.subtotal : item.price)}</Col>
        </Row>
        <Row>
            <Col>{texts.payment.taxes}</Col>
            {tax && (
                <Col>
                    {tax.taxes !== null
                        ? tax.taxes === 0
                            ? texts.payment.free
                            : Formatter.price(tax.taxes)
                        : texts.payment.tbd}
                </Col>
            )}
        </Row>
        <Row className="shipping">
            <Col>{tax && !tax.shipping ? texts.payment.shipping : texts.payment.freeShipping}</Col>
            {tax && <Col>{Formatter.price(tax.shipping)}</Col>}
        </Row>
        <Row className="total">
            <Col>{texts.payment.total}</Col>
            <Col>{Formatter.price(tax ? tax.total : item.price)}</Col>
        </Row>
    </Container>
);

export default (props) => (
    //<Elements stripe={stripePromise}>
    <ItemCheckout {...props} />
    //</Elements>
);
