import './style.scss';

import { useGoogleLogin } from '@react-oauth/google';
import React, { FC } from 'react';

type TProps = {
    disabled?: boolean;
    onSuccess: ({ googleAuthCode }: { googleAuthCode: string }) => void;
    markerClass?: string;
};

export const GoogleLogin: FC<TProps> = ({ disabled = false, onSuccess, markerClass = '' }) => {

    const redirectToGoogleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            tokenResponse.code && onSuccess({ googleAuthCode: tokenResponse.code });
        },
        flow: 'auth-code'
    });

    return (
        <div className="google-login">
            <button onClick={() => !disabled && redirectToGoogleLogin()} disabled={disabled} className={markerClass}>
                <img src="https://media-cf.wishi.me/react/icons/google-icon.svg" />
                <span>Continue with Google</span>
            </button>

            <div className="google-signup-separator"></div>
        </div>
    );
};
