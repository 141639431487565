import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React from 'react';
import Slider from 'react-slick';

const PressCarousel = ({ items }) => (
    <Slider
        className="press-carousel"
        dots={false}
        infinite={true}
        speed={500}
        slidesToShow={5}
        slidesToScroll={1}
        autoplay={true}
        responsive={[
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1060,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]}
    >
        {items.map((item) => (
            <div key={item.url} className="press-item">
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <ImgWithFallback src={item.img.src} fallbackSrc={item.img.fallback} />
                </a>
                <p>{item.text}</p>
            </div>
        ))}
    </Slider>
);
export default PressCarousel;
