import { MatchItem } from 'components';
import { useWithDispatch } from 'hooks';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Tracking } from 'services';
import { isMatchedStylist, MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { useBookingStore } from 'store/booking/reducer';
import { setBookingSource, setMixpanelStore } from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';
import { updateFavoriteStylist } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';
import { Stylist as StylistType } from 'types/user';

import content from '../../../content.json';
import MatchHeader from '../components/Header';

const texts = content.match;

interface IStylistsLis {
    matchedStylists: StylistType[];
    moreStylists: StylistType[];
}

export const List: React.FC<IStylistsLis> = ({ matchedStylists, moreStylists }) => {
    const setBookingSourceAction = useWithDispatch(setBookingSource);
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const campaign = useBookingStore((store) => store.campaign);
    const stylists = useCommonStore((store) => store.stylists);
    const navigate = useNavigate();
    const favoriteStylists = useUserStore((store) => store.favoriteStylists);
    const updateFavoriteStylistAction = useWithDispatch(updateFavoriteStylist);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    const isFavoriteStylists = (uuid: string) =>
        favoriteStylists?.findIndex((favorite) => favorite.uuid === uuid) > -1;
    const analyticsTrackingEvent = (stylistName: string, uuid: string, matches: StylistType[]) => {
        Tracking.tag({
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: { list: 'Stylist Recommendations' },
                    products: [
                        {
                            id: uuid,
                            name: stylistName,
                            category: '/category/stylist/'
                        }
                    ]
                }
            }
        });
    };

    const mixpanelTrackingEvent = (stylistName: string, uuid: string, matches: StylistType[]) => {
        const eventProperties = {
            ...mixpanelStore.onboarding,
            [MP_PROPS.STYLIST_NAME]: stylistName ?? '',
            [MP_PROPS.STYLIST_UUID]: uuid,
            [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, uuid),
            [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLISTS
        };

        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: eventProperties
        });
        setMixpanelStoreAction({
            onboarding: {
                ...eventProperties
            }
        });
    };

    const selectStylist = (stylist: StylistType) => {
        const uuid = stylist?.uuid || stylist?.value;
        setBookingSourceAction({ source: MP_VALUES.STYLISTS });
        if (uuid) {
            const stylistName = stylist.name ? stylist.name : stylist.label;
            if (stylistName) {
                analyticsTrackingEvent(stylistName, uuid, matches);
                mixpanelTrackingEvent(stylistName, uuid, matches);
            }
            const to = `/stylist/${uuid}/profile`;
            const props = campaign ? { state: { campaign } } : {};
            navigate(to, props);
        }
    };

    return (
        <>
            <MatchHeader
                matchesLength={matchedStylists.length}
                onSelect={selectStylist}
                stylists={stylists}
            />
            <Row>
                {matchedStylists.map((match) => (
                    <Col xs={12} md={4} key={match.uuid} className="match-item-container">
                        <MatchItem
                            match={match}
                            onClick={selectStylist}
                            isFavorite={isFavoriteStylists(match.uuid ?? '')}
                            onFavorite={updateFavoriteStylistAction}
                        />
                    </Col>
                ))}
            </Row>
            {moreStylists && (
                <>
                    <div className="more-stylists title">{texts.moreHeader}</div>
                    <Row>
                        {moreStylists.map((match) => (
                            <Col xs={12} md={4} key={match.uuid} className="match-item-container">
                                <MatchItem
                                    match={match}
                                    onClick={selectStylist}
                                    isFavorite={isFavoriteStylists(match.uuid ?? '')}
                                    onFavorite={updateFavoriteStylistAction}
                                />
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
};
