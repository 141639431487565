import './style.scss';

import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const StickyButton = ({
    url = '',
    text,
    type = 'dark',
    onClick,
    className = '',
    disabled = false
}) => (
    <Link
        to={url || '#'}
        onClick={(e) => {
            if (!url) {
                e.preventDefault();
                onClick();
            }
        }}
        className={`sticky-button ${className} ${disabled ? 'disabled' : ''}`}
    >
        <Button variant={type} disabled={disabled}>
            {text}
        </Button>
    </Link>
);

export default StickyButton;
