import './style.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getOutfitUrl } from 'services/utils/url-utils';

import { Loader, Page } from '../../components';
import { getSDKAccessToken } from '../../services/sdk';
import { itemToProps } from '../../services/utils/mixpanel-utils';
import { useGetMessagesQuery } from 'store/inbox-service/inbox-api-slice';

interface IClientChat {
    cart: any | null;
    updateItem: () => void;
    isTwilioInitialized: boolean;
    addToCart: (item: any, source: string) => void;
    removeFromCart: (item: any) => void;
}

export const Chat: React.FC<IClientChat> = ({
    cart,
    isTwilioInitialized,
    addToCart,
    removeFromCart
}) => {
    const { stylist_id: stylistId, user_id: userId } = useParams();
    const hasParams = stylistId && userId;
    const [chatRootElement, setChatRootElement] = useState<HTMLDivElement>();
    const navigate = useNavigate();
    const pluginRef = useRef<any>(null);
    const [cartItems, setCartItems] = useState<string[]>(
        () => cart?.map(({ originalItemUnique, uuid }: any) => originalItemUnique || uuid) || []
    );
    const { data: messages } = useGetMessagesQuery();

    const chatChannelProps = useMemo(() => {
        if (messages?.length && hasParams) {
            const filteredChannel = messages.find(
                (message) => message['stylist']['uuid'] === stylistId
            );
            if (filteredChannel) {
                const {
                    sid,
                    stylist: { first_name, last_name }
                } = filteredChannel;
                return {
                    userId,
                    stylistId,
                    chatSid: sid,
                    stylistName: `${first_name} ${last_name}`
                };
            }
        }
    }, [messages, stylistId, userId, hasParams]);

    const loaded = isTwilioInitialized && !!chatChannelProps;

    const addCartItem = useCallback(
        (itemId: string) => {
            setCartItems((cartItems) => {
                addToCart({ uuid: itemId }, 'session');

                return [...cartItems, itemId];
            });
        },
        [setCartItems, addToCart]
    );

    const removeCartItem = useCallback(
        (itemId: string) => {
            setCartItems((cartItems) => {
                const filtered = cartItems.filter((id) => id !== itemId);
                removeFromCart({ uuid: itemId });

                return [...filtered];
            });
        },
        [setCartItems, removeFromCart]
    );

    useEffect(() => {
        if (isTwilioInitialized && chatChannelProps && chatRootElement) {
            const { userId, stylistId, chatSid, stylistName } = chatChannelProps;
            const accessToken = getSDKAccessToken();

            pluginRef.current && pluginRef.current.unmount();

            if (chatSid) {
                localStorage.setItem('[wishi-sdk-channel-sid]', chatSid);
                localStorage.setItem('[wishi-sdk-token]', accessToken);
            }

            const plugin = (pluginRef.current = window.ClientChatPlugin({
                chatMode: 'b2c',
                element: chatRootElement,
                partnerToken: accessToken,
                partnerUserId: userId,
                clientInfo: { cartItems },
                selectedSid: chatSid
            }));

            plugin.on('cart-item-added', async (item: string) => {
                addCartItem(item);
            });
            plugin.on('cart-item-removed', async (item: string) => {
                removeCartItem(item);
            });
            plugin.on('item-clicked', async (item: any) => {
                trackEvent({
                    name: MP_EVENTS.ITEM_CLICKS,
                    properties: itemToProps(
                        item.item_uuid,
                        MP_VALUES.SESSION,
                        item.brand_name,
                        item.collectionId
                    )
                });

                if (!item.isShoppable && item.itemType === 'shop') {
                    window.open(item.buyUrl, '_blank');
                }

                navigate(`/item/${item.itemId}?context=session`);
            });
            plugin.on('end_session_response', (response: { [key: string]: string }) => {
                trackEvent({
                    name: MP_EVENTS.END_SESSION_RESPONSE,
                    properties: { [MP_PROPS.END_SESSION_RESPONSE]: response.reply }
                });
            });
            plugin.on('session_ended', () => {
                trackEvent({ name: MP_EVENTS.SESSION_ENDED, properties: {} });
            });
            plugin.on('look_views', (response: { [key: string]: string }) => {
                trackEvent({
                    name: MP_EVENTS.LOOK_VIEWS,
                    properties: {
                        [MP_PROPS.LOOK_UUID]: response.collectionId,
                        [MP_PROPS.LOOK_SOURCE]: 'session',
                        [MP_PROPS.LOOK_URL]: getOutfitUrl(response.collectionId),
                        [MP_PROPS.STYLIST_UUID]: stylistId,
                        [MP_PROPS.STYLIST_NAME]: stylistName
                    }
                });
            });

            plugin.mountApp();
        }
    }, [
        isTwilioInitialized,
        chatChannelProps,
        navigate,
        cartItems,
        chatRootElement,
        addCartItem,
        removeCartItem
    ]);

    const reff = useCallback((node: HTMLDivElement) => {
        if (node) setChatRootElement(node);
    }, []);

    return (
        <Page className="chat" footer={false}>
            {!loaded && <Loader />}
            <Container className={loaded ? 'show' : 'hide'}>
                <div className="chat-container wishi-chat" ref={reff} />
            </Container>
        </Page>
    );
};
