import jsCookies from 'js-cookie';

const Cookies = {
    set: (name, value, options = {}) => {
        document.cookie = `${name}=${encodeURIComponent(
            JSON.stringify(value)
        )}; path=/app; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        jsCookies.set(name, value, options);
    },
    get: (name) => {
        const cookie = jsCookies.get(name);
        let value;
        try {
            value = JSON.parse(cookie);
        } catch (e) {
            value = cookie ? cookie : {};
        }
        return value;
    },
    remove: (name, options) => {
        jsCookies.remove(name, { path: '/app' });
        jsCookies.remove(name, options);
    }
};

export default Cookies;

