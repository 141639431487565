import { Dispatch, AnyAction } from 'redux';
import { setupError } from '../store/error/actions';
import Axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

type URLParams = Record<string, string | number | boolean>;

function requestHandler({
    dispatch,
    targetAction,
    params
}: {
    dispatch: Dispatch<AnyAction>;
    targetAction?: string;
    params?: URLParams;
}) {
    if (dispatch && targetAction) {
        dispatch({ type: `${targetAction}_REQUEST`, payload: params });
    }
}

function errorHandler({
    dispatch,
    targetAction,
    err,
    showErrorMessage = false,
    rethrow = false
}: {
    dispatch: Dispatch<AnyAction>;
    targetAction?: string;
    err: AxiosError;
    showErrorMessage?: boolean;
    rethrow?: boolean;
}) {
    if (dispatch && targetAction) {
        dispatch({ type: `${targetAction}_FAILURE` });
    }
    if (dispatch) {
        const defaultMessage = 'Action failed';
        setupError(dispatch, {
            error: err,
            message: defaultMessage,
            showMessage: showErrorMessage
        });
    }
    if (rethrow) {
        throw err;
    }
}

type PrettifyData = (data: any) => any;

function successHandler({
    dispatch,
    targetAction,
    prettifyData,
    response,
    analytics,
    showSuccessMessage = false
}: {
    dispatch: Dispatch<AnyAction>;
    targetAction?: string;
    prettifyData: PrettifyData;
    response: AxiosResponse;
    analytics?: boolean;
    showSuccessMessage?: boolean;
}) {
    const payload = prettifyData(response.data);
    if (dispatch && targetAction) {
        dispatch({ type: `${targetAction}_SUCCESS`, payload, analytics });
    }

    if (dispatch !== undefined && dispatch !== null && showSuccessMessage) {
        const message =
            typeof payload === 'string' ? payload : payload?.message ?? 'Action succeeded';
    }
    return payload;
}
interface Request {
    dispatch: Dispatch<AnyAction>;
    url: string;
    targetAction?: string;
    params?: URLParams;
    prettifyData?: PrettifyData;
    requireAuth?: boolean;
    analytics?: boolean;
    throwErrors?: boolean;
    config?: Partial<AxiosRequestConfig>;
}

async function fetch<T>({
    dispatch,
    targetAction,
    url,
    params = {},
    config = {},
    prettifyData = (data) => data,
    requireAuth = true,
    analytics = false,
    throwErrors = false
}: Request) {
    try {
        requestHandler({ dispatch, targetAction, params });
        const response = await Axios.request<T>({ method: 'GET', url, params, ...config });
        return successHandler({
            dispatch,
            targetAction,
            prettifyData,
            response,
            analytics,
            showSuccessMessage: false
        });
    } catch (err) {
        errorHandler({
            dispatch,
            targetAction,
            err: err as AxiosError,
            showErrorMessage: false,
            rethrow: throwErrors
        });
        return null;
    }
}

export default {
    fetch
};
