import './style.scss';

import React, { useEffect, useState } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';

import { FilterParams, FilterStruct, FilterMap } from '../../types/styling';
import Filter from './Filter';
import { Client } from 'types/user';

interface IFilters {
    loadFilters?: (user_uuid: string) => void;
    filters: FilterStruct[];
    selected: Record<string, any>;
    showSelection: boolean;
    setFilter: (input: FilterParams) => void;
    clearFilter?: (input: string) => void;
    user: Client;
    className: string;
    filterSubmited?: boolean;
}

const Filters: React.FC<IFilters> = ({
    loadFilters = undefined,
    filters = [],
    selected = {},
    showSelection = false,
    setFilter,
    clearFilter,
    user,
    className = '',
    filterSubmited
}) => {
    const [collapsed, setCollapsed] = useState<Array<string>>([]);

    const setCollapsedFilters = (key: string) => {
        if (collapsed.includes(key)) {
            setCollapsed(collapsed.filter((item) => item !== key));
        } else {
            setCollapsed([...collapsed, key]);
        }
    };

    const getFilterType = (key: string) => {
        let type = 'checkbox';
        if (key === 'PRICES') type = 'slider';
        if (key === 'brand_name') type = 'search';
        if (key === 'brands') type = 'search';
        return type;
    };

    useEffect(() => {
        if (!filters.length && user && loadFilters) {
            loadFilters(user.user_uuid as string);
        }
    }, [user]);

    return (
        <Container className={`filters no-padding ${className}`} fluid>
            <Row className="filters-container">
                <Col>
                    <Accordion className="filters-list">
                        {filters.map(
                            (filter) =>
                                filter.key && (
                                    <Filter
                                        disabled={
                                            (!filter.filter_options ||
                                                !Array.isArray(filter.filter_options) ||
                                                !filter.filter_options.length) &&
                                            getFilterType(filter.key) !== 'slider'
                                        }
                                        key={filter.key}
                                        type={getFilterType(filter.key)}
                                        filter={filter as FilterMap}
                                        selected={
                                            filter.key
                                                ? filter.key === 'PRICES'
                                                    ? [
                                                          {
                                                              key: 'minPrice',
                                                              value: selected.minPrice
                                                                  ? Number(selected.minPrice[0].key)
                                                                  : selected.min_budget
                                                                  ? Number(
                                                                        selected.min_budget[0].key
                                                                    )
                                                                  : 1
                                                          },
                                                          {
                                                              key: 'maxPrice',
                                                              value: selected.maxPrice
                                                                  ? Number(selected.maxPrice[0].key)
                                                                  : selected.max_budget
                                                                  ? Number(
                                                                        selected.max_budget[0].key
                                                                    )
                                                                  : 5000
                                                          }
                                                      ]
                                                    : selected[filter.key]
                                                : []
                                        }
                                        onClick={() => setCollapsedFilters(filter.key)}
                                        onChange={setFilter}
                                        onClear={clearFilter}
                                        isCollapsed={collapsed.includes(filter.key)}
                                        showSelection={
                                            getFilterType(filter.key) === 'slider'
                                                ? false
                                                : showSelection
                                        }
                                        isfilterSubmited={filterSubmited}
                                    />
                                )
                        )}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
};

export default Filters;
